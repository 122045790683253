import React,{useState,useEffect} from 'react'
import Legal from './Legal'
import SkeletonLoader from '../../Components/Common/SkeletonLoader';

const CancellationAndRefund = () => {
  const [loader, setLoader] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setLoader(false);
    }, [1000]);
  }, []);
  return (
    <>
<div className='course-container'>
<div className='row '>
    {/*  filter options */}
<div className='col-lg-3 col-md-3 col-sm-12  mb-5' >
    <Legal/>
    </div>
    
    <div className='col-lg-9 col-md-9 col-sm-12  mb-5' >
    <div className='course-section'>
    {loader ?
                  <SkeletonLoader skeletonCount={5} />:
        <div className='row container'>
            <div className='col-xl-12 col-lg-12 col-md-12 col-sm-12'>
            <div className='learningpt-header' >Cancellation & Refund Policy</div>
            <div className='requirment pt-5'>Last updated on Jan 2nd 2024</div>
            <div className='requirment pt-5'>
            LEARNLYTICA PRIVATE LIMITED is committed to providing exceptional service to its customers. Our cancellation and refund policy is designed to be customer-friendly, taking into consideration the unique nature of our IT training courses.

            </div>

<div className='requirment pt-5'> Please review the following policy:</div>
<h5 className='requirment pt-5'> Cancellation Requests:</h5>    
<div className='requirment'>
              <ul className='pt-2'>
                <li >
             
Cancellation requests will be considered if made immediately after placing the order. However, we may not entertain cancellation requests if the orders have already been communicated to our training partners and they have initiated the course enrollment process.
                </li>


              </ul>
            
            
            </div>

            <h5 className='requirment pt-5'> Non-Cancellation of Training Courses:</h5>    
            <div className='requirment'>
                          <ul className='pt-2'>
                           
                           <li>
                          
                           Cancellation requests for IT training courses, once the enrollment process has begun, may not be accepted. This is due to the nature of the services provided, which involve coordination with training partners and immediate access to course materials.
                            </li>
                          </ul>
                        
                        
                        </div>

                        <h5 className='requirment pt-5'>
                        Perishable Items:</h5>    
                        <div className='requirment'>
                                      <ul className='pt-2'>
                                       
                                       <li>
                                      
                                       As our offerings are primarily digital and non-perishable, the concept of perishable items does not apply. Cancellation requests for courses will be considered based on the aforementioned conditions.
                                       </li>
                                      </ul>
                                    
                                    
                                    </div>

                                    <h5 className='requirment pt-5'> Quality Concerns:</h5>    
                                    <div className='requirment'>
                                                  <ul className='pt-2'>
                                                   
                                                   <li>
                                                  
                                                   If you receive a course that does not meet your expectations or has quality issues, please report it to our Customer Service team on the same day of receipt. We will initiate an investigation, and if the quality concern is validated, a refund or replacement will be provided.                                     
</li>
                                                  </ul>
                                                
                                                
                                                </div>
                                                <h5 className='requirment pt-5'> Damaged or Defective Items:</h5>    
                                                <div className='requirment'>
                                                              <ul className='pt-2'>
                                                               
                                                               <li>
                                                              
                                                               In the rare case of receiving damaged or defective digital items, please report it to our Customer Service team within the same day of receiving the course. We will address the issue after verification with our training partners.
                                                               </li>
                                                              </ul>
                                                            
                                                            
                                                            </div>
                                                            <h5 className='requirment pt-5'>
                                                            Product Discrepancy:</h5>    
                                                            <div className='requirment'>
                                                                          <ul className='pt-2'>
                                                                           
                                                                           <li>
                                                                        
                                                                          
                                                                           If you believe the course received does not align with the information provided on the website or your expectations, please notify our customer service within the same day of receiving the product. Our team will investigate and make an appropriate decision.
                                                                           </li>
                                                                          </ul>
                                                                        
                                                                        
                                                                        </div>

                                                                        <h5 className='requirment pt-5'>
                                                                        Manufacturer Warranty:</h5>    
                                                            <div className='requirment'>
                                                                          <ul className='pt-2'>
                                                                           
                                                                           <li>
                                                                          
                                                                           For courses that come with a warranty from training partners or instructors, any issues or complaints should be referred to them for resolution.
                                                                           </li>
                                                                          </ul>
                                                                        
                                                                        
                                                                        </div>
                                                                        <h5 className='requirment pt-5'>
                                                                        Refund Processing:</h5>    
                                                            <div className='requirment'>
                                                                          <ul className='pt-2'>
                                                                           
                                                                           <li>
                                                                         
                                                                           In case of approved refunds by LEARNLYTICA PRIVATE LIMITED, it will take 9-15 days for the refund to be processed and credited to the end customer.
                                                                          
                                                                           </li>
                                                                          </ul>
                                                                        
                                                                        
                                                                        </div>

                                                                     



<div className='requirment pt-5 pb-5'>By engaging with our services, you agree to comply with this Cancellation and Refund Policy. We are committed to addressing your concerns promptly and providing a satisfactory resolution whenever possible.</div>


            

         
            </div>

        </div>
}

    </div>



    
    
    
    </div>


    </div>
</div>
    </>
  )
}

export default CancellationAndRefund