/* Note : Calling Api Standard
* E.g : getListing Axios call accept params in following seq
* Url : service url
  payload : post data
  param : url parameter
  config : if config object
  config = {
    loader : '#container'  //loader will show for the div having id = 'container'
  }
  if any api fails or empty response comes then related messge will be shown

  Ex:
  getListing(payload,config) {
    var params ={
      viewType :'detail'
    };
    return AxiosService.post('/api/listing', payload,params,config);
  },

*
*/

import GLOBELCONSTANT from "../Constant/GlobleConstant.js";
import AxiosService from "./axios.service.js";

const RestService = {
  //all consumer courses
  getCosumerCourses: (pageNo, pageSize, currency, payload) => AxiosService.put(GLOBELCONSTANT.ALL_COURSES.GET_CONSUMER_COURSES + `?page_no=${pageNo}&page_size=${pageSize}&currency=${currency}`, payload),
  getCosumerCoursesForFilterPriceRating: (pageNo, pageSize, payload) => AxiosService.put(GLOBELCONSTANT.ALL_COURSES.GET_CONSUMER_COURSES + `?page_no=${pageNo}&page_size=${pageSize}`, payload),
  getCosumerCoursesForUser: (pageNo, pageSize, currency, payload) => AxiosService.put(GLOBELCONSTANT.ALL_COURSES.GET_CONSUMER_COURSES + `?page_no=${pageNo}&page_size=${pageSize}&currency=${currency}`, payload),
  getCosumerCoursesForUserFilterPriceRating: (pageNo, pageSize, consumerSid, payload) => AxiosService.put(GLOBELCONSTANT.ALL_COURSES.GET_CONSUMER_COURSES + `?consumer_sid=${consumerSid}&page_no=${pageNo}&page_size=${pageSize}`, payload),
  getCosumerCoursesWithFilterCategory: (pageNo, pageSize, categorySid, payload) => AxiosService.put(GLOBELCONSTANT.ALL_COURSES.GET_CONSUMER_COURSES + `?category_sid=${categorySid}&page_no=${pageNo}&page_size=${pageSize}`, payload),
  getCosumerCoursesWithFilterCategorySubCategory: (pageNo, pageSize, categorySid, subCategorysid, payload) => AxiosService.put(GLOBELCONSTANT.ALL_COURSES.GET_CONSUMER_COURSES + `?category_sid=${categorySid}&sub_category_sid=${subCategorysid}&page_no=${pageNo}&page_size=${pageSize}`, payload),
  getCosumerCoursesWithFilterCategoryForUser: (pageNo, pageSize, categorySid, consumerSid, payload) => AxiosService.put(GLOBELCONSTANT.ALL_COURSES.GET_CONSUMER_COURSES + `?category_sid=${categorySid}&consumer_sid=${consumerSid}&page_no=${pageNo}&page_size=${pageSize}`, payload),
  getAllConsumerCourseSubCategories: (categorySid) => AxiosService.get(GLOBELCONSTANT.ALL_COURSES.GET_ALL_CONSUMER_COURSE_SUB_CATEGORIES + `?category_sid=${categorySid}`),
  getCosumerCoursesWithFilterCategorySubCategoryForUser: (pageNo, pageSize, categorySid, subCategorysid, consumerSid, payload) => AxiosService.put(GLOBELCONSTANT.ALL_COURSES.GET_CONSUMER_COURSES + `?category_sid=${categorySid}&sub_category_sid=${subCategorysid}&consumer_sid=${consumerSid}&page_no=${pageNo}&page_size=${pageSize}`, payload),
  getCoursesForFilterWithPriceAndCourseType: (pageNo, pageSize, payload) => AxiosService.put(GLOBELCONSTANT.ALL_COURSES.GET_CONSUMER_COURSES + `?page_no=${pageNo}&page_size=${pageSize}`, payload),
  getCoursesForFilterWithPriceAndCourseTypeForUser: (pageNo, pageSize, consumerSid, payload) => AxiosService.put(GLOBELCONSTANT.ALL_COURSES.GET_CONSUMER_COURSES + `?page_no=${pageNo}&page_size=${pageSize}&consumer_sid=${consumerSid}`, payload),
  getAllCategoriesAndSubCategories: () => AxiosService.get(GLOBELCONSTANT.ALL_COURSES.GET_ALL_CATEGORIES_AND_SUB_CATEGORIES),
  getConsumerCourseDetails: (courseSid) => AxiosService.get(GLOBELCONSTANT.ALL_COURSES.GET_CONSUMER_COURSE_DETAILS + `?course_sid=${courseSid}`),
  getConsumerCourseDetailsUser: (consumerSid, courseSid) => AxiosService.get(GLOBELCONSTANT.ALL_COURSES.GET_CONSUMER_COURSE_DETAILS + `?course_sid=${courseSid}&consumer_sid=${consumerSid}`),
  getTop5Courses: () => AxiosService.get(GLOBELCONSTANT.ALL_COURSES.GET_TOP5_COURSES),
  getTop5CoursesForLoginUser: (consumerSid) => AxiosService.get(GLOBELCONSTANT.ALL_COURSES.GET_TOP5_COURSES + `?consumer_sid=${consumerSid}`),
  getTop5CoursesForSpecificCourses: (courseSid) => AxiosService.get(GLOBELCONSTANT.ALL_COURSES.GET_TOP5_COURSES + `?course_sid=${courseSid}`),
  getTop5CoursesForLoginUserForSpecificCourses: (courseSid, consumerSid) => AxiosService.get(GLOBELCONSTANT.ALL_COURSES.GET_TOP5_COURSES + `?course_sid=${courseSid}&consumer_sid=${consumerSid}`),
  newsLetterSubscribe: (email) => AxiosService.post(GLOBELCONSTANT.ALL_COURSES.NEWSLETTER_SUBSCRIBE + `?email=${email}`, {}),
  proceedToTraining: (consumerSid, courseSid) => AxiosService.get(GLOBELCONSTANT.ALL_COURSES.PROCEED_TO_TRAINING + `?consumer_sid=${consumerSid}&course_sid=${courseSid}`),
  contactUsForm: (payload) => AxiosService.post(GLOBELCONSTANT.ALL_COURSES.CONTACTUS_FORM, payload),
  getUploadProfilePicture: (consumerSid) => AxiosService.get(GLOBELCONSTANT.ALL_COURSES.GET_UPLOAD_PROFILE_PICTURE + `?consumer_sid=${consumerSid}`),
  uploadProfilePicture: (consumerSid, payload) => AxiosService.uploadMultiPart(GLOBELCONSTANT.ALL_COURSES.UPLOAD_PROFILE_PICTURE + `?consumer_sid=${consumerSid}`, payload),
  curateCourse: (courseSid, consumerSid, payload) => AxiosService.post(GLOBELCONSTANT.ALL_COURSES.CURATE_COURSE.replace("{courseSid}", courseSid) + `?consumer_sid=${consumerSid}`, payload),
  notifyMe: (payload) => AxiosService.post(GLOBELCONSTANT.ALL_COURSES.NOTIFY_ME, payload),
  searchCourse: (text) => AxiosService.get(GLOBELCONSTANT.ALL_COURSES.SEARCH_COURSE + `?search_text=${text}`),
  viewAllSearchedCourses: (currency, pageNo, pageSize, text, payload) => AxiosService.put(GLOBELCONSTANT.ALL_COURSES.VIEW_ALL_SEARCHED_COURSES + `?currency=${currency}&page_no=${pageNo}&page_size=${pageSize}&search_text=${text}`, payload),

  //auth
  consumerSignup: (payload) => AxiosService.post(GLOBELCONSTANT.AUTH.CONSUMER_SIGNUP, payload),
  autoLogin: (payload) => AxiosService.post(GLOBELCONSTANT.AUTH.AUTO_LOGIN, payload),
  login: (payload, type) => AxiosService.post(GLOBELCONSTANT.AUTH.LOGIN + `?user_type=${type}`, {}, {}, payload),
  Googlelogin: (payload) => AxiosService.post(GLOBELCONSTANT.AUTH.Google_AUTO_LOGIN, payload),
  getLogoutTimes: (timeSpent) => AxiosService.get(GLOBELCONSTANT.AUTH.GET_LOGOUT_TIMES + `?time_spent=${timeSpent}`),
  forgotPassword: (payload) => AxiosService.put(GLOBELCONSTANT.AUTH.FORGOT_PASSWORD, payload),
  changeForgotPassword: (payload) => AxiosService.put(GLOBELCONSTANT.AUTH.CHANGE_FORGOT_PASSWORD, payload),
  updateConsumerPassword: (payload) => AxiosService.put(GLOBELCONSTANT.AUTH.UPDATE_CONSUMER_PASSWORD, payload),
  getUserEnrolledCourses: (consumerSid) => AxiosService.put(GLOBELCONSTANT.AUTH.GET_USER_ENROLLED_COURSES + `?consumer_sid=${consumerSid}`),
  getUserEnrolledCoursesWithFilter: (consumerSid, payload) => AxiosService.put(GLOBELCONSTANT.AUTH.GET_USER_ENROLLED_COURSES + `?consumer_sid=${consumerSid}`, payload),
  editConsumerProfile: (payload) => AxiosService.put(GLOBELCONSTANT.AUTH.EDIT_CONSUMER_PROFILE, payload),

  //cart
  addToCart: (courseSid, currency) => AxiosService.post(GLOBELCONSTANT.CART_CONTROLLER.ADD_TO_CART + `?course_sid=${courseSid}&currency=${currency}`),
  deleteFromCart: (cartId, courseSid) => AxiosService.delete(GLOBELCONSTANT.CART_CONTROLLER.DELETE_FROM_CART + `?cart_id=${cartId}&course_sid=${courseSid}`),
  displayCart: () => AxiosService.get(GLOBELCONSTANT.CART_CONTROLLER.DISPLAY_CART),
  createRazorPayOrder: (payload) => AxiosService.post(GLOBELCONSTANT.CART_CONTROLLER.CREATE_RAZORPAY_ORDER, payload),
  initiateOrderRefund: (payload) => AxiosService.post(GLOBELCONSTANT.CART_CONTROLLER.INITIATE_ORDER_REFUND, payload),
  applyCouponCode: (action, cartId, consumerSid, couponCode) => AxiosService.put(GLOBELCONSTANT.CART_CONTROLLER.APPLY_COUPON_CODE.replace("{cartId}", cartId) + `?action=${action}&consumer_sid=${consumerSid}&coupon_code=${couponCode}`),

  //order
  getUserOrderDetails: (consumerSid) => AxiosService.get(GLOBELCONSTANT.ORDERS.GET_USER_ORDER_DETAILS + `?consumer_sid=${consumerSid}`),

  //work with us:
  joinOurTeam: (payload) => AxiosService.uploadMultiPart(GLOBELCONSTANT.WORK_WITH_US.JOIN_OUR_TEAM, payload),
  becomeATrainer: (payload) => AxiosService.uploadMultiPart(GLOBELCONSTANT.WORK_WITH_US.BECOME_A_TRAINER, payload),
  partnerWithUs: (payload) => AxiosService.post(GLOBELCONSTANT.WORK_WITH_US.PARTNER_WITH_US, payload),

  //blog
  getBlogDetails: (blogSlug, pageNo, pageSize, categoryName) => AxiosService.get(GLOBELCONSTANT.BLOG.GET_BLOG_DETAILS.replace('{blogSlug}', blogSlug) + `?page_no=${pageNo}&page_size=5&category_name=${categoryName}`),
  commentOnBlog: (blogSlug, payload) => AxiosService.post(GLOBELCONSTANT.BLOG.COMMENT_ON_BLOG.replace('{blogSlug}', blogSlug) + `?page_no=1&page_size=5`, payload),
  getCommentReplies: (blogSlug, commentId, count) => AxiosService.get(GLOBELCONSTANT.BLOG.GET_COMMENT_REPLIES.replace('{blogSlug}', blogSlug).replace('{commentId}', commentId) + `?count=${count}`),
  replyOnComment: (blogSlug, commentId, payload) => AxiosService.post(GLOBELCONSTANT.BLOG.POST_COMMENT_REPLIES.replace('{blogSlug}', blogSlug).replace('{commentId}', commentId), payload),
  likeBlog: (blogSlug, pageNo, pageSize) => AxiosService.put(GLOBELCONSTANT.BLOG.LIKE_BLOG.replace('{blogSlug}', blogSlug) + `?page_no=${pageNo}&page_size=${pageSize}`),
  likeConversation: (blogSlug, commentId) => AxiosService.put(GLOBELCONSTANT.BLOG.LIKE_CONVERSATION.replace('{blogSlug}', blogSlug).replace('{commentId}', commentId))
};



export default RestService;
