import React, { useState, useEffect } from 'react';
import RestService from '../../Services/api.service';
import ReplyForm from './ReplyForm';
import './Comments.css';
import { REPLY_ICON } from './Icon';

function Comment({ comment, slug }) {
  const [replies, setReplies] = useState([]);
  const [showReplies, setShowReplies] = useState(false);
  const [countShowMore, setCountShowMore] = useState(1);
  const [showReplyForm, setShowReplyForm] = useState(false);
  const [commentid, setCommentid] = useState(comment?.commentId);
  const [repliescount, setRepliescount] = useState(comment?.replies);

  const firstLetter = comment?.commentedBy ? comment?.commentedBy.charAt(0).toUpperCase() : '';

  const fetchReplies = async () => {
    try {
      const response = await RestService.getCommentReplies(slug, comment?.commentId, countShowMore);
      if (response.status === 200) {
        setReplies(response?.data.replyDetails);
        setCommentid(response?.data.commentDetails.commentId)
      }
    } catch (err) {
      console.error("Error in fetchReplies: ", err);
    }
  }


  const handleShowReplies = () => {
    setShowReplies(!showReplies);
    if (!showReplies && countShowMore === 1) {
      fetchReplies();
    }
  };

  const handleShowMoreReplies = () => {
    setCountShowMore(prev => prev + 1);
  };



  const handleReplySubmit = (newReply) => {
    // setReplies(prevReplies => [...prevReplies, newReply]);
    fetchReplies()
    setShowReplyForm(false);
  };

  useEffect(() => {
    if (countShowMore > 0 && showReplies) {
      fetchReplies();
    }
  }, [countShowMore, slug, comment?.commentId, showReplies, commentid]);
  const authorImageStyle = {
    width: '60px',
    height: '60px',
    borderRadius: '50%',
    marginRight: '10px',
  };
  const authourstyleImgText={
    width: '60px',
    height: '60px',
    borderRadius: '50%',
    background:"rgb(189, 189, 189)",
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontFamily:"Inter",
    fontSize:"20px"
  }



  return (
    <div className="comment">
      <div className='d-flex' style={{ justifyContent: "space-between" }}>
        <div>
          <ul className="list-group pmd-list" style={{ border: 'none', marginTop: 'auto' }}>
            <li className="list-group-item d-flex flex-row" style={{ border: 'none' }}>
              {comment?.commentedByProfile !== null ?
                <img
                  className="post-card-author-image"
                  style={authorImageStyle}
                  src={comment?.commentedByProfile}
                // src="http://images.ctfassets.net/qqwc8vzzdbmu/4CHYpLp87zfiH3TdwZ7VO7/f9cf0494f44bf46b21f631ed1d931d1c/DP_DP.jpeg.png"
                />
                :
                <div className="post-card-author-image"
                style={{...authorImageStyle, ...authourstyleImgText}}>{firstLetter}</div>
              }
              <div>
                <h3 className="comment-user">{comment?.commentedBy}</h3>
                <div className="comment-date">{comment?.commentedOnDate}</div>
              </div>
            </li>
          </ul>
        </div>
        <div style={{ float: "inline-end" }}> <button onClick={() => setShowReplyForm(!showReplyForm)} className="comment-reply-btn">
          {REPLY_ICON} {showReplyForm ? 'Cancel' : 'Reply'}
        </button></div>
      </div>




      {/* <div className="comment-date">{comment?.commentedOnDate}</div> */}
      <div className="comment-content">{comment?.text}</div>

      <div className="comment-actions mt-5 ">
        {comment.replies > 0 && (
          <button onClick={handleShowReplies} className="comment-reply-btn">
            {showReplies ? 'Hide Replies' : `View Replies (${repliescount})`}
          </button>
        )}

      </div>
      {showReplies && replies.map(reply => (
        <Comment key={reply?.commentId} comment={reply} slug={slug} />
      ))}
      {showReplies && comment.replies > replies.length && (
        <button onClick={handleShowMoreReplies} className="comment-reply-btn">
          Show More Replies
        </button>
      )}
      {showReplyForm && (
        <ReplyForm
          parentCommentId={comment?.commentId}
          blogSlug={slug}
          onReplySubmit={handleReplySubmit}
        />
      )}
    </div>
  );
}

export default Comment;