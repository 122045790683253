import React, { useEffect, useState } from 'react';
import { createClient } from 'contentful';
import { Link, useParams } from 'react-router-dom';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS, INLINES, MARKS } from '@contentful/rich-text-types';
import './FullPost.css';
import { COPYLINK_ICON, FACEBOOK_ICON, GO_FORWARD, ICN_LANGUAGE, ICN_NEXT, ICN_TOTAL_LECTURE, ICN_TOTAL_TIME, LIKE_ICON, LINKEDIN_ICON, NEXT_PAGINATION_ICON, PEN_ICON, PREV_PAGINATION_ICON, TWITTER_ICON } from '../../Components/Common/Icon';
import SkeletonLoader from '../../Components/Common/SkeletonLoader';
import { ToastContainer } from 'react-toastify';
import ToastMessage from '../../Store/ToastHook';
import RestService from '../../Services/api.service';
import Comments from '../../Components/Common/Comments';
import NewCommentForm from '../../Components/Common/NewCommentForm';
import { Stack, Pagination } from '@mui/material';
import AnimationWrapper from '../../Components/Common/Animation Ref/AnimationWrapper';
import { Helmet } from 'react-helmet-async';
import CartContext from '../../Store/CartContext';
import { useContext } from 'react';

const FullPost = () => {
  const [loadingTop5, setLoadingTop5] = useState(true);
  const [recommendedCourses, setRecommendedCourses] = useState([]);
  const [loader, setLoader] = useState(true);
  const { id, category } = useParams();
  const [post, setPost] = useState(null);
  const [blogLoader, setBlogLoader] = useState(true);
  const [allComments, setAllComments] = useState();
  const [toogle, settogglereply] = useState();
  const [pageSize, setPageSize] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const { currency } = useContext(CartContext);

  let user = localStorage.getItem('user')
    ? JSON.parse(localStorage.getItem('user'))
    : '';
  //   {
  //     content_type: 'post', // Replace with your content type ID
  //     'sys.id': postId, // Fetch the post with the specified postId
  //   }
  const client = createClient({
    space: process.env.REACT_APP_BLOG_SPACE, // Replace with your Contentful space ID
    accessToken: process.env.REACT_APP_BLOG_ACCESS_TOKEN, // Replace with your Contentful access token
  });

  const paginationStyle = {
    fontSize: "15px", // Adjust the size as needed
  };


  const stackContainerStyle = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    margin: "25px 0px",
    padding: "15px 0px",
    borderTop: "1px solid #EAECF0",
    fontSize: "25px"


  };

  const handlePageChange = (event, value) => {
    // Update the state with the selected page number
    setCurrentPage(value);
    // You can perform any other actions with the page number here
  };



  const renderRichText = (content) => {
    return documentToReactComponents(content, {
      renderNode: {
        [BLOCKS.PARAGRAPH]: (node, children) => (
          <p style={{ marginBottom: '16px', lineHeight: '1.5', color: "#242424", fontFamily: "Inter", fontSize: "18px", fontWeight: "400", textAlign: "justify" }}>{children}</p>
        ),
        [BLOCKS.HEADING_2]: (node, children) => (
          <h2 style={{ marginBottom: '12px', color: "#242424", fontFamily: "Inter", fontSize: "30px", fontWeight: "600" }}>{children}</h2>
        ),
        [BLOCKS.HEADING_3]: (node, children) => (
          <h2 style={{ marginBottom: '12px', color: "#242424", fontFamily: "Inter", fontSize: "22px", fontWeight: "600" }}>{children}</h2>
        ),
        [BLOCKS.EMBEDDED_ASSET]: (node) => (
          <img
            src={node.data.target?.fields?.file.url}
            alt={node.data.target?.fields?.description}
            style={{ maxWidth: '100%', marginBottom: '16px', borderRadius: "12px" }}
          />
        ),
        [BLOCKS.EMBEDDED_ENTRY]: (node) => {
          return <p>[Embedded Entry: {node.data.target?.fields.title}]</p>;
        },
        [BLOCKS.TABLE]: (node, children) => (
          <table style={{ width: '100%', borderCollapse: 'collapse' }}>
            {children}
          </table>
        ),

        // Table Row
        [BLOCKS.TABLE_ROW]: (node, children) => (
          <tr>
            {children}
          </tr>
        ),

        // Table Cell
        [BLOCKS.TABLE_CELL]: (node, children) => (
          <td style={{ border: '1px solid #ddd', padding: '8px' }}>
            {children}
          </td>
        )
      },
      renderMark: {
        [MARKS.CODE]: (text) => {
          const codeStyle = {
            backgroundColor: '#F9FAFB', // Background color
            borderRadius: '16px', // Rounded corners
            padding: '8px', // Padding around the code block
            fontFamily: 'Inter', // Font family for code
            fontSize: '18px', // Font size
            lineHeight: '1.4', // Line height
            whiteSpace: 'pre-wrap', // Preserve whitespace and line breaks
            overflowX: 'auto', // Add horizontal scrollbar if needed
            marginBottom: '16px', // Spacing after code block
            padding: "32px"
          };


          return (
            <pre style={codeStyle}>
              <code>{text}</code>
            </pre>
          );
        },
      },
      renderInline: {
        [INLINES.ENTRY_HYPERLINK]: (node, children) => {
          return (
            <a
              href={`/post/${node.data.target.sys.id}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {children}
            </a>
          );
        },
      },
    });
  };



  const authorImageStyle = {
    width: '60px',
    height: '60px',
    borderRadius: '50%',
    marginRight: '10px',
  };
  const authorStyle = {
    display: 'flex',
    alignItems: 'center',

  };

  const handleNewComment = (newComment) => {
    // Assuming newComment is the newly added comment object
    settogglereply(!toogle)
    // setAllComments([...allComments , newComment]);
  };


  // useEffect(() => {
  //   setTimeout(() => {
  //     setLoader(false);
  //   }, [1000]);
  // }, [id]);


  // Fetch post details

  //get blog comments
  const getBlogDetails = () => {
    try {
      const pageNo = currentPage;
      const pageSize = 5;
      const blogSlug = id;
      const categoryName = category;
      RestService.getBlogDetails(blogSlug, pageNo, pageSize, categoryName).then(
        response => {
          if (response.status === 200) {
            const totalPageSize1 = (response.data.comments / 5);
            const totalPageSize2 = (response.data.comments % 5 > 0) ? 1 : 0;
            setAllComments(response.data);
            setPageSize(Math.floor(totalPageSize1 + totalPageSize2));
            setRecommendedCourses(response.data.recommendedCourses);
            setBlogLoader(false);
            setTimeout(() => {
              setLoadingTop5(false);
            }, 2000);

          }

        },
        err => {
          console.error(err);
        }
      )
    } catch (err) {
      console.error("error occur on getBlogDetails()", err)
    }
  }

  //for liking blog
  const likeBlog = () => {
    try {
      const pageNo = 1;
      const pageSize = 5;
      const blogSlug = id;
      RestService.likeBlog(blogSlug, pageNo, pageSize).then(
        response => {
          if (response.status === 200) {

            setAllComments(response.data);
            setBlogLoader(false);
          }

        },
        err => {
          console.error(err);
        }
      )
    } catch (err) {
      console.error("error occur on likeBlog()", err)
    }
  }

  //like for conversation
  const likeConversation = (commentId) => {
    setBlogLoader(true);
    try {
      const blogSlug = id;
      RestService.likeConversation(blogSlug, commentId).then(
        response => {
          if (response.status === 200) {

            setAllComments(response.data);
            setBlogLoader(false);
          }

        },
        err => {
          console.error(err);
        }
      )
    } catch (err) {
      console.error("error occur on likeConversation()", err)
    }
  }

  useEffect(() => {
    const fetchPost = async () => {
      try {
        setLoader(true);
        const response = await client.getEntry(id);
        setPost(response);
      } catch (error) {
        console.error('Error fetching post:', error);
      } finally {
        setLoader(false);
      }
    };

    fetchPost();
  }, [id]);

  // Fetch additional details based on user
  useEffect(() => {

    getBlogDetails();

  }, [toogle]);

  // Fetch additional details based on user
  useEffect(() => {

    getBlogDetails();

  }, [currentPage]);

  useEffect(() => {
    setTimeout(() => {
      setLoader(false);
    }, [1000]);
  }, []);



  if (loader) {
    return <div><SkeletonLoader skeletonCount={10.5} /></div>; // Replace with a proper loading indicator
  }

  if (!post) {
    return <div></div>; // More informative message
  }

  const { title, excerpt, author, coverImage, content, date } = post.fields;






  const copyUrl = () => {
    let copyText = document.getElementById(`${id}`);
    let textArea = document.createElement("textarea");
    textArea.value = copyText.textContent;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand("Copy");

    ToastMessage({ type: "success", message: 'link Copied', });
    textArea.parentNode.removeChild(textArea);
  }

  if (loader) {
    return <SkeletonLoader skeletonCount={10.5} />;
  }




  return (

    <>
      <Helmet>
        {/* Primary Meta Tags */}
        <link rel='canonical' href={`/blog/${id}/${category}`} />
        <title>{author?.fields?.name ? `${author?.fields?.name} - Learnlytica Blog Author` : `Learnlytica | Transform minds with brilliance, experience, and innovation.`}</title>
        <meta name="title" content={author?.fields?.name ? `${author?.fields?.name} - Learnlytica Blog Author` : `Learnlytica | Transform minds with brilliance, experience, and innovation.`} />
        <meta name="description" content={`Explore articles by ${author?.fields?.name} on Learnlytica's blog. Discover insights in personalized learning, technology, and skill development.`} />
        <meta name="theme-color" content="#004ba0" />
        <meta name="keywords" content={`${author?.fields?.name}, Learnlytica blog author, educational content, personalized learning, educational technology, skills development`} />

        {/* Open Graph / Facebook */}
        <meta property="og:type" content="website" />
        <meta property="og:url" content={`/blog/${id}/${category}`} />
        <meta property="og:title" content={author?.fields?.name ? `${author?.fields?.name} - Learnlytica Blog Author` : `Learnlytica | Transform minds with brilliance, experience, and innovation.`} />
        <meta property="og:description" content={`Explore articles by ${author?.fields?.name} on Learnlytica's blog. Discover insights in personalized learning, technology, and skill development.`} />
        <meta property="og:image" content={coverImage?.fields?.file.url || "https://metatags.io/images/meta-tags.png"} />

        {/* Twitter */}
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content={`/blog/${id}/${category}`} />
        <meta property="twitter:title" content={author?.fields?.name ? `${author?.fields?.name} - Learnlytica Blog Author` : `Learnlytica | Transform minds with brilliance, experience, and innovation.`} />
        <meta property="twitter:description" content={`Explore articles by ${author?.fields?.name} on Learnlytica's blog. Discover insights in personalized learning, technology, and skill development.`} />
        <meta property="twitter:image" content={coverImage?.fields?.file.url || "https://metatags.io/images/meta-tags.png"} />
      </Helmet>



      <ToastContainer />
      <div className='layout'>
        <div className='container text-center'>
          <h1 className='feature-screen-header py-3'>{title}</h1>

          <div className='mt-5 d-flex justify-content-center align-items-center'>
            <div className="post-card-author" style={authorStyle}>
              <div className='d-flex'>
                <div>
                  <img
                    src={author?.fields.picture.fields?.file.url}
                    alt={author?.fields.picture.fields?.description}
                    className="post-card-author-image"
                    style={authorImageStyle}
                    title={author?.fields.picture.fields?.description}
                  />
                </div>
                <div>
                  <h3 className="post-card-author-name">{author?.fields?.name}</h3>
                  <p >{new Date(date).toLocaleDateString()}</p>
                </div>
                {/* <div className='mx-4 ' >
                <a href={author?.fields?.linkedIn} target='blank'>
                <img src ="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAABDUlEQVR4AWP4////gOLB44D6nTcsGIo33QHi/zTGd0B2YTiAPpYjHIHNAf/piQk6wGPW8f/rLz8HYRCbXg5AWI4GQGJ0cwDY12gAJDbcHUA4CkZAIqQUK7Ts/m/SfxBMs5RupswBaACr+P47b/5zlG/5DyzZ/r/+8hNF7vuvP//nn3r0X6JhJ+0ccPrR+/+H7735jw9cf/n5v0D1Nuo5gBxQve06zR0AjoL7b7/+//zjN4bc+ScfaOeA33///k9Yfg4mDw7u/Xdeo6uhnQP6D93FMNxlxjF0ZbRzgMXEQ9iyI90cALIMJoccDXRzAK6CZog6YNQBow6gIx54Bwx4x2RAu2bAysoEZu9o7xgAQrvkxt3WZi0AAAAASUVORK5CYII="
               width="25px"
                />
              </a>
              </div> */}
                <div >

                  <a className=' btn-follow' style={{ padding: "2px 18px" }} href={author?.fields?.linkedIn} target='blank'>
                    Follow</a>

                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='layout'>

        <div className='container'>


          <div className="full-post-container">
            <div className="full-post-content">
              <img
                src={coverImage?.fields?.file.url}
                alt={coverImage?.fields?.description}
                title={coverImage?.fields?.description}
                className="full-post-cover-image"
              />
              <p className='feature-screen-text mt-5 mb-5' style={{ color: "#242424" }}>{excerpt}</p>

              {/* <p>Date: {new Date(date).toLocaleDateString()}</p> */}

              <div className="content pt-3 pb-5" style={{ fontSize: '20px' }} >{renderRichText(content)}

              </div>

            </div>
          </div>

        </div>

      </div>




      <div className='layout comment-header-section' >
        <div className='container'>
          <div className='row ' >
            <div className='col-lg-4 col-xl-4 col-md-4 col-sm-12'>
              <ul className="list-group pmd-list" style={{ border: 'none', marginTop: 'auto' }}>
                <li className="list-group-item d-flex flex-row" style={{ border: 'none' }}>

                  <img
                    alt={author?.fields.picture.fields?.description}
                    className="post-card-author-image"
                    style={authorImageStyle}
                    src={author?.fields.picture.fields?.file.url}
                    title=
                    {author?.fields.picture.fields?.description}
                  />
                  <div>
                    <h3 className="pmd-list-title">{author?.fields.name}</h3>
                    <div className="designation">{author?.fields?.designation}</div>
                  </div>
                </li>
              </ul>
            </div>
            <div className='col-md-8 col-sm-12 col-lg-8 col-xl-8 d-flex justify-content-center align-items-center'>
              <div className='numberoflikes copylink' onClick={() => likeBlog()}>{LIKE_ICON} {allComments?.likes} likes</div>
              <div className='copylink mx-4' onClick={() => copyUrl()}>{COPYLINK_ICON} Copy link


              </div>
              <div className="copy-url-disp" id={id}>
                {`${window.location.href}`}
              </div>

              <div className='social_icon'>{TWITTER_ICON}</div>
              <div className='social_icon mx-4'>{FACEBOOK_ICON}</div>
              <div className='social_icon'><a href={author?.fields?.linkedIn} target='blank'>{LINKEDIN_ICON}</a></div>
            </div>
          </div>
        </div>
      </div>





      <div className='layout comment-header-section'>
        <div className='container'>
          {
            blogLoader ?

              <SkeletonLoader skeletonCount={1.5} />
              :
              <div className='no_of_comments'>Comments ({allComments?.comments})</div>
          }
          <NewCommentForm blogSlug={allComments?.blogSlug} onCommentAdded={handleNewComment} />

          {
            blogLoader ?

              <SkeletonLoader skeletonCount={1.5} />
              :
              <Comments blogComments={allComments.blogComments} totalComments={allComments.comments} totalLikes={allComments.likes} />
            // <Comment comment={allComments.blogComments} blogSlug={allComments?.blogSlug} />
          }
          {
            pageSize > 0 &&

            <div style={stackContainerStyle}>
              <Stack spacing={3} direction="row" alignItems="center" justifyContent="space-between" width="100%" borderRadius="8px"
                border="1px solid var(--Gray-300, #D0D5DD)"
                padding="10px 16px"

                boxShadow="0px 1px 2px 0px rgba(16, 24, 40, 0.05)">
                <button className='page_button' style={{ float: "left" }} onClick={() => setCurrentPage(currentPage - 1)} disabled={currentPage <= 1}
                >{PREV_PAGINATION_ICON} Previous</button>

                <Pagination count={pageSize} page={currentPage} onChange={handlePageChange}
                  size='large' />
                <button className='page_button' style={{ float: "inline-end" }} onClick={() => setCurrentPage(currentPage + 1)}
                  disabled={currentPage === pageSize}>Next {NEXT_PAGINATION_ICON}</button>

              </Stack>


            </div>
          }
        </div>
      </div>
      {/* explore all courses */}
      <div className='layout '>
        <div className='container'>
          <div className='row'>
            <div className='col-12 col-sm-9 col-md-9 col-lg-9'>
              <AnimationWrapper animationType="heading">     <h3 className='feature-screen-header'>Recommended Courses</h3></AnimationWrapper>
              {/* <AnimationWrapper animationType="subHeading">    <div className='feature-screen-text'>
         one supporting text goes here, not more than one line.
        </div></AnimationWrapper>  */}

            </div>

            <div className='col-12 col-sm-3 col-md-3 col-lg-3'>
              <AnimationWrapper animationType="button">
                <Link to="/course-library" class="btn-explore">Explore all Courses {GO_FORWARD}</Link></AnimationWrapper>
            </div>
          </div>

          {
            recommendedCourses.length === 0 ?
              <div className='layout'>
                <div className='container'>
                  <div className='ph  text-center' style={{ fontSize: "25px" }}>
                    Courses being curated by our delivery team will be available soon.
                  </div>

                </div>
              </div>
              :
              <div class="row mt-5 mb-5">

                {
                  recommendedCourses?.map((item) => {
                    return (
                      <div class="col-12 col-sm-12 col-md-6 col-lg-4 mb-3">

                        <div class="card py-1 px-5 h-100 ">
                          <AnimationWrapper animationType="card">
                            {/* {item.headerimg} */}
                            <div class="card-body d-flex flex-column">

                              <h4 class="card-title mt-4">{loadingTop5 ?
                                <SkeletonLoader skeletonCount={1} />
                                : item?.courseName}</h4>
                              <div className='d-flex flex-wrap pt-3 gap-2'>
                                {/* <button className='card-top-btn flex-grow-1 mb-2'>{loadingTop5 ?
                     <SkeletonLoader skeletonCount={1} />
                     : item.categoryName}</button> */}
                                {/* <button className='card-top-btn flex-grow-1 mb-2'>{loadingTop5 ?
                     <SkeletonLoader skeletonCount={1} />
                     : item.subCategoryName}</button> */}
                                {
                                  loadingTop5 ?
                                    <SkeletonLoader skeletonCount={5} />
                                    :
                                    item.tags.map((tag) => {
                                      return (
                                        <button className='card-top-btn flex-grow-1 mb-2'>{tag}</button>
                                      )
                                    })
                                }

                              </div>
                              <div className='d-flex gap-5 mb-4 mt-4'>
                                <div className='total card-subtitle'><span className='mx-2'>{ICN_TOTAL_LECTURE}</span><span className='mt-5'>{loadingTop5 ?
                                  <SkeletonLoader skeletonCount={1} />
                                  : item?.totalContent} Lectures</span></div>
                                <div className='total card-subtitle'> <span className='mx-2'>{ICN_TOTAL_TIME}</span> <span className='mt-5'>{loadingTop5 ?
                                  <SkeletonLoader skeletonCount={1} />
                                  : item?.totalDuration} Total Hours</span></div>
                              </div>
                              <p class="card-text gap-2"><span >{ICN_LANGUAGE}</span>{loadingTop5 ?
                                <SkeletonLoader skeletonCount={1} />
                                : item?.languages?.toString()} </p>
                              <div className="course-card-h"> {currency === 'INR' ? '₹' : '$'}{loadingTop5 ?
                                <SkeletonLoader skeletonCount={1} />
                                :
                                currency === 'INR' ?
                                  Math.floor(item?.price)
                                  :
                                  Math.floor(item?.internationalCoursePrice)
                              } </div>
                              <Link to={`/course/${item.courseSid}`} class="card-view-course mt-auto">{loadingTop5 ?
                                <SkeletonLoader skeletonCount={1} />
                                : "View Course"} {ICN_NEXT}</Link>

                              {/* <button  class="card-view-course" style={{border:"none",background:"transparent"
                       }}>View Course {ICN_NEXT}</button> */}

                            </div>
                          </AnimationWrapper>
                        </div>

                      </div>
                    )
                  })
                }

              </div>
          }
        </div>
      </div>


    </>



  );
};

export default FullPost;