import React from 'react'
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
const Whatsapp = ({phone}) => {
  return (
    <>
    <a href={`https://api.whatsapp.com/send?phone=${phone}&text=`} class="float" target="_blank">
    <div class=" my-float"><WhatsAppIcon fontSize='md'/></div>

    
    </a>
    </>
  )
}

export default Whatsapp