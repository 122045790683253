import React, { useState, useEffect, useContext } from 'react'
import { ICN_LANGUAGE, ICN_TOTAL_LECTURE, ICN_TOTAL_TIME, IMG_CARD, REMOVE_ICN, PURCHASE_EMPTY_ICON } from '../../../Components/Common/Icon';
import RestService from '../../../Services/api.service';
import axios from 'axios';
import GLOBELCONSTANT from '../../../Constant/GlobleConstant';
import ToastMessage from '../../../Store/ToastHook';
import { ToastContainer } from 'react-toastify';
import CartContext from '../../../Store/CartContext';

const OrderHistory = () => {
  const [orderDetails, setOrderDetails] = useState([]);
  const { currency } = useContext(CartContext);
  let user = localStorage.getItem('user')
    ? JSON.parse(localStorage.getItem('user'))
    : '';

  //get user order details
  const getUserOrderDetails = () => {
    try {
      // spinner.show();
      // const subCategorySid = subCategorySid;
      const consumerSid = user?.sid;
      RestService.getUserOrderDetails(consumerSid).then(
        response => {
          setOrderDetails(response.data);
        },
        err => {
          console.error(err);
        }
      )
    } catch (err) {
      console.error("error occur on getUserOrderDetails()", err)
    }
  }

  //request refund status
  const initiateOrderRefund = (orderId) => {
    try {
      // spinner.show();
      // const subCategorySid = subCategorySid;
      const consumerSid = user?.sid;
      const payload = {
        "consumerSid": consumerSid,
        "orderId": orderId
      }
      RestService.initiateOrderRefund(payload).then(
        response => {
          if (response.status === 200) {
            ToastMessage({ type: "success", message: 'Refund Successful' });
            getUserOrderDetails();
          }
        },
        err => {
          console.error(err);
        }
      )
    } catch (err) {
      console.error("error occur on initiateOrderRefund()", err)
    }
  }

  //download receipt
  const printOrderDetailsPdf = (cartId) => {
    const consumerSid = user?.sid;
    const consumerName = user?.appuser?.name;
    const url = GLOBELCONSTANT.CART_CONTROLLER.PRINT_ORDER_DETAILS_PDF.replace("{cartId}", cartId);
    axios
      .patch(url + `?consumer_sid=${consumerSid}`, null, {
        responseType: 'blob', // Set responseType in the Axios configuration
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/pdf',
        },
      })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));

        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${consumerName}.pdf`); // Specify the correct file name
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => {
        console.error();
      });
  };

  useEffect(() => {
    getUserOrderDetails();
  }, []);


  return (
    <>
      <ToastContainer />
      <div className='layout'>
        {orderDetails.length === 0 ?
          <div className='layout'>
            <div className='container'>
              <div className='section-heading  ' style={{ margin: "auto" }}> You haven't purchased anything</div>
            </div>
          </div>

          :
          <div className='container'>

            <div class="row mt-5 mb-5">

              <div class="col-12 col-sm-12 ">
                <div class="card" >
                  <div className='row '>
                    {/* {
      item.thumbnail !== null &&
      <div className='col-md-4'>
        <img src={item.thumbnail} height="100%" width="100%" />
      </div>
    } */}

                    {/* <div className='col-md-8'> */}
                    <div class="card-body">

                      {orderDetails.map((item, index) => {
                        return (
                          <>
                            <div className='d-flex gap-5 p-3 ' style={{ border: "1px solid #f0f2f1", background: "#f0f2f1", alignContent: "center" }}>
                              <div className='total card-subtitle'>
                                Total Amt. {Math.floor(item?.cartTotal)}

                              </div>
                              <div className='total card-subtitle'>Purchased On {item.paidOn}</div>
                              {
                                item?.couponCode !== null &&
                                <>
                                  <div className='total card-subtitle'>Coupon Applied {item.couponCode}</div>
                                  <div className='total card-subtitle'>Discount {item.discount}%</div>
                                </>
                              }

                              <div className='ms-auto' style={{ cursor: 'pointer', color: '#6941C6', fontWeight: 500 }} onClick={() => printOrderDetailsPdf(item.cartId)}>Download Receipt</div>
                            </div>
                            {
                              item.itemDetails.map((order) => {
                                return (
                                  <div className='p-3'>
                                    <div className='d-flex' style={{ justifyContent: "space-between" }}>
                                      <h4 class="card-title mt-4">{order.courseName}</h4>
                                      <div class="card-title mt-4">{currency === 'INR' ? '₹' : '$'}{Math.floor(order?.coursePrice)}</div>
                                    </div>
                                    <div className='d-flex gap-3'>
                                      <button className='card-top-btn'>{order.categoryName}</button>
                                      <button className='card-top-btn'>{order.subCategoryName}</button>
                                      {
                                        order.tags.map((tag) => {
                                          return (
                                            <button className='card-top-btn'>{tag}</button>
                                          )
                                        })
                                      }

                                      {/* <button className='card-top-btn'>Git</button> */}
                                    </div>

                                    <div className='d-flex gap-5 mb-4 mt-4'>
                                      <div className='total card-subtitle'><span className='mx-2'>{ICN_TOTAL_LECTURE}</span><span className='mt-5'>{order.totalContent} Lectures</span></div>
                                      <div className='total card-subtitle'> <span className='mx-2'>{ICN_TOTAL_TIME}</span> <span className='mt-5'>{order.totalDuration} Total Hours</span></div>
                                    </div>
                                    <div className='d-flex' style={{ justifyContent: "space-between", textAlign: "center", alignItems: "center" }}>
                                      <p class="card-text gap-2"><span >{ICN_LANGUAGE}</span>{order.languages.toString()} </p>
                                      {/* <Link to={`/course/${item.title}`} class="card-view-course">View Course {ICN_NEXT}</Link> */}

                                      {
                                        order.enableForRefund && item.discount !== 100 ?
                                          <button
                                            className='removebtn'

                                            onClick={() => { initiateOrderRefund(order.userOrderId) }}
                                          >
                                            {/* {REMOVE_ICN} */}
                                            Refund
                                          </button>
                                          :
                                          order?.refundStatus === 'REFUNDED' && order?.refundProcessingStatus !== null ?
                                            <div className='removebtn'>Refund Processed On: {JSON.parse(order.refundProcessingStatus)['refund.processed']}</div>
                                            :
                                            order?.refundStatus === 'REFUNDED' && order?.refundRequestOn &&
                                            <div className='removebtn'>Refund Requested On: {order?.refundRequestOn}</div>
                                        // order.refundStatus === 'REFUNDED' && order.enableForRefund === false &&
                                        // <div className='removebtn'>
                                        //   Refunded
                                        // </div>
                                        // <button
                                        //   className='removebtn'

                                        //   onClick={() => { initiateOrderRefund(order.userOrderId) }}
                                        // >
                                        //   {/* {REMOVE_ICN} */}
                                        //   Refunded
                                        // </button>
                                      }
                                    </div>
                                    <hr />


                                  </div>
                                )
                              })
                            }

                          </>
                        )
                      })}
                    </div>
                    {/* </div> */}


                    {/* <div className='col-md-4'>
hjhjhjhjhj
      </div> */}
                  </div>

                </div>
              </div>



            </div>

          </div>

        }

      </div>


    </>
  )
}

export default OrderHistory