import React, { useState } from 'react'

import { Modal } from 'react-bootstrap';
import { REMOVE_ICN } from '../Common/Icon';
const AllModal = ({ children, setShow, headerTitle, show, size = "md", headerAction = null }) => {

    return (
        <Modal
            show={show}
            onHide={() => setShow(false)}
            dialogClassName="modal-100w"
            aria-labelledby="example-custom-modal-styling-title"
           

        >
            <Modal.Header closeButton>
                <Modal.Title id="example-custom-modal-styling-title">
                    {headerTitle}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ padding: 0, overflow: 'hidden' }}>
                {children}

            </Modal.Body>
        </Modal>
    );
}

export default AllModal






