import React, { useState, useEffect } from 'react'
import { ICN_CHROME, ICN_HEADER_LOGO1, ICN_LOGO } from '../../Components/Common/Icon'
// import "./Auth.css"
import { Link } from 'react-router-dom'
import RestService from '../../Services/api.service'
import Readytoexplore from "../../Assets/Images/readytoexplore.png"
import "../../Components/Randomclass.css"
import SkeletonLoader from '../../Components/Common/SkeletonLoader'
import ToastMessage from '../../Store/ToastHook'
import { ToastContainer } from 'react-toastify'
import { Helmet } from 'react-helmet-async';
import { Spinner } from 'react-bootstrap';
const Contactus = () => {
  const [loading, setLoading] = useState(false);
  const [loader, setLoader] = useState(true)
  const [affiliation, setAffiliation] = useState('Company');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [jobTitle, setJobTitle] = useState('');
  const [jobLevel, setJobLevel] = useState('');
  const [companySize, setCompanySize] = useState('15-20');
  const [traineeSize, setTraineeSize] = useState('15-20');
  const [organizationTrainingNeeds, setOrganizationTrainingNeeds] = useState('');
  const [privacy, setPrivacy] = useState(false);

  const [firstNameError, setFirstNameError] = useState('');
  const [emailError, setEmailError] = useState('');
  const [phoneNumberError, setPhoneNumberError] = useState('');
  const [companyNameError, setCompanyNameError] = useState('');

  const handleFirstNameChange = (e) => {
    const value = e.target.value;
    setFirstName(value);

    // Validate first name (only letters, no spaces, at least 3 characters)
    const isValid = /^[A-Za-z]+$/.test(value);
    setFirstNameError(
      isValid ? '' : 'Only alphabetic characters'
    );
  };

  const handleEmailChange = (e) => {
    const value = e.target.value;
    setEmail(value);

    // Validate email
    const isValidEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value);
    setEmailError(isValidEmail ? '' : 'Invalid email address');
  };

  const handlePhoneNumberChange = (e) => {
    const value = e.target.value;
    setPhoneNumber(value);

        // Check if the value is exactly 10 digits long and consists only of numbers
        const isValidLength = value.length === 10;
        const containsOnlyNumbers = /^\d+$/.test(value);
    
        // Update the error message based on the validation
        if (!containsOnlyNumbers) {
            setPhoneNumberError('Phone number must contain only digits');
        } else if (!isValidLength) {
            setPhoneNumberError('Phone number must be exactly 10 digits');
        } else {
            setPhoneNumberError(''); // No error
        }

    // // Validate phone number
    // const isValidPhoneNumber = /^[1-9][0-9]{0,9}$/.test(value);
    // setPhoneNumberError(
    //   isValidPhoneNumber ? '' : 'Phone number must start with a digit between 1-9 and have a maximum of 10 digits'
    // );
  };

  const handleCompanyNameChange = (e) => {
    const value = e.target.value;
    setCompanyName(value);

    // Validate company name (optional, adjust as needed)
    const isValidCompanyName = /^[A-Za-z0-9\s]+$/.test(value);
    setCompanyNameError(isValidCompanyName ? '' : 'Only letters, numbers, and spaces are allowed');
  };

  const contactUsForm = () => {
    setLoading(true);
      try {
        let payload =
        {
          "affiliation": affiliation,
          "companyName": companyName,
          "companySize": companySize,
          "email": email,
          "firstName": firstName,
          "jobLevel": jobLevel,
          "jobTitle": jobTitle,
          "lastName": lastName,
          "organizationTrainingNeeds": organizationTrainingNeeds,
          "phoneNumber": phoneNumber,
          "traineeSize": traineeSize
        }

        RestService.contactUsForm(payload).then(res => {
          
          ToastMessage({ type: "success", message: res.data });
          setAffiliation('Company');
          setCompanyName('');
          setCompanySize('15-20');
          setEmail('');
          setFirstName('');
          setJobLevel('');
          setJobTitle('');
          setLastName('');
          setOrganizationTrainingNeeds('');
          setPhoneNumber('');
          setTraineeSize('15-20');
          setPrivacy(false);
          setLoading(false);

          // setShow(false)
        }, err => {
          ToastMessage({ type: "error", message: `${err.response.data.message}` });
          setLoading(false);
        }
        );
      }
      catch (err) {
        console.error();
      
        // Toast.error({ message: Something wrong!! });
      }
      
    }

  useEffect(() => {
    setTimeout(() => {
      setLoader(false);
    }, [1000]);
  }, []);

  return (
    <>
    <Helmet>
    <title>Contact Learnlytica - Get in Touch with Us Today</title>
<meta name="description" content="Contact Learnlytica today for inquiries, feedback, or partnerships. Reach out to us for any questions regarding our courses, training programs, or educational services."/>
<meta name="keywords" content="Contact Learnlytica, get in touch, inquiries, feedback, partnerships, course inquiries, training programs, educational services"/>

    </Helmet>
      <ToastContainer />
      <div className='container'>
        <div className='row'>

          <div className='col-md-6 col-lg-6 col-xl-6 col-sm-12 mt-5'>
            {loader ?
              <SkeletonLoader skeletonCount={5} />
              :
              <h2 className='contactusheader mt-5'>

                Empower your employees to upskill with our corporate training solution</h2>
            }
            {loader ?
              <SkeletonLoader skeletonCount={5} />
              :
              <p className='pt-3 contactussubheader'>In this demo, you’ll learn about:</p>
            }

            {loader ?
              <SkeletonLoader skeletonCount={5} />
              :
              <div className='sectionpoint pt-5 mb-3'>
                <ul>
                  <li> Unlimited access to 24,000+ fresh courses across tech, business, leadership and wellness
                  </li>
                  <li> Robust corporate training platform features offering on-demand immersive, and cohort-based learning for your team
                  </li>
                  <li> Personalized learning and actionable learner insights to drive high usage and course completion rates
                  </li>

                </ul>
              </div>
            }
          </div>


          <div className='col-md-6 col-lg-6 col-xl-6 col-sm-12'>
            {
              loader ?
                <SkeletonLoader skeletonCount={5.6} />
                :
                <div class="signup-form">

                  {/* <div className='text-center'>
    {ICN_LOGO}
</div> */}

                  {/* <hr/> */}

                  <div class="form-group pt-5">
                    <label> Select Your Affiliation</label>
                    <div class="input-group">
                      {/* <div class="input-group-prepend">
  <span class="input-group-text">
    <span class="fa fa-user"></span>
  </span>                    
</div> */}
                      {/* <input type="text" onChange={(e)=>setName(e.target.value)} value={name} class="form-control" name="username" placeholder="Enter your Name" required="required"/>
*/}

                      <select name="affiliation" id="affiliation" className='form-control' value={affiliation} onChange={(e) => setAffiliation(e.target.value)}>
                        {/* <option value="Individual">Individual</option> */}
                        <option value="Company">Company</option>

                      </select>

                    </div>
                  </div>

                  <div className='row'>
                    <div className='col-md-6 col-lg-6 col-sm-12'>
                      <div class="form-group ">
                        <label>First Name<sup style={{fontSize:"14px"}}>*</sup></label>
                        <div class="input-group">
                          {/* <div class="input-group-prepend">
  <span class="input-group-text">
    <span class="fa fa-user"></span>
  </span>                    
</div> */}
                          <input type="text" onChange={handleFirstNameChange} value={firstName} class="form-control" name="firstName" placeholder="First Name" required />
                        </div>
                        {firstNameError && <div className="error-message text-danger">{firstNameError}</div>}
                      </div>
                    </div>

                    <div className='col-md-6 col-lg-6 col-sm-12'>
                      <div class="form-group ">
                        <label>Last Name</label>
                        <div class="input-group">
                          {/* <div class="input-group-prepend">
  <span class="input-group-text">
    <span class="fa fa-user"></span>
  </span>                    
</div> */}
                          <input type="text" onChange={(e) => setLastName(e.target.value)} value={lastName} class="form-control" name="username" placeholder="Last Name" />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="form-group">
                    <label>Email<sup style={{fontSize:"14px"}}>*</sup></label>
                    <div class="input-group">
                      {/* <div class="input-group-prepend">
  <span class="input-group-text">
    <i class="fa fa-paper-plane"></i>
  </span>                    
</div> */}
                      <input type="email" onChange={handleEmailChange} class="form-control" name="email" placeholder="Email Address" value={email}/>

                    </div>
                    {emailError && <div className="error-message text-danger">{emailError}</div>}
                  </div>
                  <div class="form-group">
                    <label>Phone Number<sup style={{fontSize:"14px"}}>*</sup></label>
                    <div class="input-group">
                      {/* <div class="input-group-prepend">
  <span class="input-group-text">
    <i class="fa fa-lock"></i>
  </span>                    
</div> */}

                      <input type="text" inputmode="numeric" onChange={handlePhoneNumberChange} value={phoneNumber} class="form-control" name="phoneNumber" placeholder="Enter your Number" />

                    </div>
                    {phoneNumberError && <div className="error-message text-danger">{phoneNumberError}</div>}
                  </div>
                  <div class="form-group">
                    <label>Company Name<sup style={{fontSize:"14px"}}>*</sup></label>
                    <div class="input-group">
                      {/* <div class="input-group-prepend">
  <span class="input-group-text">
    <i class="fa fa-lock"></i>
  </span>                    
</div> */}

                      <input type="text" onChange={handleCompanyNameChange} value={companyName} class="form-control" name="companyName" placeholder="Company Name" />
                    </div>
                    {companyNameError && <div className="error-message text-danger">{companyNameError}</div>}
                  </div>
                  <div class="form-group">
                    <label>Job Title</label>
                    <div class="input-group">
                      {/* <div class="input-group-prepend">
  <span class="input-group-text">
    <i class="fa fa-lock"></i>
  </span>                    
</div> */}

                      <input type="text" onChange={(e) => setJobTitle(e.target.value)} value={jobTitle} class="form-control" name="jobTitle" placeholder="Job Title" required="required" />
                    </div>
                  </div>
                  <div class="form-group">
                    <label>Job level</label>
                    <div class="input-group">
                      {/* <div class="input-group-prepend">
  <span class="input-group-text">
    <i class="fa fa-lock"></i>
  </span>                    
</div> */}

                      <input type="text" onChange={(e) => setJobLevel(e.target.value)} value={jobLevel} class="form-control" name="jobLevel" placeholder="Job Level" required="required" />
                    </div>
                  </div>



                  <div className='row'>
                    <div className='col-md-6 col-lg-6 col-sm-12'>
                      <div class="form-group ">
                        <label>Company Size</label>
                        <div class="input-group">
                          {/* <div class="input-group-prepend">
  <span class="input-group-text">
    <span class="fa fa-user"></span>
  </span>                    
</div> */}
                          {/* <input type="text" onChange={(e)=>setJobTitle(e.target.value)} value={jobtitle} class="form-control" name="username" placeholder="Job Title" required="required"/>
*/}


                          <select name="companySize" id="companySize" className='form-control' value={companySize} onChange={(e) => setCompanySize(e.target.value)}>
                            <option value="15-20">15-20</option>
                            <option value="20-25">20-25</option>
                            <option value="25-50">25-50</option>
                            <option value="50-100">50-100</option>
                            <option value="Greater than 100">Greater than 100</option>
                          </select>

                        </div>
                      </div>
                    </div>

                    <div className='col-md-6 col-lg-6 col-sm-12'>
                      <div class="form-group ">
                        <label>Number Of People to train</label>
                        <div class="input-group">
                          {/* <div class="input-group-prepend">
  <span class="input-group-text">
    <span class="fa fa-user"></span>
  </span>                    
</div> */}
                          {/* <input type="text" onChange={(e)=>setPhone(e.target.value)} value={phone} class="form-control" name="username" placeholder="Enter your Number" required="required"/>
*/}


                          <select name="trainEmployee" id="trainEmployee" className='form-control' value={traineeSize} onChange={(e) => setTraineeSize(e.target.value)}>
                            <option value="15-20">15-20</option>
                            <option value="20-25">20-25</option>
                            <option value="25-50">25-50</option>
                            <option value="50-100">50-100</option>
                            <option value="Greater than 100">Greater than 100</option>
                          </select>

                        </div>
                      </div>
                    </div>
                  </div>


                  <div class="form-group ">
                    <label>What are your organizations training needs ?</label>
                    <div class="input-group">
                      {/* <div class="input-group-prepend">
  <span class="input-group-text">
    <span class="fa fa-user"></span>
  </span>                    
</div> */}
                      <textarea rows="5" class="form-control" column="5" value={organizationTrainingNeeds} onChange={(e) => setOrganizationTrainingNeeds(e.target.value)} placeholder='Leave us a message ...'></textarea>

                    </div>
                  </div>
                  <div class="form-group">
                    <label class="form-check-label"><input type="checkbox" required="required" onClick={(e) => setPrivacy(e.target.checked)}/>

                     <span className='mx-2'>You agree to our friendly</span>  <Link to="/legal/privacy">privacy policy.</Link>


                    </label>
                  </div>





                  <div class="form-group">
                    <button type="submit" disabled={
                      !privacy ||
                      firstName.length === 0 || email.length === 0
                      || phoneNumber.length === 0 || companyName.length === 0 ||
                      firstNameError.length > 0 || emailError.length > 0
                      || phoneNumberError.length > 0 || companyNameError.length > 0} 
                      onClick={contactUsForm}
                      class="btn btn-primary btn-lg"
                      >
                      
                      {loading ? (
                        <>
                            <Spinner
                                as="span"
                                animation="border"
                                size="md"
                                role="status"
                                aria-hidden="true"
                                className="mr-2" // Add margin to separate spinner from text
                                style={{ border: '5px dotted #fff' }}
                            />
                            {' '}
                            Loading...
                        </>
                    ) :
                      "Send Message"}</button>
                  </div>








                </div>
            }
          </div>

        </div>
      </div>
    </>
  )
}



export default Contactus