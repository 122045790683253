import React from "react";
import { Collapse } from "react-collapse";

import { css } from "@emotion/css";

class DropdownItemforSidebar extends React.Component {
  state = {
    isDropdownOpen: false,
    dropdownToggleIcon: "˅"
  };

  onDropdownClicked = () => {
    this.setState(prevState => ({
      isDropdownOpen: !prevState.isDropdownOpen,
      dropdownToggleIcon: prevState.dropdownToggleIcon === "˅" ? "^" : "˅"
    }));
  };

  render() {
    return (
      <div style={{
        backgroundColor: this.state.isDropdownOpen ? "#fff" : "inherit",
      }}
        className={
          this.props.theme === "dark"
            ? accordionContainerDark
            : accordionContainerLight
        }
        id={this.props.id}
      >
        <div  style={{
    backgroundColor: this.state.isDropdownOpen ? "#F5F8FB" : "inherit",
  }}
          className={
            this.props.theme === "dark" ? headerBarDark : headerBarLight
          }
          onClick={this.onDropdownClicked}
      
        >
          <div  style={{display:"flex",flexDirection:"column" }}>
          <div>{this.props.title}</div>
          <p className="pt-1 pb-1" style={{fontSize:"16px",fontWeight:"400",color:"#475467"}}>{this.props.total}</p>
          </div>
          <button
            className={
              this.props.theme === "dark"
                ? openAccordionToggleDark
                : openAccordionToggleLight
            }
            // onClick={this.onDropdownClicked}
          >
            {this.state.dropdownToggleIcon}
          </button>
        </div>
        <Collapse isOpened={this.state.isDropdownOpen}>
          <div className={displayText}>{this.props.children}</div>
        </Collapse>
      </div>
    );
  }
}

export default DropdownItemforSidebar;

//Light Theme
const headerBarLight = css`
  display: flex;
  justify-content: space-between;
  border-bottom: 2px  #FAFAFA;
  // margin-bottom: 12px;
  padding: 5px;
  font-size: 20px;
`;

const openAccordionToggleLight = css`
  background-color: transparent;
  border: none;
  font-size: 18px !important;
  font-family: 'Inter' !important;
  cursor: pointer;
  color: #272D3B;
    font-weight: 500
`;

const displayText = css`
  text-align: left;
`;

const accordionContainerLight = css`
  margin: 10px;
  color: black;
`;
const color = 'darkgreen'
//Dark Theme
const headerBarDark = css`

  display: flex;
  justify-content: space-between;
  color: var(--Gray-700, #344054);

  /* Text md/Medium */
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */
    &:hover{
      background-color: #ececec;
border-radius:1px
    }`;

const accordionContainerDark = css`
  // margin: 10px;
  // color: #272D3B;
  // font-weight: bold
  // font-size: 12px !important;
  // font-family: 'Inter';

  justify-content: space-between;
// border-bottom: 0.1px ridge #EAECF0;
cursor: pointer;
// margin-bottom: 10px;
// padding: 5px;
font-size: 16px!important;
font-family: 'Inter';
color: #101828;
font-weight: 500;
`;

const openAccordionToggleDark = css`
display: -webkit-box;
display: -webkit-flex;
display: -ms-flexbox;
display: flex;
-webkit-box-pack: justify;
-webkit-justify-content: space-between;
justify-content: space-between;
// border-bottom: 0.1px ridge #EAECF0;
cursor: pointer;
margin-bottom: 10px;
padding: 5px;
font-size: 20px!important;
font-family: 'Inter';
color: #101828;
// font-weight: 900;
border:none;
background:transparent
`;
