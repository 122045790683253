import React, { useEffect, useState } from 'react';
import { createClient } from 'contentful';
import { BrowserRouter as Router, Switch, Route, Link, Outlet } from 'react-router-dom';

import PostCard from './PostCard';
import FullPost from './FullPost';
import SkeletonLoader from '../../Components/Common/SkeletonLoader';
import Select from 'react-select';
import { ICN_SEARCH, IMG_BLOG_HEADER, NEXT_PAGINATION_ICON, PREV_PAGINATION_ICON } from '../../Components/Common/Icon';
import AnimationWrapper from '../../Components/Common/Animation Ref/AnimationWrapper'
import { Stack, Pagination } from '@mui/material';
import { Helmet } from 'react-helmet-async';


const Post = () => {
  const [selectedOption, setSelectedOption] = useState(null);
  const [posts, setPosts] = useState([]);
  const [filterpost, setFilterpost] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [pageSize, setPageSize] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  // const [currentPageShow, setCurrentPageShow] = useState(0);

  const client = createClient({
    space: process.env.REACT_APP_BLOG_SPACE, // Replace with your Contentful space ID
    accessToken: process.env.REACT_APP_BLOG_ACCESS_TOKEN, // Replace with your Contentful access token
  });

  const handlePageChange = (event, value) => {
    // Update the state with the selected page number
    // setCurrentPageShow(value);
    setCurrentPage(value);
    // You can perform any other actions with the page number here
  };

  const paginationStyle = {
    fontSize: "15px", // Adjust the size as needed
  };


  const stackContainerStyle = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    margin: "25px 0px",
    padding: "15px 0px",
    borderTop: "1px solid #EAECF0",
    fontSize: "25px"


  };


  useEffect(() => {
    const fetchPosts = async () => {
      const pageNo = currentPage - 1;
      try {
        const response = await client.getEntries({
          content_type: 'post', // Replace with your content type ID
          order: '-fields.date', // Optionally, order posts by date in descending order
          limit: 6,
          skip: pageNo*6
        });

        // Extract the post objects from the response
        const retrievedPosts = response.items;
        const pageSize = Math.floor((response.total / response.limit) + ((response.total % response.limit) > 0 ? 1 : 0));
        setPosts(retrievedPosts);
        setFilterpost(retrievedPosts);
        setPageSize(pageSize);
      } catch (error) {
        console.error('Error fetching posts:', error);
      }
    };

    fetchPosts();
  }, [currentPage]);

  const filterCourses = () => {
    if (searchTerm.trim() === '') {
      setFilterpost(posts); // Show all courses if no search term
    } else {
      const filtered = posts.filter(post =>
        post.fields.title?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        post.fields.author.fields?.name?.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setFilterpost(filtered);
    }
  };

  const handleInputChange = (e) => {
    setSearchTerm(e.target.value);
    filterCourses(); // Filter courses on every input change
  };


  const [loader, setLoader] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setLoader(false);
    }, [1000]);
  }, []);

  const categorttitile = {
    color: "#6941C6",

    /* Text sm/Semibold */
    fontFamily: "Inter",
    fontSize: "14px",

    fontWeight: "600",
    lineHeight: "20px" /* 142.857% */
  }

  const options = [
    { value: 'Leadership', label: 'Leadership' },
    { value: 'Product', label: 'Product' },
    { value: 'Software Engineering', label: 'Software Engineering' },
  ];


  return (
    <>
    <Helmet>
    <title>Learnlytica Blog - Insights, Articles, and Updates</title>
    <meta name="description" content="Explore the Learnlytica blog for insights, articles, and updates on personalized learning, educational technology, skills development, and more."/>
    <meta name="keywords" content="Learnlytica blog, educational technology, personalized learning, skills development, educational articles, learning resources"/>
    
    </Helmet>
    <div className=" mt-5 mb-5">
      {

        loader ?
          <SkeletonLoader skeletonCount={10.5} />
          :
          <div className='layout'>
            <div className='container'>
              <AnimationWrapper animationType="heading">
                <div style={categorttitile}>Our Blog</div></AnimationWrapper>
              <AnimationWrapper animationType="heading"><div className='blog-header mt-2'>
                Resources and Insights
              </div></AnimationWrapper>
              <AnimationWrapper animationType="subHeading">
                <div className='blog-subheader mt-2 mb-3'>The latest industry news, interviews, technologies, and resources.</div></AnimationWrapper>

              <div className="mb-5" style={{ position: 'relative' }}>
                {IMG_BLOG_HEADER}

                <div className="blog-text-container p-5" style={{ position: 'absolute', bottom: 0, left: 0, padding: '10px', color: 'white', maxWidth: '100%' }}>
                  <AnimationWrapper animationType="heading"> <div className='blog-header mt-2' style={{ fontSize: '24px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', color: "#fff" }}>
                    Unleashing Wisdom: Explore Our Blog Wonderland
                  </div></AnimationWrapper>
                  <AnimationWrapper animationType="subHeading"><div className='blog-subheader mb-3' style={{ fontSize: '16px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', color: "#fff" }}>
                    Dive into a Tapestry of Ideas, Stories, and Knowledge – Where Inspiration Meets Insight
                  </div></AnimationWrapper>
                  {/* <div className='publish mt-4' style={{ fontSize: '20px', fontFamily:"Inter" }}>
          Published On
        </div>
        <div className='publish mt-3' style={{ fontSize: '16px',fontFamily:"Inter"  }}>
          22 January 2024
        </div> */}
                </div>
              </div>






              {/* <div className="post-list mt-1">
         {posts.map((post) => (
           
           <Link to={`/blog/${post.sys.id}`} key={post.sys.id}>
             <PostCard post={post} />
           </Link>
         ))}
       </div> */}

              <div className='container'>
                <div className='row' style={{ justifyContent: "space-between" }}>
                  <div className='col-md-8 col-lg-8 col-sm-12'>

                  </div>
                  <div className='col-md-4 col-lg-4 col-sm-12'>
                    <div class="form-group ">
                      {/* <label className='label-class pb-1'>Filter by SubCategories</label> */}

                      <div class="d-flex ">
                        <div class="input-group mt-4 ">
                          <input
                            type="text"
                            className='form-control applycoupn'
                            placeholder="Search Blogs"
                            onChange={handleInputChange}

                          />
                          <button class=" applycoupn-btn" type="button" id="button-addon1" data-mdb-ripple-color="dark">
                            {ICN_SEARCH}
                          </button>
                        </div>
                      </div>

                    </div>
                  </div>

                </div>

              </div>

              {
                filterpost.length === 0 ? (
                  <div className='text-center mt-5'>
                    <h4>No results found.</h4>
                  </div>
                ) : (
                  <div className="row mt-5 mb-5 gx-5">
                    {filterpost.map((post, index) => {
                      const category = post?.fields?.category !== undefined ? post?.fields?.category : 'GenAI' ;
                      return (
                        <div key={post.sys.id} className="col-sm-12 col-md-4 col-lg-4  mb-3">
                          <Link to={`/blog/${post.sys.id}/${category}`} >
                            <PostCard post={post} />
                          </Link>
                        </div>
                      )
                    })

                    }

                  </div>
                )
              }





              <div><Outlet /></div>

            </div>

          </div>
      }
      <div className='container'>
        <div style={stackContainerStyle}>
          <Stack spacing={3} direction="row" alignItems="center" justifyContent="space-between" width="100%">
            <button className='page_button' style={{ float: "left" }} onClick={() => setCurrentPage(currentPage - 1)} disabled={currentPage <= 1}
            >{PREV_PAGINATION_ICON} Previous</button>

            <Pagination count={pageSize} page={currentPage} onChange={handlePageChange}
              size='large' />
            <button className='page_button' style={{ float: "inline-end" }} onClick={() => setCurrentPage(currentPage + 1)}
              disabled={currentPage === pageSize}>Next {NEXT_PAGINATION_ICON}</button>

          </Stack>


        </div>
      </div>
    </div>
    </>
  );
};

export default Post;
