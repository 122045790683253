import React, { useState } from 'react';
import { ICN_CHROME, ICN_HEADER_LOGO1, ICN_LOGO } from '../../Components/Common/Icon';
import "./Auth.css";
import { Link } from 'react-router-dom';
import RestService from '../../Services/api.service';
import ToastMessage from '../../Store/ToastHook';
import { ToastContainer } from 'react-toastify';
import CryptoJS from 'crypto-js';
import { Navigate } from 'react-router-dom';

const ResetLink = ({isLoggedIn}) => {
    const secretKey = process.env.REACT_APP_SECRET;
    const [email, setEmail] = useState('')
    const [emailError, setEmailError] = useState('');
    const handleEmail = (e) => {
        const value = e.target.value;
        setEmail(value);

        // Validate email
        const isValidEmail = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/.test(value);
        setEmailError(isValidEmail ? '' : 'Invalid email address');
    };

    //forgot password link
    const forgotPassword = () => {
        const emailEncrypt = CryptoJS.AES.encrypt(email, secretKey).toString();

        try {
            // spinner.show();
            const payload = {
                "email": emailEncrypt,
                "userType": "CONSUMER"
            }
            RestService.forgotPassword(payload).then(
                response => {
                    if (response.status === 200) {
                        ToastMessage({ type: "success", message: 'Email Sent' });
                        setEmail('');
                    }
                },
                err => {
                    
                    ToastMessage({ type: "error", message: err.response.data.message });
                }
            )
        } catch (err) {
            ToastMessage({ type: "error", message: 'Try Again' });
            console.error("error occur on forgotPassword()", err)
        }
    }

    //if login and user tries to login again, redirect to my enrolled courses
    if (isLoggedIn) {
        return <Navigate to="/mycourses" replace />;
    }


    return (
        <>
            <ToastContainer />
            <div className="signup-form mt-5">

                <div className="text-center">
                    {/* {ICN_LOGO} */}
                </div>
                <h2 className="pt-4">Enter email</h2>
                <p className="pt-3">Enter your email. You can update your password after you click on the link.</p>

                <div className="form-group">
                    <label>Email</label>
                    <div className="input-group">
                        <input
                            type="text"
                            className="form-control"
                            name="email"
                            placeholder="Enter Email"
                            value={email}
                            onChange={handleEmail}
                        />
                    </div>
                    {emailError && <p className="error-message">{emailError}</p>}
                </div>

                <div className="form-group">
                    <button type="submit" disabled={emailError.length > 0 || email.length === 0} className="btn btn-primary btn-lg" onClick={forgotPassword}>
                        Send
                    </button>
                </div>

            </div>


        </>
    )
}

export default ResetLink