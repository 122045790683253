import React ,{useState,useEffect}from 'react'
import Legal from './Legal'
import { Link } from 'react-router-dom'
import SkeletonLoader from '../../Components/Common/SkeletonLoader'

const Contact = () => {
  const [loader, setLoader] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setLoader(false);
    }, [1000]);
  }, []);
  return (
    <>
    <div className='course-container'>
<div className='row '>
    {/*  filter options */}
<div className='col-lg-3 col-md-3 col-sm-12  mb-5' >
    <Legal/>
    </div>

    <div className='col-lg-9 col-md-9 col-sm-12  mb-5' >
    <div className='course-section'>
    {loader ?
                  <SkeletonLoader skeletonCount={5} />:
        <div className='row container'>
            <div className='col-xl-12 col-lg-12 col-md-12 col-sm-12'>
            <div className='learningpt-header' >Contact</div>
            <div className='requirment pt-5'>Last updated on Jan 2nd 2024</div>
            

            <div className='requirment pt-5'>
            You may contact us using the information below:
               </div>


            <div className='requirment'>
              <ul className='pt-5'>
                <li > Merchant Legal entity name: LEARNLYTICA PRIVATE LIMITED</li>
                <li className='pt-5'>Registered Address: 17/1, 2nd Floor, Ambalipura Sarjapura Road, Bellandur Bengaluru KARNATAKA 560103</li>
                <li className='pt-5'>Operational Address: 17/1, 2nd Floor, Ambalipura Sarjapura Road, Bellandur Bengaluru KARNATAKA 560103</li>
                <li className='pt-5'> Telephone No: +91-9731149585</li>
                <li className='pt-5'>E-Mail ID: support@learnlytica.com</li>
                
              </ul>
            
            
            </div>

            <Link to ="/contactus" className='btn-primary mt-5' style={{padding:"10px"}} >More Details</Link>
            </div>

        </div>
}

    </div>



    
    
    
    </div>

    </div>
</div>
    
    </>
  )
}

export default Contact