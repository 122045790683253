import React, { useState, useEffect } from 'react';
import Comment from './Comment';
import './Comments.css';

function Comments({ blogComments,totalComments,totalLikes }) {
  // Assume blogComments is the array of comments passed as a prop to this component.

  return (
    <div className="comments">
    
     
      {blogComments.map(comment => (
        <Comment
          key={comment?.commentId}
          comment={comment}
          slug={comment?.blogSlug}
        />
      ))}
    </div>
  );
}

export default Comments;