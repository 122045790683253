import React from 'react';
import { Navigate } from 'react-router-dom';

// function PrivateRoute({ component: Component, ...rest }) {
//   return (
//     localStorage.getItem('REACTAPP.TOKEN') ? <Component {...rest} /> :  <Navigate to="/login" replace />
//   )
// }
const PrivateRoute = ({ element }) => {
  return localStorage.getItem('REACTAPP.TOKEN') ? (
    element
  ) : (
    <Navigate to="/login" replace />
  );
};

export default PrivateRoute