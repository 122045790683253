import React from "react";
import { Collapse } from "react-collapse";
import { css } from "@emotion/css";


class DropdownItem extends React.Component {
  render() {
    const { isOpen, toggleDropdown, theme, title, total, children } = this.props;

    return (
      <div
        style={{ backgroundColor: isOpen ? "#fff" : "inherit" }}
        className={theme === "dark" ? accordionContainerDark : accordionContainerLight}
        id={this.props.id}
      >
        <div
          style={{ backgroundColor: isOpen ? "#F5F8FB" : "inherit" }}
          className={theme === "dark" ? headerBarDark : headerBarLight}
          onClick={toggleDropdown}
        >
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div>{title}</div>
            <p className="pt-1 pb-1" style={{ fontSize: "16px", fontWeight: "400", color: "#475467" }}>{total}</p>
          </div>
          <button
            className={theme === "dark" ? openAccordionToggleDark : openAccordionToggleLight}
          >
            {isOpen ? "^" : "˅"}
          </button>
        </div>
        <Collapse isOpened={isOpen}>
          <div className={displayText}>{children}</div>
        </Collapse>
      </div>
    );
  }
}

export default DropdownItem;


//Light Theme
const headerBarLight = css`
  display: flex;
  justify-content: space-between;
  border-bottom: 2px  #FAFAFA;
  // margin-bottom: 12px;
  padding: 5px;
  font-size: 20px;
`;

const openAccordionToggleLight = css`
  background-color: transparent;
  border: none;
  font-size: 18px !important;
  font-family: 'Inter' !important;
  cursor: pointer;
  color: #272D3B;
    font-weight: 500
`;

const displayText = css`
  text-align: left;
`;

const accordionContainerLight = css`
  margin: 10px;
  color: black;
`;
const color = 'darkgreen'
//Dark Theme
const headerBarDark = css`

  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #EAECF0;
  cursor:pointer;
  // margin-bottom: 10px;
 background:#fff;
  padding: 16px 32px;
  font-size: 16px !important;
  font-family: 'Inter';
  color: #272D3B;
    font-weight: bold;
    &:hover{
      background-color: #ececec;
border-radius:1px
    }`;

const accordionContainerDark = css`
  // margin: 10px;
  // color: #272D3B;
  // font-weight: bold
  // font-size: 12px !important;
  // font-family: 'Inter';

  justify-content: space-between;
// border-bottom: 0.1px ridge #EAECF0;
cursor: pointer;
// margin-bottom: 10px;
// padding: 5px;
font-size: 16px!important;
font-family: 'Inter';
color: #101828;
font-weight: 500;
`;

const openAccordionToggleDark = css`
display: -webkit-box;
display: -webkit-flex;
display: -ms-flexbox;
display: flex;
-webkit-box-pack: justify;
-webkit-justify-content: space-between;
justify-content: space-between;
// border-bottom: 0.1px ridge #EAECF0;
cursor: pointer;
margin-bottom: 10px;
padding: 5px;
font-size: 20px!important;
font-family: 'Inter';
color: #101828;
font-weight: 900;
`;
