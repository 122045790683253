import React ,{useState}from 'react';
import { ARROW_UP_RIGHT } from '../../Components/Common/Icon';
import AnimationWrapper from '../../Components/Common/Animation Ref/AnimationWrapper'
import { motion } from 'framer-motion';

const imageVariants = {
  hidden: { opacity: 0, scale: 0.8 },
  visible: { opacity: 1, scale: 1 }
};


const PostCard = ({ post }) => {

  const { title, excerpt, coverImage, author ,date} = post.fields;
  const cardStyle = {
    display: 'flex',
    flexDirection: 'column',
    height: '100%', // Set the height to 100% of the container
    border:"none",
   
 
   
  };

  const coverImageStyle = {
    flex: '1', // Make the image take up the available space
    objectFit: 'cover',
    borderRadius: '16px ', // Adjust the border radius as needed
    maxHeight:"250px"
  };

  const contentStyle = {
    flex: '1', // Make the content take up the available space
    padding: '10px',
    display: 'flex',
    flexDirection: 'column',
  };

  // const titleStyle = {
  //   fontSize: '18px',
  //   fontWeight: 'bold',
  //   margin: '0',
  // };

  const excerptStyle = {
    color: "#475467",
    margin:"10px 0px",
    /* Text md/Regular */
    fontFamily: "Inter",
    fontSize: "16px",
   
    fontWeight: "400",
    lineHeight: "24px" ,/* 150% */
    textAlign:"Justify"
  };
  const categorttitile={
    color: "#6941C6",

/* Text sm/Semibold */
fontFamily: "Inter",
fontSize: "14px",

fontWeight: "600",
lineHeight: "20px" /* 142.857% */
  }

  // const authorStyle = {
  //   marginTop: 'auto', // Push the author info to the bottom
  //   display: 'flex',
  //   alignItems: 'center',
  // };

  const authorImageStyle = {
    width: '30px',
    height: '30px',
    borderRadius: '50%',
    marginRight: '10px',
  };

  

  return (

    
   <>
   
 <div className="card h-100" style={cardStyle}>
      <img src={coverImage.fields?.file.url} alt={coverImage.fields.description} style={coverImageStyle} />
  
      <div className="card-body" style={contentStyle}>
      <AnimationWrapper animationType="heading"> 
         <div style={categorttitile}>{new Date(date).toLocaleDateString()}</div></AnimationWrapper>




       
       <div className='d-flex' style={{justifyContent:"space-between" , alignItems:"center"}}>
       <h4 className="card-title mt-4">
          {title}
        </h4>
        <div style={{float:"inline-end"}}>{ARROW_UP_RIGHT}</div>
        </div>
        <AnimationWrapper animationType="subHeading"> 
        <p className="card-subtitle pb-5" style={excerptStyle}>
          {excerpt}
        </p></AnimationWrapper>
        <ul className="list-group pmd-list" style={{ border: 'none', marginTop: 'auto' }}>
          <li className="list-group-item d-flex flex-row" style={{ border: 'none' }}>
            
            <motion.img variants={imageVariants}
initial="hidden"
animate="visible"
transition={{ duration: 1 }}
              alt={author.fields.picture.fields?.description}
              className="post-card-author-image"
              style={authorImageStyle}
              src={author.fields.picture.fields?.file.url}
            />
            <h3 className="pmd-list-title">{author.fields.name}</h3>
          </li>
        </ul>
      </div>
    </div>
   </>
  );
};
export default PostCard;
