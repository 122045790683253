import React from 'react';

// Component to display video or image based on the file extension in the URL
const MediaDisplay = ({ mediaUrl, altText }) => {
  // Determine if the URL is for a video based on the file extension
  const isVideo = /\.(mp4|webm|ogg)$/i.test(mediaUrl);

  return (
    < >
      {isVideo ? (
        <video width="100%" height="245" autoPlay muted  controls controlsList="nodownload" style={{ borderTopLeftRadius: "12px", borderTopRightRadius: "12px" }}>
          <source src={mediaUrl} type={`video/${mediaUrl.split('.').pop()}`} />
          Your browser does not support the video tag.
        </video>
      ) : (
        <img src={mediaUrl} alt={altText} className='img-fluid' style={{ borderTopLeftRadius: "12px", borderTopRightRadius: "12px" }}/>
      )}
    </>
  );
};

export default MediaDisplay;
