import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
// import RestService from '../../Services/api.service'
import Readytoexplore from "../../../Assets/Images/readytoexplore.png"


import { ToastContainer } from 'react-toastify'
import SkeletonLoader from '../SkeletonLoader'
import ToastMessage from '../../../Store/ToastHook'
import RestService from '../../../Services/api.service'
import { Helmet } from 'react-helmet-async';
import { Spinner } from 'react-bootstrap';
const Partnerwithus = () => {
  const [loader, setLoader] = useState(true)
  const [loading, setLoading] = useState(false);
  const [companyName, setCompanyName] = useState('');
  const [companyEmail, setCompanyEmail] = useState('');
  const [contactName, setContactName] = useState('');
  const [website, setWebsite] = useState('');
  const [addInfo, setAddInfo] = useState('');
  const [privacy, setPrivacy] = useState(false);

  const [emailError, setEmailError] = useState('');
  const [contactNameError, setContactNameError] = useState('');
  const [companyNameError, setCompanyNameError] = useState('');
  const [isValidWebsite, setIsValidWebsite] = useState(true);

  const handleEmailChange = (e) => {
    const value = e.target.value;
    setCompanyEmail(value);

    // Validate email
    const isValidEmail = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/.test(value);
    setEmailError(isValidEmail ? '' : 'Invalid email address');
  };

  const handlePrimaryContactNameChange = (e) => {
    const value = e.target.value;
    setContactName(value);

    // Validate contact name (optional, adjust as needed)
    const isValidContactName = /^[A-Za-z\s]+$/.test(value);
    setContactNameError(isValidContactName ? '' : 'Only letters and spaces are allowed');

  };

  const handleCompanyNameChange = (e) => {
    const value = e.target.value;
    setCompanyName(value);

    // Validate company name (optional, adjust as needed)
    const isValidCompanyName = /^[A-Za-z\s]+$/.test(value);
    setCompanyNameError(isValidCompanyName ? '' : 'Only letters and spaces are allowed');
  };

  const handleWebsiteChange = (e) => {
    const url = e.target.value;
    setWebsite(url);

    // Simple URL validation using a regular expression
    const urlPattern = /^https:\/\/[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}(\/)?$/;
    
    setIsValidWebsite(url === '' || urlPattern.test(url));
  };

  const partnerWithUs = () => {
    setLoading(true);
    try {
      let payload =
      {
        "companyEmail": companyEmail,
        "companyName": companyName,
        "primaryContactName": contactName
      }

      if (website.length !== 0) {
        payload.companyWebsite = website;
      }

      if (addInfo.length !== 0) {
        payload.additionalInformation = addInfo
      }

      RestService.partnerWithUs(payload).then(res => {

        if (res.status === 200) {
          ToastMessage({ type: "success", message: res.data });
          setCompanyEmail('');
          setCompanyName('');
          setContactName('');
          setWebsite('');
          setAddInfo('');
          setPrivacy(false);
          setLoading(false);
        }

      }, err => {
        setLoading(false);
        ToastMessage({ type: "error", message: `Try Again` });
      }
      );
    }
    catch (err) {
      setLoading(false);
      console.error()
      // Toast.error({ message: Something wrong!! });
    }
  }

  useEffect(() => {
    setTimeout(() => {
      setLoader(false);
    }, [1000]);
  }, []);

  return (
    <>
    <Helmet>
    <title>Partner with Learnlytica - Collaborate for Educational Excellence</title>
    <meta name="description" content="Partner with Learnlytica and collaborate for educational excellence. Explore partnership opportunities in content development, technology integration, institutional collaborations, and more."/>
    <meta name="keywords" content="Partner with Learnlytica, educational partnerships, content development partnerships, technology integration, institutional collaborations"/>
    
    </Helmet>
      <ToastContainer />
      <div className='container'>
        <div className='row'>

          <div className='col-md-6 col-lg-6 col-xl-6 col-sm-12 mt-5'>
            {loader ?
              <SkeletonLoader skeletonCount={5} />
              :
              <h2 className='contactusheader mt-5'>

                Partner with Us</h2>
            }
            {loader ?
              <SkeletonLoader skeletonCount={5} />
              :
              <p className='pt-3 contactussubheader'>Uplift Your Sales and Performance with Our Synergistic Partnership
                :</p>
            }

            {loader ?
              <SkeletonLoader skeletonCount={5} />
              :
              <div className='sectionpoint pt-5 mb-3'>
                <ul>
                  <li> Collaborative Growth: Join a network of partners who are committed to mutual success and collaborative growth.
                  </li>
                  <li> Support at Every Step: Receive ongoing support from our team, ensuring a smooth and productive partnership experience.
                  </li>
                  <li> Easy Partnership Process: Discover how simple it is to partner with us through our straightforward onboarding process.
                  </li>

                </ul>
              </div>
            }
          </div>


          <div className='col-md-6 col-lg-6 col-xl-6 col-sm-12'>
            {
              loader ?
                <SkeletonLoader skeletonCount={5.6} />
                :
                <div class="signup-form">

                  {/* <div className='text-center'>
      {ICN_LOGO}
  </div> */}

                  {/* <hr/> */}


                  <div class="form-group">
                    <label>Company Name<sup style={{ fontSize: "14px" }}>*</sup></label>
                    <div class="input-group">
                      {/* <div class="input-group-prepend">
    <span class="input-group-text">
      <i class="fa fa-lock"></i>
    </span>                    
  </div> */}

                      <input type="text" onChange={handleCompanyNameChange} value={companyName} class="form-control" name="companyName" placeholder="Company Name" />
                    </div>
                    {companyNameError && <div className="error-message text-danger">{companyNameError}</div>}
                  </div>

                  <div class="form-group">
                    <label>Company Email<sup style={{ fontSize: "14px" }}>*</sup></label>
                    <div class="input-group">
                      {/* <div class="input-group-prepend">
    <span class="input-group-text">
      <i class="fa fa-paper-plane"></i>
    </span>                    
  </div> */}
                      <input type="email" onChange={handleEmailChange} class="form-control" name="email" value={companyEmail} placeholder="Email Address" />

                    </div>
                    {emailError && <div className="error-message text-danger">{emailError}</div>}
                  </div>
                  <div class="form-group">
                    <label>Primary Contact Name<sup style={{ fontSize: "14px" }}>*</sup></label>
                    <div class="input-group">
                      {/* <div class="input-group-prepend">
    <span class="input-group-text">
      <i class="fa fa-lock"></i>
    </span>                    
  </div> */}

                      <input type="text" onChange={handlePrimaryContactNameChange} value={contactName} class="form-control" name="contactName" placeholder="Enter Primary Contact Name" />

                    </div>
                    {contactNameError && <div className="error-message text-danger">{contactNameError}</div>}
                  </div>

                  <div class="form-group">
                    <label>Company Website</label>
                    <div class="input-group">
                      {/* <div class="input-group-prepend">
    <span class="input-group-text">
      <i class="fa fa-lock"></i>
    </span>                    
  </div> */}
                      <input type="url" onChange={handleWebsiteChange}
                        value={website} className={`form-control ${isValidWebsite ? '' : 'is-invalid'}`} name="website" placeholder="Company Website" />
                      {website !== '' && !isValidWebsite && (
                        <div className="invalid-feedback text-danger">Please enter a valid HTTPS URL.</div>
                      )}
                    </div>
                  </div>


                  <div class="form-group">
                    <label>Additional Information</label>
                    <div class="input-group">
                      {/* <div class="input-group-prepend">
    <span class="input-group-text">
      <i class="fa fa-lock"></i>
    </span>                    
  </div> */}

                      <textarea type="text" rows="10" class="form-control" name="addInfo" value={addInfo} onChange={(e) => setAddInfo(e.target.value)} placeholder="Additional Information" />

                    </div>
                  </div>


                  <div class="form-group">
                    <label class="form-check-label"><input type="checkbox" checked={privacy} onClick={(e) => setPrivacy(e.target.checked)} />

                     <span className='mx-2'>You agree to our friendly</span>  <Link to="/legal/privacy">privacy policy.</Link>


                    </label>
                  </div>





                  <div class="form-group">
                    <button type="submit"

                      disabled={
                        !privacy ||
                        companyName.length === 0 || companyEmail.length === 0 ||
                        contactName.length === 0 ||
                        emailError.length > 0
                        || contactNameError.length > 0 || companyNameError.length > 0
                      || isValidWebsite === false}
                      onClick={partnerWithUs}
                      class="btn btn-primary btn-lg"
                    >
                   { loading ? (
                      <>
                          <Spinner
                              as="span"
                              animation="border"
                              size="md"
                              role="status"
                              aria-hidden="true"
                              className="mr-2" // Add margin to separate spinner from text
                              style={{ border: '5px dotted #fff' }}
                          />
                          {' '}
                          Loading...
                      </>
                  ) :
                    "Submit Partnership Enquiry"}</button>
                  </div>








                </div>
            }
          </div>

        </div>
      </div>

    </>
  )
}

export default Partnerwithus