import React, { useState, useEffect, useContext } from 'react';
import { ICN_LOGO, ICN_HEADER_EXPAND, ICN_LANGUAGE, ICN_NEXT, ICN_RESOURCES, ICN_TOTAL_LECTURE, ICN_TOTAL_TIME, ICN_VIDEO, IMG_CARD, IMG_COMMENTS, IMG_FEATURE, ICN_LAB, ICN_PDF, IMG_PADLOCK, ICN_STRAT_SESSION, LAB, ASSESSMENT_ICON, CALENDAR_ICON, ICON_SESSION, ICN_MCQ, MCQASS_ICN, VIDEO_ICN1, CUSTOMIZE_LAB_ICN, CUSTOMIZE_LIVE_SESSION_ICN, CUSTOMIZE_MCQ_ASSE_ICN, CUSTOMIZE_CODING_ASS_ICN, CUSTOMIZE_DOCUMENT_ICN, CUSTOMIZE_VIDEO_ICN, ICN_EXPAND_ACCORDIAN, ICN_COLLAPSED } from '../../Components/Common/Icon';
import { Link, useLocation } from 'react-router-dom';
import ReactPlayer from 'react-player';
import RestService from '../../Services/api.service';
import parse from 'html-react-parser';
import DropdownItem from '../../Components/Common/DropdownItem';
import AllModal from '../../Components/Common/AllModal';
import CartContext from '../../Store/CartContext';
import SignupForCart from '../Auth/SignupForCart';
import { BsModal } from '../../Components/Common/Utills/BsModal';
import SkeletonLoader from '../../Components/Common/SkeletonLoader';
import { ToastContainer } from 'react-toastify';
import ToastMessage from '../../Store/ToastHook';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import AnimationWrapper from '../../Components/Common/Animation Ref/AnimationWrapper';
import MediaDisplay from '../../Components/Common/MediaDisplay';
import ReactGA from "react-ga4";
import { Helmet } from 'react-helmet-async';
import { Tooltip } from "react-tooltip";
import InfoIcon from '@mui/icons-material/Info';
import { Spinner } from 'react-bootstrap';

const SpecificCourse = (props) => {
  let { courseid } = useParams();

  let { state } = useLocation();

  const [courseDetails, setCourseDetails] = useState({});
  const [contentType, setContentType] = useState({});
  const [languages, setLanguages] = useState([]);
  const [contentDetails, setContentDetails] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadingTop5, setLoadingTop5] = useState(true);
  const [show, setShow] = useState(false);
  const [showSignUp, setShowSignUp] = useState(false);
  const [customizeCourse, setCustomizeCourse] = useState(false);
  const [showCart, setShowCart] = useState(false);
  const [type, setType] = useState('');
  const [contentTitle, setContentTitle] = useState('');
  const [contentLink, setContentLink] = useState('');
  const { currency, cartResponse, setCartResponse,
    setCouponCode, setDiscount, setOriginalCartPrice, setTotalPrice } = useContext(CartContext);
  const [top5Courses, setTop5Courses] = useState([]);
  const [subCategoryName, setSubCategoryName] = useState('');
  const [top5Route, setTop5Route] = useState(false);
  const [currentDate, setCurrentDate] = useState('');
  const [isCollapsed, setIsCollapsed] = useState(false);

  const [liveSessions, setLiveSessions] = useState(true);
  const [labs, setLabs] = useState(true);
  const [mcq, setMcq] = useState(true);
  const [coding, setCoding] = useState(true);
  const [document, setDocument] = useState(true);
  const [videos, setVideos] = useState(true);

  const [modulesSelected, setModulesSelected] = useState(6);
  const [selectCurateAny, setSelectCurateAny] = useState(false);
  const [notification, setNotification] = useState(false);
  const [selectReset, setSelectReset] = useState(true);

  const [curationVisibleDetailsMeeting, setCurationVisibleDetailsMeeting] = useState({});
  const [curationVisibleDetailsLabs, setCurationVisibleDetailsLabs] = useState({});
  const [curationVisibleDetailsMcq, setCurationVisibleDetailsMcq] = useState({});
  const [curationVisibleDetailsCoding, setCurationVisibleDetailsCoding] = useState({});
  const [curationVisibleDetailsDocument, setCurationVisibleDetailsDocument] = useState({});
  const [curationVisibleDetailsVideos, setCurationVisibleDetailsVideos] = useState({});

  const [openStates, setOpenStates] = useState([]); // Initially, all are open

  // State to track whether all items are shown or hidden
  const [areAllOpen, setAreAllOpen] = useState(true);

  // Toggle a specific dropdown
  const toggleDropdown = (index) => {
    const newOpenStates = [...openStates];
    newOpenStates[index] = !newOpenStates[index];
    setOpenStates(newOpenStates);

    // Check if all or none are open after toggling
    const allOpen = newOpenStates.every(state => state);
    setAreAllOpen(allOpen);
  };

  // Toggle all dropdowns based on the current state
  const toggleAll = () => {
    if (areAllOpen) {
      // If all are open, close them
      setOpenStates(contentDetails.map(() => false));
      setAreAllOpen(false);
    } else {
      // If not all are open, open them
      setOpenStates(contentDetails.map(() => true));
      setAreAllOpen(true);
    }
  };

  const [notify, setNotify] = useState(false);

  const NotificationModal = () => {

    const [emailError, setEmailError] = useState('');
    const [nameError, setNameError] = useState('');

    const [phoneError, setPhoneError] = useState('');

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');

    const [phone, setPhone] = useState('');
    const [notify, setNotify] = useState(false);



    const handleChangeName = (event) => {
      const newValue = event.target.value;
      if (/^[A-Za-z\s]*$/.test(newValue)) {
        setName(newValue);

        // Check if the length is less than 5 and the string is not empty
        if (newValue.length === 0) {
          setNameError('Please Enter your full Name');
        } else {
          setNameError(''); // Clear error if the input is valid
        }
      } else {
        setNameError('Name should only contain alphabets and spaces.');
      }
    };


    const handleEmailChange = (e) => {
      const value = e.target.value;
      setEmail(value);

      // Validate email
      const isValidEmail = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/.test(value);
      setEmailError(isValidEmail ? '' : 'Invalid email address');
    };



    const handlePhoneChange = (e) => {
      const value = e.target.value;
      setPhone(value);

      // Check if the value is exactly 10 digits long and consists only of numbers
      const isValidLength = value.length === 10;
      const containsOnlyNumbers = /^\d+$/.test(value);

      // Update the error message based on the validation
      if (!containsOnlyNumbers) {
        setPhoneError('Phone number must contain only digits');
      } else if (!isValidLength) {
        setPhoneError('Phone number must be exactly 10 digits');
      } else {
        setPhoneError(''); // No error
      }

      // // Validate phone number
      // const isValidPhoneNumber = /^[1-9][0-9]{0,9}$/.test(value);
      // setPhoneError(
      //     isValidPhoneNumber ? '' : 'Phone number must be 10 digits'
      // );
    };

    //notify for nomination closed
    const notifyMe = () => {
      setNotify(true);

      try {


        const payload = {
          "email": email,
          "name": name,
          "phoneNumber": phone
        }


        RestService.notifyMe(payload).then(
          response => {
            if (response.status === 200) {
              setNotify(false);
              setNotification(false);
              ToastMessage({ type: "success", message: `${response.data}`, time: 2000 });
            }
          },
          err => {
            console.error(err);
            setNotify(false);
            ToastMessage({ type: "warning", message: `${err.response.data.message}`, time: 2000 });
          }
        )
      } catch (err) {
        console.error("error occur on notifyMe()", err)
      }

    }

    return (
      <>
        <div class="signup-form">

          <div className='text-center d-none d-lg-block '>
            {ICN_LOGO}
          </div>


          <div className='container'>
            <div class="form-group pt-5">
              <label> Name<sup style={{ fontSize: "13px" }}>*</sup></label>
              <div class="input-group">
                {/* <div class="input-group-prepend">
<span class="input-group-text">
  <span class="fa fa-user"></span>
</span>                    
</div> */}
                <input type="text" onChange={handleChangeName} value={name} class="form-control" name="username" placeholder="Enter your Name" />

              </div>
              {nameError && <div className="error-message text-danger">{nameError}</div>}
            </div>



            <div class="form-group">
              <label>Email<sup style={{ fontSize: "13px" }}>*</sup></label>
              <div class="input-group">
                {/* <div class="input-group-prepend">
<span class="input-group-text">
  <i class="fa fa-paper-plane"></i>
</span>                    
</div> */}
                <input type="email" onChange={handleEmailChange} value={email} class="form-control" name="email" placeholder="Email Address" />

              </div>
              {emailError && <div className="error-message text-danger">{emailError}</div>}

            </div>
            <div class="form-group ">
              <label>Phone Number<sup style={{ fontSize: "13px" }}>*</sup></label>
              <div class="input-group">
                {/* <div class="input-group-prepend">
<span class="input-group-text">
<span class="fa fa-user"></span>
</span>                    
</div> */}
                <input type="text" onChange={handlePhoneChange} value={phone} class="form-control" name="username" placeholder="Enter your Number" />

              </div>
              {phoneError && <div className="error-message text-danger">{phoneError}</div>}

            </div>


            <div class="form-group">
              <button type="submit" class="btn btn-primary btn-lg" onClick={() => notifyMe()}>
                {notify ? (
                  <>
                    <Spinner
                      as="span"
                      animation="border"
                      size="md"
                      role="status"
                      aria-hidden="true"
                      className="mr-2" // Add margin to separate spinner from text
                      style={{ border: '5px dotted #fff' }}
                    />
                    {' '}
                    Loading...
                  </>
                ) : 'Submit'}</button>
            </div>

          </div>



        </div>

      </>
    )
  }


  const toggleCollapse = () => {
    setIsCollapsed(!isCollapsed);

  };

  let slNoCounter = 0;

  let user = localStorage.getItem('user')
    ? JSON.parse(localStorage.getItem('user'))
    : '';

  const showUnLockedContent = (type, link, title) => {
    setType(type);
    setContentLink(link);
    setShow(true);
    setContentTitle(title)
  }

  const resetCustomizeButton = () => {
    setLiveSessions(true);
    setLabs(true);
    setMcq(true);
    setCoding(true);
    setDocument(true);
    setVideos(true);
  }

  const includeSlNo = (courseContent) => {

    courseContent.forEach((section) => {
      section.courseContentResposeTOList.forEach((content) => {
        content.slNo = ++slNoCounter;
      });
    });
    setContentDetails(courseContent);

  }

  const handleAddToCart = () => {
    ReactGA.event({
      category: "On Specific Course",
      action: "Clicked on Add to cart",
      label: "clicked on specific course", // optional
      value: courseid, // optional, must be a number
      nonInteraction: true, // optional, true/false
      transport: "xhr", // optional, beacon/xhr/image
    });
    if ('REACTAPP.TOKEN' in localStorage) {
      addToCart();
    }
    else {
      setShowSignUp(true);
    }
  }

  //get courses details
  const getConsumerCourseDetails = () => {
    try {
      // const courseSid = state?.some?.courseSid ? state.some.courseSid : localStorage.getItem('courseSid');
      // const courseSid = state?.some?.courseSid;

      RestService.getConsumerCourseDetails(courseid).then(
        response => {
          if (response.status === 200) {
            // Generate sl.no for each content across all sections
            let currentSlNo = 1;
            const curationDetails = response.data.curationDetails !== null ? JSON.parse(response.data.curationDetails) : null;
            const updatedArray = response.data.contentDetails.courseSectionResponseTO.map(section => {
              return {
                ...section,
                courseContentResposeTOList: section.courseContentResposeTOList.map(content => ({
                  ...content,
                  slNo: currentSlNo++
                }))
              };
            });

            setTimeout(() => {
              setCourseDetails(response.data);
              setContentType(response.data.contentType);
              setLanguages(response.data.languages);
              setContentDetails(updatedArray);
              setOpenStates(updatedArray.map(() => true));
              setSubCategoryName(response.data.subCategoryName);
              setLoading(false);
              setTop5Route(false);
              response.data.curationVisibleDetails.map(visible => {
                if (visible.contentName === 'DOCUMENTS') {
                  setCurationVisibleDetailsDocument(visible);
                  setDocument(visible.visible ? curationDetails !== null ? !curationDetails.documentDeselected : true : false);
                }
                if (visible.contentName === 'VIDEOS') {
                  setCurationVisibleDetailsVideos(visible);
                  setVideos(visible.visible ? curationDetails !== null ? !curationDetails.videoDeselected : true : false);
                }
                if (visible.contentName === 'MEETING') {
                  setCurationVisibleDetailsMeeting(visible);
                  setLiveSessions(
                    visible.visible ?

                      curationDetails !== null ? !curationDetails.meetingDeselected : true : false);
                }
                if (visible.contentName === 'LABS') {
                  setCurationVisibleDetailsLabs(visible);
                  setLabs(visible.visible ? curationDetails !== null ? !curationDetails.labDeselected : true : false);
                }
                if (visible.contentName === 'MCQ') {
                  setCurationVisibleDetailsMcq(visible);
                  setMcq(visible.visible ? curationDetails !== null ? !curationDetails.mcqDeselected : true : false);
                }
                if (visible.contentName === 'CODING') {
                  setCurationVisibleDetailsCoding(visible);
                  setCoding(visible.visible ? curationDetails !== null ? !curationDetails.codingDeselected : true : false);
                }
                // setLiveSessions(curationDetails !== null ? !curationDetails.meetingDeselected : true);
                // setLabs(curationDetails !== null ? !curationDetails.labDeselected : true);
                // setMcq(curationDetails !== null ? !curationDetails.mcqDeselected : true);
                // setCoding(curationDetails !== null ? !curationDetails.codingDeselected : true);
                // setDocument(curationDetails !== null ? !curationDetails.documentDeselected : true);
                // setVideos(curationDetails !== null ? !curationDetails.videoDeselected : true);

              })
            }, 2000);


          }
        },
        err => {
          console.error(err);
        }
      )
    } catch (err) {
      console.error("error occur on getConsumerCourseDetails()", err)
    }
  }

  //get course details for user
  const getConsumerCourseDetailsUser = () => {
    try {
      const consumerSid = user?.sid;
      // const courseSid = state?.some?.courseSid;
      // const courseSid = state?.some?.courseSid ? state.some.courseSid : localStorage.getItem('courseSid');
      RestService.getConsumerCourseDetailsUser(consumerSid, courseid).then(
        response => {
          if (response.status === 200) {
            const curationDetails = response.data.curationDetails !== null ? JSON.parse(response.data.curationDetails) : null;
            // Generate sl.no for each content across all sections
            let currentSlNo = 1;

            const updatedArray = response.data.contentDetails.courseSectionResponseTO.map(section => {
              return {
                ...section,
                courseContentResposeTOList: section.courseContentResposeTOList.map(content => ({
                  ...content,
                  slNo: currentSlNo++
                }))
              };
            });

            setTimeout(() => {
              setCourseDetails(response.data);
              setContentType(response.data.contentType);
              setLanguages(response.data.languages);
              setContentDetails(updatedArray);
              setOpenStates(updatedArray.map(() => true));
              setSubCategoryName(response.data.subCategoryName);
              setLoading(false);
              setTop5Route(false);
              response.data.curationVisibleDetails.map(visible => {
                if (visible.contentName === 'DOCUMENTS') {
                  setCurationVisibleDetailsDocument(visible);
                  setDocument(visible.visible ? curationDetails !== null ? !curationDetails.documentDeselected : true : false);
                }
                if (visible.contentName === 'VIDEOS') {
                  setCurationVisibleDetailsVideos(visible);
                  setVideos(visible.visible ? curationDetails !== null ? !curationDetails.videoDeselected : true : false);
                }
                if (visible.contentName === 'MEETING') {
                  setCurationVisibleDetailsMeeting(visible);
                  setLiveSessions(
                    visible.visible ?

                      curationDetails !== null ? !curationDetails.meetingDeselected : true : false);
                }
                if (visible.contentName === 'LABS') {
                  setCurationVisibleDetailsLabs(visible);
                  setLabs(visible.visible ? curationDetails !== null ? !curationDetails.labDeselected : true : false);
                }
                if (visible.contentName === 'MCQ') {
                  setCurationVisibleDetailsMcq(visible);
                  setMcq(visible.visible ? curationDetails !== null ? !curationDetails.mcqDeselected : true : false);
                }
                if (visible.contentName === 'CODING') {
                  setCurationVisibleDetailsCoding(visible);
                  setCoding(visible.visible ? curationDetails !== null ? !curationDetails.codingDeselected : true : false);
                }
                // setLiveSessions(curationDetails !== null ? !curationDetails.meetingDeselected : true);
                // setLabs(curationDetails !== null ? !curationDetails.labDeselected : true);
                // setMcq(curationDetails !== null ? !curationDetails.mcqDeselected : true);
                // setCoding(curationDetails !== null ? !curationDetails.codingDeselected : true);
                // setDocument(curationDetails !== null ? !curationDetails.documentDeselected : true);
                // setVideos(curationDetails !== null ? !curationDetails.videoDeselected : true);

              })
            }, 2000);


          }
        },
        err => {
          console.error(err);
        }
      )
    } catch (err) {
      console.error("error occur on getConsumerCourseDetailsUser()", err)
    }

  }


  //display cart
  const displayCart = () => {
    try {
      // spinner.show();
      RestService.displayCart().then(
        response => {
          if (response.status === 200) {
            setCartResponse(response.data);
            setCouponCode(response.data.couponCode);
            setDiscount(response.data.discount);
            setOriginalCartPrice(response.data.originalCartPrice);


          }
        },
        err => {
          console.error(err);
        }
      )
    } catch (err) {
      console.error("error occur on displayCart()", err)
    }
  }

  //get top 5 courses
  const getTop5CoursesForSpecificCourses = () => {
    try {
      const courseSid = courseid;
      RestService.getTop5CoursesForSpecificCourses(courseSid).then(
        response => {
          if (response.status === 200) {
            setTimeout(() => {
              setTop5Courses(response.data);
              window.scrollTo({ top: 0, behavior: 'smooth' });
              setLoadingTop5(false);
            }, 2000);

          }

        },
        err => {
          console.error(err);
        }
      )
    } catch (err) {
      console.error("error occur on getTop5CoursesForSpecificCourses()", err)
    }
  }

  //get top 5 courses for user
  const getTop5CoursesForLoginUserForSpecificCourses = () => {
    try {
      const courseSid = courseid;
      const consumerSid = user?.sid;
      RestService.getTop5CoursesForLoginUserForSpecificCourses(courseSid, consumerSid).then(
        response => {
          if (response.status === 200) {
            setTimeout(() => {
              setTop5Courses(response.data);
              window.scrollTo({ top: 0, behavior: 'smooth' });
              setLoadingTop5(false);
            }, 2000);
          }

        },
        err => {
          console.error(err);
        }
      )
    } catch (err) {
      console.error("error occur on getTop5CoursesForLoginUserForSpecificCourses()", err)
    }
  }

  //add to cart
  const addToCart = () => {
    try {
      const courseSid = courseDetails.courseSid
      RestService.addToCart(courseSid, currency).then(
        response => {
          if (response.status === 200) {
            setCartResponse(response.data);
            ToastMessage({ type: "success", message: 'Course added to cart' });
            // window.location.reload(); 

          }
        },
        err => {
          console.error(err);
        }
      )
    } catch (err) {
      console.error("error occur on addToCart()", err)
    }
  }

  //customize course 
  const curateCourse = () => {
    setLoading(true);
    try {
      const courseSid = courseDetails.courseSid;
      const consumerSid = user?.sid;
      const payload = {
        "codingDeselected": !coding,
        "documentDeselected": !document,
        "labDeselected": !labs,
        "mcqDeselected": !mcq,
        "meetingDeselected": !liveSessions,
        "videoDeselected": !videos
      }
      RestService.curateCourse(courseSid, consumerSid, payload).then(
        response => {
          if (response.status === 200) {
            let currentSlNo = 1;
            const curationDetails = response.data.curationDetails !== null ? JSON.parse(response.data.curationDetails) : null;
            const updatedArray = response.data.contentDetails.courseSectionResponseTO.map(section => {
              return {
                ...section,
                courseContentResposeTOList: section.courseContentResposeTOList.map(content => ({
                  ...content,
                  slNo: currentSlNo++
                }))
              };
            });

            setTimeout(() => {
              setCourseDetails(response.data);
              setContentType(response.data.contentType);
              setLanguages(response.data.languages);
              setContentDetails(updatedArray);
              setOpenStates(updatedArray.map(() => true));
              setSubCategoryName(response.data.subCategoryName);
              setLoading(false);
              setTop5Route(false);
              response.data.curationVisibleDetails.map(visible => {
                if (visible.contentName === 'DOCUMENTS') {
                  setCurationVisibleDetailsDocument(visible);
                  setDocument(visible.visible ? curationDetails !== null ? !curationDetails.documentDeselected : true : false);
                }
                if (visible.contentName === 'VIDEOS') {
                  setCurationVisibleDetailsVideos(visible);
                  setVideos(visible.visible ? curationDetails !== null ? !curationDetails.videoDeselected : true : false);
                }
                if (visible.contentName === 'MEETING') {
                  setCurationVisibleDetailsMeeting(visible);
                  setLiveSessions(
                    visible.visible ?

                      curationDetails !== null ? !curationDetails.meetingDeselected : true : false);
                }
                if (visible.contentName === 'LABS') {
                  setCurationVisibleDetailsLabs(visible);
                  setLabs(visible.visible ? curationDetails !== null ? !curationDetails.labDeselected : true : false);
                }
                if (visible.contentName === 'MCQ') {
                  setCurationVisibleDetailsMcq(visible);
                  setMcq(visible.visible ? curationDetails !== null ? !curationDetails.mcqDeselected : true : false);
                }
                if (visible.contentName === 'CODING') {
                  setCurationVisibleDetailsCoding(visible);
                  setCoding(visible.visible ? curationDetails !== null ? !curationDetails.codingDeselected : true : false);
                }
                setTotalPrice(currency === 'INR' ?
                  response.data.price
                  :
                  response.data.internationalCoursePrice);
                const allValuesTrue = curationDetails !== null ? Object.values(curationDetails).some(value => value === true) : false;

                setSelectReset(!allValuesTrue);
                // setLiveSessions(curationDetails !== null ? !curationDetails.meetingDeselected : true);
                // setLabs(curationDetails !== null ? !curationDetails.labDeselected : true);
                // setMcq(curationDetails !== null ? !curationDetails.mcqDeselected : true);
                // setCoding(curationDetails !== null ? !curationDetails.codingDeselected : true);
                // setDocument(curationDetails !== null ? !curationDetails.documentDeselected : true);
                // setVideos(curationDetails !== null ? !curationDetails.videoDeselected : true);

              })
            }, 2000);


          }
        },
        err => {
          console.error(err);
        }
      )
    } catch (err) {
      console.error("error occur on curateCourse()", err)
    }
  }

  const notifyMe = () => {
    setNotify(true);
    try {

      const name = `${user?.consumerProfileDetails?.firstName} ${user?.consumerProfileDetails?.lastName}`;
      const email = user?.consumerProfileDetails?.email;
      const phoneNumber = user?.consumerProfileDetails?.phoneNumber;

      let payload = {
        "email": email,
        "name": name,
        "phoneNumber": phoneNumber
      }


      RestService.notifyMe(payload).then(
        response => {
          if (response.status === 200) {

            ToastMessage({ type: "success", message: `${response.data}`, time: 2000 });
            setNotify(false);
          }
        },
        err => {
          console.error(err);

          ToastMessage({ type: "warning", message: `${err.response.data.message}`, time: 2000 });
          setNotify(false);
        }
      )
    } catch (err) {
      console.error("error occur on notifyMe()", err)
    }

  }


  //proceed to course
  const proceedToTraining = () => {
    const consumerSid = user?.sid;
    const courseSid = courseDetails.courseSid
    // Construct the URL
    const url = `https://lxp.learnlytica.com/?njnjknkenenkjandkjeneakeajkekaned=${courseSid}&uhugttdjnjnjndaasksske3ninonoinioqdoiodiendeoin=${consumerSid}`;

    // Open a new tab with the specified URL
    // window.open(url, '_blank');
    window.location.href = url;

  }

  useEffect(() => {
    // Use moment to format the current date
    ReactGA.event({
      category: "On Specific Course",
      action: "Clicked",
      label: "clicked on specific course", // optional
      value: courseid, // optional, must be a number
      nonInteraction: true, // optional, true/false
      transport: "xhr", // optional, beacon/xhr/image
    });
    const formattedDate = moment().format('YYYY-MM-DD');

    // Set the formatted date in the state
    setCurrentDate(formattedDate);

    if ('user' in localStorage) {
      getConsumerCourseDetailsUser();
      getTop5CoursesForLoginUserForSpecificCourses();
    }
    else {
      getConsumerCourseDetails();
      getTop5CoursesForSpecificCourses();
    }


  }, []);

  useEffect(() => {
    // Use moment to format the current date
    const formattedDate = moment().format('YYYY-MM-DD');

    // Set the formatted date in the state
    setCurrentDate(formattedDate);

    if (top5Route) {
      if ('user' in localStorage) {
        getConsumerCourseDetailsUser();
        getTop5CoursesForLoginUserForSpecificCourses();
      }
      else {
        getConsumerCourseDetails();
        getTop5CoursesForSpecificCourses();
      }
    }


  }, [top5Route]);


  const formatStartDate = () => {
    if (courseDetails.trainingStartDate) {
      const startDate = new Date(courseDetails.trainingStartDate);
      return startDate.toLocaleDateString("en-GB", { day: '2-digit', month: '2-digit', year: 'numeric' }).replace(/\//g, '-');
    }
    return null;
  };

  const formatEndDate = () => {
    if (courseDetails.trainingEndDate) {
      const endDate = new Date(courseDetails.trainingEndDate);
      return endDate.toLocaleDateString("en-GB", { day: '2-digit', month: '2-digit', year: 'numeric' }).replace(/\//g, '-');
    }
    return null;
  };

  useEffect(() => {
    if (selectCurateAny) {
      const count = [liveSessions, labs, mcq, coding, document, videos].filter(Boolean).length;
      setModulesSelected(count);
      if ('user' in localStorage) {
        if (courseDetails?.bought === false)
          curateCourse();

      }
    }
  }, [liveSessions, labs, mcq, coding, document, videos]);

  return (


    <>

      <Helmet>
        <title>Learnlytica |{subCategoryName} | Course |</title>
        <meta name="description" content="Discover the future of personalized learning assessments with Learnlytica. Explore personalized learning paths, diverse assessments, and real-time analytics. Join today for a transformative educational experience." />
        <meta name="keywords" content="learning assessments, personalized learning paths, real-time analytics, skills improvement, education platform, diverse assessments, progress tracking" />

      </Helmet>

      <ToastContainer />
      <div className='main-header mb-5 mt-5 desktop-view'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-8 col-md-8 col-sm-12'>
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb"
                //  style={{ "--bs-breadcrumb-divider": "'>'" }}

                >
                  <li class="breadcrumb-item"><Link to="/course-library">{loading ?
                    <SkeletonLoader skeletonCount={1} />
                    : "Course-Library"}</Link></li>
                  <li class="breadcrumb-item active" aria-current="page">{
                    loading ?
                      <SkeletonLoader skeletonCount={1} />
                      :
                      subCategoryName
                  }</li>
                </ol>
              </nav>
              <AnimationWrapper animationType="heading">
                <div className='main-header-title pt-3'>{
                  loading ?
                    <SkeletonLoader skeletonCount={1} />
                    : courseDetails.courseName}</div></AnimationWrapper>
              {/* <div className='main-header-title pt-3'>UX Design Process from User Research to Usability Testing</div> */}

              <AnimationWrapper animationType="subHeading">
                <div className='main-header-sub-title pt-3' data-tooltip-id="subheader" data-tooltip-content={(courseDetails.courseDescription)}>{
                  loading ?
                    <SkeletonLoader skeletonCount={1} />
                    : parse(courseDetails.courseDescription)}</div></AnimationWrapper>


              {/* <div className='main-header-sub-title pt-3'>Untitled are a commercial interior design studio. We specialize in custom office design, restaurant design, shop design, and studio design.</div> */}
              <div className='d-flex flex-column flex-sm-row gap-5  mt-1 pt-4 pb-2' style={{ borderBottom: "1px solid #EAECF0" }}>
                <div className='total card-subtitle'><span className='mx-2'>{ICN_TOTAL_LECTURE}</span><span className='mt-sm-0 mt-3'>{
                  loading ?
                    <SkeletonLoader skeletonCount={1} />
                    : contentType["TOTAL CONTENT"]} Artefact</span></div>
                <div className='total card-subtitle'>
                  <span className='mx-2'>{ICON_SESSION}</span>
                  <span className='mt-sm-0 mt-3'>{loading ?
                    <SkeletonLoader skeletonCount={1} />
                    : contentType["TRAINING_SESSION"]} Expert Sessions
                  </span></div>
                {/* <div className='total card-subtitle'><span className='mx-2'>{ICN_VIDEO}</span><span className='mt-sm-0 mt-3'>{contentType["VIDEOS"]} Videos</span></div> */}

                <div className='total card-subtitle'><span className='mx-2'>{ICN_LAB}</span><span className='mt-sm-0 mt-3'>{loading ?
                  <SkeletonLoader skeletonCount={1} />
                  : contentType["PRACTICE LABS"] + contentType["ASSESSMENT LABS"]} Labs</span></div>
                {/* {
                  loading ?
                    <SkeletonLoader skeletonCount={1} />
                    :
                    courseDetails.courseType === 'INSTRUCTOR_LED' &&
                    <div className='total card-subtitle'><span className='mt-sm-0 mt-3'>{contentType["TRAINING_SESSION"]} Session</span></div>
                } */}

                {
                  loading ?
                    <SkeletonLoader skeletonCount={1} />
                    :
                    <div className='total card-subtitle'><span className='mx-2'>{MCQASS_ICN}</span><span className='mt-sm-0 mt-3'>{
                      contentType["MCQ ASSESSMENTS"]} MCQ Assessment</span></div>
                }
                {
                  loading ?
                    <SkeletonLoader skeletonCount={1} />
                    :
                    <div className='total card-subtitle'><span className='mx-2'>{ASSESSMENT_ICON}</span><span className='mt-sm-0 mt-3'>{
                      contentType["CODING ASSESSMENTS"]} Coding Assessment</span></div>
                }

                {/* {
                        courseDetails.courseType === 'INSTRUCTOR_LED' &&
                        <div className='total card-subtitle'><span className='mt-sm-0 mt-3'>{courseDetails.courseExpiry}</span></div>
                      } */}
                {/* <div className='total card-subtitle'><span className='mx-2'>{ICN_TOTAL_LECTURE}</span><span className='mt-sm-0 mt-3'>365 Lectures</span></div>
                <div className='total card-subtitle'><span className='mx-2'>{ICN_TOTAL_TIME}</span><span className='mt-sm-0 mt-3'>8 Total Hours</span></div>
                <div className='total card-subtitle'><span className='mx-2'>{ICN_TOTAL_TIME}</span><span className='mt-sm-0 mt-3'>27 Resources</span></div>
                <div className='total card-subtitle'><span className='mx-2'>{ICN_TOTAL_TIME}</span><span className='mt-sm-0 mt-3'>14 Labs</span></div>

                <div className='total card-subtitle'><span className='mx-2'>{ICN_LANGUAGE}</span>English, Hindi</div> */}
              </div>

              <div className='d-flex flex-column flex-sm-row gap-5 mb-4 pt-1 pt-2'>
                <div className='total card-subtitle'><span className='mx-2'>{ICN_RESOURCES}</span><span className='mt-sm-0 mt-3'>{loading ?
                  <SkeletonLoader skeletonCount={1} />
                  : contentType["DOCUMENTS"]} Document</span></div>

                <div className='total card-subtitle'><span className='mx-2'>{VIDEO_ICN1}</span><span className='mt-sm-0 mt-3'>{loading ?
                  <SkeletonLoader skeletonCount={1} />
                  : contentType["VIDEOS"]} Videos</span></div>

                <div className='total card-subtitle'><span className='mx-2'>{ICN_LANGUAGE}</span>{loading ?
                  <SkeletonLoader skeletonCount={1} />
                  : languages.toString()}</div>
                {/* {
                      courseDetails.courseType === 'INSTRUCTOR_LED' &&
                      <div className='total card-subtitle'><span className='mt-sm-0 mt-3'>{courseDetails.courseExpiry}</span></div>
                    } */}
                {/* <div className='total card-subtitle'><span className='mx-2'>{ICN_TOTAL_LECTURE}</span><span className='mt-sm-0 mt-3'>365 Lectures</span></div>
              <div className='total card-subtitle'><span className='mx-2'>{ICN_TOTAL_TIME}</span><span className='mt-sm-0 mt-3'>8 Total Hours</span></div>
              <div className='total card-subtitle'><span className='mx-2'>{ICN_TOTAL_TIME}</span><span className='mt-sm-0 mt-3'>27 Resources</span></div>
              <div className='total card-subtitle'><span className='mx-2'>{ICN_TOTAL_TIME}</span><span className='mt-sm-0 mt-3'>14 Labs</span></div>

              <div className='total card-subtitle'><span className='mx-2'>{ICN_LANGUAGE}</span>English, Hindi</div> */}
              </div>
            </div>

            <div className='col-lg-4 col-md-4 col-sm-12  '>
              <div className="card course-card ">
                {loading ?
                  <SkeletonLoader skeletonCount={3} />
                  :

                  <MediaDisplay

                    mediaUrl={courseDetails?.thumbnail ? courseDetails.thumbnail : 'https://learnlyticabackup.s3.ap-south-1.amazonaws.com/learnlytica/Learnlytica+-+Our+services.mp4'}
                    altText="Description of the media"
                  />
                }


                <div className="card-body" style={{ padding: "16px 32px" }}>
                  <div className='d-flex  align-items-center mt-3'>
                    <div className="course-card-h">{currency === 'INR' ? '₹' : '$'}{loading ?
                      <SkeletonLoader skeletonCount={1} />
                      :
                      currency === 'INR' ?
                        Math.floor(courseDetails.price)
                        :
                        Math.floor(courseDetails.internationalCoursePrice)} </div>
                    {/* <div className="course-card-h"> ₹529 </div> */}
                    {/* <div className='d-flex px-4'>
                      <div className='course-card-h-offer'> ₹1,999</div>
                      <div className='course-card-h-discount ml-2'>74%</div>
                    </div> */}
                  </div>
                  <div className='course-card-content pt-4 pb-4'>
                    <ul>
                      {loading ?
                        <SkeletonLoader skeletonCount={1} />
                        :
                        formatStartDate() && formatEndDate() &&
                        <li> {`Expert Session Starts-on : ${formatStartDate()} `}</li>
                      }

                      {loading ?
                        <SkeletonLoader skeletonCount={1} />
                        :
                        <li> {courseDetails.courseDuration} Total Hours</li>
                      }


                      {loading ?
                        <SkeletonLoader skeletonCount={1} />
                        :
                        <li>Lifetime Access: Expert Session Recordings</li>
                      }
                      {loading ?
                        <SkeletonLoader skeletonCount={1} />
                        :
                        <li>Access Our Support for this purchase</li>
                      }
                    </ul>
                  </div>

                  {/* customize course start*/}
                  {

                    (courseDetails?.bought === false && currentDate > courseDetails.trainingStartDate?.substring(0, 10)) ?
                      <></>
                      :
                      <>
                        <div className='d-flex' onClick={toggleCollapse} style={{ justifyContent: "space-between", cursor: "pointer" }}>
                          <div className='learningpt-header'>Customize Your Learning Experience</div>
                          <div className='assement-objective-collpase-icon'>  {isCollapsed ? <span>{ICN_EXPAND_ACCORDIAN}</span> : <span>{ICN_COLLAPSED}</span>}</div>

                        </div>

                        {loading ?
                          <SkeletonLoader skeletonCount={1} />
                          :
                          !isCollapsed &&
                          <>
                            <div className='customize-course-title mt-3 mb-4'>Customize your course with our new feature: choose videos, documents, MCQs, coding tasks, labs, or live sessions. Tailor your learning journey to fit your unique style and needs.</div>
                            <div className='SelectModule'>Select Artefact to Add to Your Course</div>
                            {
                              modulesSelected === 1 &&

                              <div className='SelectModule mt-2' style={{ color: 'red' }}>Select atleast 1 artefact</div>
                            }

                            <div className='customize'>
                              <div className='course-card-content pt-4 pb-4 mx-2 '>
                                <div className='row'>

                                  {/* {curationVisibleDetailsMeeting.visible && */}

                                  <div className='col-sm-12 col-md-6 col-lg-6' >
                                    <div className='selectmoduletype' style={{ borderColor: liveSessions ? '#6941C6' : '', background: (courseDetails?.bought || curationVisibleDetailsMeeting.free) && '#f2f2f2' }}>
                                      <div className="d-flex" style={{ justifyContent: "space-between", alignItems: "center" }}> <span className='selectedmodule_icon'>{CUSTOMIZE_LIVE_SESSION_ICN}</span>
                                        {(curationVisibleDetailsMeeting?.visible) &&
                                          <input id="liveSessions" type="checkbox" name="liveSessions" checked={liveSessions}
                                            onChange={(e) => {
                                              if ('user' in localStorage === false) {
                                                setCustomizeCourse(true);
                                              }
                                              else {
                                                setLiveSessions(e.target.checked);
                                                setSelectCurateAny(true);
                                              }

                                            }}
                                            disabled={(modulesSelected === 1 && liveSessions) || courseDetails?.bought || curationVisibleDetailsMeeting.free} style={{ cursor: ((modulesSelected === 1 && liveSessions) || courseDetails?.bought || curationVisibleDetailsMeeting.free) && 'not-allowed' }} />
                                        }</div>

                                      <label for="liveSessions" style={{ color: liveSessions ? '#6941C6' : '' }}>{!curationVisibleDetailsMeeting.visible ? 'No' : curationVisibleDetailsMeeting?.originalContentCount}  Live sessions</label>
                                    </div>
                                  </div>
                                  {/* } */}

                                  {/* {curationVisibleDetailsLabs.visible && */}
                                  <div className='col-sm-12 col-md-6 col-lg-6' >
                                    <div className='selectmoduletype' style={{ justifyContent: "space-between", borderColor: labs ? '#6941C6' : '', background: (courseDetails?.bought || curationVisibleDetailsLabs.free) && '#f2f2f2' }} >
                                      <div className="d-flex" style={{ justifyContent: "space-between", alignItems: "center" }}><span className='selectedmodule_icon'>{CUSTOMIZE_LAB_ICN}</span>
                                        {(curationVisibleDetailsLabs?.visible) &&
                                          <input id="labs" type="checkbox" name="labs" checked={labs}
                                            onChange={(e) => {
                                              if ('user' in localStorage === false) {
                                                setCustomizeCourse(true);
                                              }
                                              else {
                                                setLabs(e.target.checked);
                                                setSelectCurateAny(true);
                                              }
                                            }}
                                            disabled={(modulesSelected === 1 && labs) || courseDetails?.bought || curationVisibleDetailsLabs.free} style={{ cursor: ((modulesSelected === 1 && labs) || courseDetails?.bought || curationVisibleDetailsLabs.free) && 'not-allowed' }}

                                          />
                                        }
                                      </div>
                                      <label for="labs" style={{ color: labs ? '#6941C6' : '' }}>{!curationVisibleDetailsLabs.visible ? 'No' : curationVisibleDetailsLabs?.originalContentCount} Labs</label>

                                    </div>
                                  </div>
                                  {/* } */}
                                </div>
                                <div className='row'>
                                  {/* {curationVisibleDetailsMcq.visible && */}
                                  <div className='col-sm-12 col-md-6 col-lg-6'>
                                    <div className='selectmoduletype' style={{ borderColor: mcq ? '#6941C6' : '', background: (courseDetails?.bought || curationVisibleDetailsMcq.free) && '#f2f2f2' }}>
                                      <div className="d-flex" style={{ justifyContent: "space-between", alignItems: "center" }}> <span className='selectedmodule_icon'>{CUSTOMIZE_MCQ_ASSE_ICN}</span>
                                        {(curationVisibleDetailsMcq?.visible) &&
                                          <input id="mcq" type="checkbox" name="mcq" checked={mcq}
                                            onChange={(e) => {
                                              if ('user' in localStorage === false) {
                                                setCustomizeCourse(true);
                                              }
                                              else {
                                                setMcq(e.target.checked);
                                                setSelectCurateAny(true);
                                              }
                                            }}
                                            disabled={(modulesSelected === 1 && mcq) || courseDetails?.bought || curationVisibleDetailsMcq.free} style={{ cursor: ((modulesSelected === 1 && mcq) || courseDetails?.bought || curationVisibleDetailsMcq.free) && 'not-allowed' }}
                                          />}
                                      </div>

                                      <label for="mcq" style={{ color: mcq ? '#6941C6' : '' }}>{!curationVisibleDetailsMcq.visible ? 'No' : curationVisibleDetailsMcq?.originalContentCount} MCQ Assessment</label>
                                    </div>
                                  </div>
                                  {/* } */}
                                  {/* {curationVisibleDetailsCoding.visible && */}

                                  <div className='col-sm-12 col-md-6 col-lg-6'>
                                    <div className='selectmoduletype' style={{ justifyContent: "space-between", borderColor: coding ? '#6941C6' : '', background: (courseDetails?.bought || curationVisibleDetailsCoding.free) && '#f2f2f2' }}>
                                      <div className="d-flex" style={{ justifyContent: "space-between", alignItems: "center" }}><span className='selectedmodule_icon'>{CUSTOMIZE_CODING_ASS_ICN}</span>
                                        {
                                          (curationVisibleDetailsCoding.visible) &&

                                          <input id="coding" type="checkbox" name="coding" checked={coding}
                                            onChange={(e) => {
                                              if ('user' in localStorage === false) {
                                                setCustomizeCourse(true);
                                              }
                                              else {
                                                setCoding(e.target.checked);
                                                setSelectCurateAny(true);
                                              }

                                            }}
                                            disabled={(modulesSelected === 1 && coding) || courseDetails?.bought || curationVisibleDetailsCoding.free} style={{ cursor: ((modulesSelected === 1 && coding) || courseDetails?.bought || curationVisibleDetailsCoding.free) && 'not-allowed' }}
                                          />
                                        }
                                      </div>
                                      <label for="coding" style={{ borderColor: coding ? '#6941C6' : '' }}>{!curationVisibleDetailsCoding.visible ? 'No' : curationVisibleDetailsCoding?.originalContentCount} Coding Assessment</label>

                                    </div>
                                  </div>
                                  {/* } */}
                                </div>

                                <div className='row'>
                                  {/* {curationVisibleDetailsDocument.visible && */}
                                  <div className='col-sm-12 col-md-6 col-lg-6'>
                                    <div className='selectmoduletype' style={{ borderColor: document ? '#6941C6' : '', background: (courseDetails?.bought || curationVisibleDetailsDocument.free) && '#f2f2f2' }} >
                                      <div className="d-flex" style={{ justifyContent: "space-between", alignItems: "center" }}> <span className='selectedmodule_icon'>{CUSTOMIZE_DOCUMENT_ICN} </span>
                                        {
                                          (curationVisibleDetailsDocument.visible) &&

                                          <input id="document" type="checkbox" name="document" checked={document}
                                            onChange={(e) => {
                                              if ('user' in localStorage === false) {
                                                setCustomizeCourse(true);
                                              }
                                              else {
                                                setDocument(e.target.checked);
                                                setSelectCurateAny(true);
                                              }
                                            }}
                                            disabled={(modulesSelected === 1 && document) || courseDetails?.bought || curationVisibleDetailsDocument.free} style={{ cursor: ((modulesSelected === 1 && document) || courseDetails?.bought || curationVisibleDetailsDocument.free) && 'not-allowed' }}
                                          />
                                        }</div>

                                      <label for="document" style={{ borderColor: document ? '#6941C6' : '' }}>{!curationVisibleDetailsDocument.visible ? 'No' : curationVisibleDetailsDocument?.originalContentCount} Document</label>
                                    </div>
                                  </div>
                                  {/* } */}
                                  {/* {
                              curationVisibleDetailsVideos.visible && */}

                                  <div className='col-sm-12 col-md-6 col-lg-6'>
                                    <div className='selectmoduletype' style={{ justifyContent: "space-between", borderColor: videos ? '#6941C6' : '', background: (courseDetails?.bought || curationVisibleDetailsVideos.free) && '#f2f2f2' }}>
                                      <div className="d-flex" style={{ justifyContent: "space-between", alignItems: "center" }}><span className='selectedmodule_icon'>{CUSTOMIZE_VIDEO_ICN}</span>
                                        {
                                          (curationVisibleDetailsVideos.visible) &&
                                          <input id="videos" type="checkbox" name="videos"
                                            checked={videos}
                                            onChange={(e) => {
                                              if ('user' in localStorage === false) {
                                                setCustomizeCourse(true);
                                              }
                                              else {
                                                setVideos(e.target.checked);
                                                setSelectCurateAny(true);
                                              }
                                            }}
                                            disabled={(modulesSelected === 1 && videos) || courseDetails?.bought || curationVisibleDetailsVideos.free} style={{ cursor: ((modulesSelected === 1 && videos) || courseDetails?.bought || curationVisibleDetailsVideos.free) && 'not-allowed' }}
                                          />
                                        }
                                      </div>
                                      <label for="videos" style={{ borderColor: videos ? '#6941C6' : '' }}>{!curationVisibleDetailsVideos.visible ? 'No' : curationVisibleDetailsVideos?.originalContentCount} Videos</label>

                                    </div>
                                  </div>
                                  {/* } */}
                                </div>





                              </div>
                            </div>
                            <div className='mt-4'>

                              <div className='course-selected'>{modulesSelected} Artefact selected</div>

                              <div className='course-selected mt-1'>{courseDetails?.bought ? 'You bought this course for' : 'Your estimated course price'} : <span className='mx-2'>{currency === 'INR' ? '₹' : '$'}{loading ?
                                <SkeletonLoader skeletonCount={1} />
                                : currency === 'INR' ?
                                  Math.floor(courseDetails.price)
                                  :
                                  Math.floor(courseDetails.internationalCoursePrice)}</span> </div>
                            </div>
                            {/* {
                        loading ?
                          <SkeletonLoader skeletonCount={1} />
                          :
                          <div className='d-flex mt-3 '>
                            <button className='btn-cancle' onClick={() => resetCustomizeButton()}>Cancel</button>
                            {
                              courseDetails?.bought === false && currentDate <= courseDetails.trainingStartDate?.substring(0, 10) ?
                                <button class="addtocart mx-2 " onClick={() => handleAddToCart()}>Customize and Add to cart</button>
                                :
                                <button class="addtocart mx-2 " onClick={() => handleAddToCart()}>Customize and Add to cart</button>
                            }

                          </div>


                      } */}
                          </>
                        }
                      </>


                  }


                  {/* customize course section end*/}

                  {
                    loading ?
                      <SkeletonLoader skeletonCount={1} />
                      :
                      courseDetails?.bought ?
                        <button class="addtocart mt-4" onClick={proceedToTraining}>Proceed to Course</button>

                        // courseDetails?.bought && currentDate < courseDetails.trainingStartDate?.substring(0, 10) ?
                        //   <>
                        //     <button class="addtocart mt-4" disabled style={{ cursor: "not-allowed" }}>Proceed to Course</button>
                        //     <div className='course-card-content'>
                        //       <ul>
                        //         <li>
                        //           <span className='mx-2'>{CALENDAR_ICON}</span> Starts on {courseDetails.trainingStartDate?.substring(0, 10)}
                        //         </li>
                        //       </ul>
                        //     </div>
                        //   </>
                        :
                        courseDetails?.bought === false && currentDate > courseDetails.trainingStartDate?.substring(0, 10) ?
                          <button class="addtocart mt-4" onClick={() => {
                            if ('user' in localStorage === false) {
                              setNotification(true);

                            }
                            else {
                              //api call
                              notifyMe();
                            }
                          }}>
                            {notify ? (
                              <>
                                <Spinner
                                  as="span"
                                  animation="border"
                                  size="md"
                                  role="status"
                                  aria-hidden="true"
                                  className="mr-2" // Add margin to separate spinner from text
                                  style={{ border: '5px dotted #fff' }}
                                />
                                {' '}
                                Loading...
                              </>
                            ) : 'Nominations Closed | Notify'}


                          </button>
                          :
                          courseDetails?.bought === false && currentDate <= courseDetails.trainingStartDate?.substring(0, 10) && cartResponse !== null && cartResponse.cartDetails?.filter(item => item.courseName.includes(courseDetails.courseName)).length > 0 ?
                            <div className='d-flex mt-3 '>
                              <button className='btn-cancle' style={{ opacity: selectReset ? 0.6 : 1 }} onClick={() => resetCustomizeButton()} disabled={selectReset}>Reset</button>
                              <button class="addtocart mx-2" onClick={() => { setShowCart(true); displayCart(); }}>Proceed To Payment</button>
                            </div>
                            :
                            courseDetails?.bought === false && currentDate <= courseDetails.trainingStartDate?.substring(0, 10) ?
                              <div className='d-flex mt-3 '>
                                <button className='btn-cancle' style={{ opacity: selectReset ? 0.6 : 1 }} onClick={() => resetCustomizeButton()} disabled={selectReset}>Reset</button>

                                <button class="addtocart mx-2 " onClick={() => handleAddToCart()}>Customize and Add to cart</button>



                              </div>
                              :
                              <div className='d-flex mt-3 '>
                                <button className='btn-cancle' style={{ opacity: selectReset ? 0.6 : 1 }} onClick={() => resetCustomizeButton()} disabled={selectReset}>Reset</button>

                                <button class="addtocart mx-2 " onClick={() => handleAddToCart()}>Customize and Add to cart</button>



                              </div>

                  }
                  <BsModal {...{ show: showCart, setShow: setShowCart, size: "md" }} />
                </div>
                {/* <button className='addtocart' >Add to cart</button> */}

              </div>



            </div>
          </div>
        </div>
      </div>


      {/* Mobile view header */}


      <div className='mobile-view mb-5'>

        <div className='container'>
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb"
            //  style={{ "--bs-breadcrumb-divider": "'>'" }}

            >
              <li class="breadcrumb-item"><Link to="/course-library">{loading ?
                <SkeletonLoader skeletonCount={1} />
                : "Course-Library"}</Link></li>
              <li class="breadcrumb-item active" aria-current="page">{
                loading ?
                  <SkeletonLoader skeletonCount={1} />
                  :
                  subCategoryName.length >= 25 ? subCategoryName.substring(0, 25) + "...." : subCategoryName
              }</li>
            </ol>
          </nav>
          <div className='row '>
            <div className="card course-card ">
              {loading ?
                <SkeletonLoader skeletonCount={3} />
                :
                // <ReactPlayer url={courseDetails?.thumbnail ? courseDetails.thumbnail : 'https://www.youtube.com/watch?v=Qgn8Kdx5Tx8'}
                //   className='react-player'
                //   width="100%"
                //   config={{
                //     file: {
                //       attributes: {
                //         controlsList: 'nodownload'  //<- this is the important bit
                //       }
                //     }
                //   }}
                //   playing={true}
                //   // loop={true}
                //   muted={true}
                //   controls
                //   light
                //   style={{ borderTopLeftRadius: "12px", borderTopRightRadius: "12px" }}
                // />
                <MediaDisplay

                  mediaUrl={courseDetails?.thumbnail ? courseDetails.thumbnail : 'https://learnlyticabackup.s3.ap-south-1.amazonaws.com/learnlytica/Learnlytica+-+Our+services.mp4'}
                  altText="Description of the media"
                />
              }


              <div className="card-body" style={{ padding: "16px 32px" }}>

                <div className='main-header-title pt-3'>{
                  loading ?
                    <SkeletonLoader skeletonCount={1} />
                    : courseDetails.courseName}</div>
                {/* <div className='main-header-title pt-3'>UX Design Process from User Research to Usability Testing</div> */}
                <div className='main-header-sub-title pt-3'>{
                  loading ?
                    <SkeletonLoader skeletonCount={1} />
                    : parse(courseDetails.courseDescription)}</div>


                <div className='course-card-content pt-4 pb-4'>

                  <div className='col-sm-6 col-md-6  '>

                    <div className='course-card-content'>
                      <ul>
                        {loading ?
                          <SkeletonLoader skeletonCount={1} />
                          :
                          formatStartDate() && formatEndDate() &&
                          <li><span className='mx-2'>{CALENDAR_ICON}</span> {`Expert Session Starts-on : ${formatStartDate()} `}</li>
                        }

                        <li> <span className='mx-2'>{ICON_SESSION}</span><span className='mt-sm-0 mt-3'> {contentType["TRAINING_SESSION"]} Expert Sessions</span></li>
                        {loading ?
                          <SkeletonLoader skeletonCount={1} />
                          :
                          <li><span className='mx-2'>{ICN_TOTAL_LECTURE}</span><span className='mt-sm-0 mt-3'>{
                            loading ?
                              <SkeletonLoader skeletonCount={1} />
                              : contentType["TOTAL CONTENT"]} Artefact</span></li>
                        }
                        {loading ?
                          <SkeletonLoader skeletonCount={1} />
                          :
                          <li><span className='mx-2'>{ICN_TOTAL_TIME}</span><span className='mt-sm-0 mt-3'>{loading ?
                            <SkeletonLoader skeletonCount={1} />
                            : courseDetails.courseDuration} Total Hours</span></li>
                        }
                        {loading ?
                          <SkeletonLoader skeletonCount={1} />
                          :
                          <li><span className='mx-2'>{ICN_RESOURCES}</span><span className='mt-sm-0 mt-3'>{loading ?
                            <SkeletonLoader skeletonCount={1} />
                            : contentType["DOCUMENTS"]} Resources</span></li>
                        }
                      </ul>
                    </div>

                  </div>

                  <div className='col-sm-6 col-md-6 '>
                    <div className='course-card-content'>
                      <ul>
                        {loading ?
                          <SkeletonLoader skeletonCount={1} />
                          :
                          <li><span className='mx-2'>{ICN_LAB}</span><span className='mt-sm-0 mt-3'>{loading ?
                            <SkeletonLoader skeletonCount={1} />
                            : contentType["PRACTICE LABS"]} Labs</span></li>
                        }
                        {loading ?
                          <SkeletonLoader skeletonCount={1} />
                          :
                          <li><span className='mx-2'>{ASSESSMENT_ICON}</span><span className='mt-sm-0 mt-3'>{contentType["ASSESSMENT LABS"]
                            + contentType["MCQ ASSESSMENTS"] + contentType["CODING ASSESSMENTS"]} Assessment</span></li>
                        }
                        {loading ?
                          <SkeletonLoader skeletonCount={1} />
                          :
                          <li><span className='mx-2'>{ICN_LANGUAGE}</span>{loading ?
                            <SkeletonLoader skeletonCount={1} />
                            : languages.toString()}</li>
                        }
                      </ul>
                    </div>

                  </div>


                  <div className='d-flex  align-items-center mt-3 mb-5 '>
                    <div className="course-card-h "> {currency === 'INR' ? '₹' : '$'}{loading ?
                      <SkeletonLoader skeletonCount={1} />
                      : currency === 'INR' ?
                        Math.floor(courseDetails.price)
                        :
                        Math.floor(courseDetails.internationalCoursePrice)} </div>
                    {/* <div className="course-card-h"> ₹529 </div> */}
                    {/* <div className='d-flex px-4'>
                      <div className='course-card-h-offer'> ₹1,999</div>
                      <div className='course-card-h-discount ml-2'>74%</div>
                    </div> */}
                  </div>


                  <hr />
                  {/* customize course mobile starts*/}
                  {(courseDetails?.bought === false && currentDate > courseDetails.trainingStartDate?.substring(0, 10)) ?
                    <></>
                    :
                    <>
                      <div className='d-flex' onClick={toggleCollapse} style={{ justifyContent: "space-between", cursor: "pointer" }}>
                        <div className='learningpt-header'>Customize Your Learning Experience</div>
                        <div className='assement-objective-collpase-icon'>  {isCollapsed ? <span>{ICN_EXPAND_ACCORDIAN}</span> : <span>{ICN_COLLAPSED}</span>}</div>

                      </div>
                      {
                        loading ?
                          <SkeletonLoader skeletonCount={6} />
                          :
                          !isCollapsed &&
                          <>
                            <div className='customize-course-title mt-3 mb-4'>Customize your course with our new feature: choose videos, documents, MCQs, coding tasks, labs, or live sessions. Tailor your learning journey to fit your unique style and needs.</div>
                            <div className='SelectModule'>Select Artefact to Add to Your Course</div>
                            {
                              modulesSelected === 1 &&
                              < div className='SelectModule mt-2' style={{ color: 'red' }}>Select atleast 1 artefact</div>
                            }

                            <div className='course-card-content pt-4 pb-4'>

                              <div className='selectmoduletype d-flex' style={{ justifyContent: "space-between", borderColor: liveSessions ? '#6941C6' : '', background: (courseDetails?.bought || curationVisibleDetailsMeeting.free) && '#f2f2f2' }}>
                                <div><span className='selectedmodule_icon'>{CUSTOMIZE_LIVE_SESSION_ICN}</span>
                                  <label for="liveSessions" style={{ color: liveSessions ? '#6941C6' : '' }}>{!curationVisibleDetailsMeeting.visible ? 'No' : curationVisibleDetailsMeeting?.originalContentCount}  Live sessions</label></div>
                                {

                                  (curationVisibleDetailsMeeting?.visible) &&
                                  < input id="liveSessions" type="checkbox" name="liveSessions" checked={liveSessions}
                                    onChange={(e) => {
                                      if ('user' in localStorage === false) {
                                        setCustomizeCourse(true);
                                      }
                                      else {
                                        setLiveSessions(e.target.checked);
                                        setSelectCurateAny(true);
                                      }

                                    }}
                                    disabled={(modulesSelected === 1 && liveSessions) || courseDetails?.bought || curationVisibleDetailsMeeting.free} style={{ cursor: ((modulesSelected === 1 && liveSessions) || courseDetails?.bought || curationVisibleDetailsMeeting.free) && 'not-allowed' }}
                                  />
                                }
                              </div>



                              <div className='selectmoduletype d-flex' style={{ justifyContent: "space-between", borderColor: labs ? '#6941C6' : '', background: (courseDetails?.bought || curationVisibleDetailsLabs.free) && '#f2f2f2' }}>
                                <div><span className='selectedmodule_icon'>{CUSTOMIZE_LAB_ICN}</span>
                                  <label for="labs" style={{ color: labs ? '#6941C6' : '' }}>{!curationVisibleDetailsLabs.visible ? 'No' : curationVisibleDetailsLabs?.originalContentCount} Labs</label></div>
                                {
                                  (curationVisibleDetailsLabs.visible) &&

                                  <input id="labs" type="checkbox" name="labs" checked={labs}
                                    onChange={(e) => {
                                      if ('user' in localStorage === false) {
                                        setCustomizeCourse(true);
                                      }
                                      else {
                                        setLabs(e.target.checked);
                                        setSelectCurateAny(true);
                                      }
                                    }}
                                    disabled={(modulesSelected === 1 && labs) || courseDetails?.bought || curationVisibleDetailsLabs.free} style={{ cursor: ((modulesSelected === 1 && labs) || courseDetails?.bought || curationVisibleDetailsLabs.free) && 'not-allowed' }}
                                  />
                                }

                              </div>


                              <div className='selectmoduletype d-flex' style={{ justifyContent: "space-between", borderColor: mcq ? '#6941C6' : '', background: (courseDetails?.bought || curationVisibleDetailsMcq.free) && '#f2f2f2' }} >
                                <div><span className='selectedmodule_icon'>{CUSTOMIZE_MCQ_ASSE_ICN}</span>
                                  <label for="mcq" style={{ color: mcq ? '#6941C6' : '' }}>{!curationVisibleDetailsMcq.visible ? 'No' : curationVisibleDetailsMcq?.originalContentCount} MCQ Assessment</label></div>
                                {
                                  (curationVisibleDetailsMcq?.visible) &&
                                  <input id="mcq" type="checkbox" name="mcq" checked={mcq}
                                    onChange={(e) => {
                                      if ('user' in localStorage === false) {
                                        setCustomizeCourse(true);
                                      }
                                      else {
                                        setMcq(e.target.checked);
                                        setSelectCurateAny(true);
                                      }
                                    }}
                                    disabled={(modulesSelected === 1 && mcq) || courseDetails?.bought || curationVisibleDetailsMcq.free} style={{ cursor: ((modulesSelected === 1 && mcq) || courseDetails?.bought || curationVisibleDetailsMcq.free) && 'not-allowed' }}
                                  />
                                }
                              </div>
                              <div className='selectmoduletype d-flex' style={{ justifyContent: "space-between", borderColor: coding ? '#6941C6' : '', background: (courseDetails?.bought || curationVisibleDetailsCoding.free) && '#f2f2f2' }}>
                                <div><span className='selectedmodule_icon'>{CUSTOMIZE_CODING_ASS_ICN}</span>
                                  <label for="coding" style={{ borderColor: coding ? '#6941C6' : '' }}>{!curationVisibleDetailsCoding.visible ? 'No' : curationVisibleDetailsCoding?.originalContentCount} Coding Assessment</label></div>

                                {
                                  (curationVisibleDetailsCoding?.visible) &&
                                  <input id="coding" type="checkbox" name="coding" checked={coding}
                                    onChange={(e) => {
                                      if ('user' in localStorage === false) {
                                        setCustomizeCourse(true);
                                      }
                                      else {
                                        setCoding(e.target.checked);
                                        setSelectCurateAny(true);
                                      }

                                    }}
                                    disabled={(modulesSelected === 1 && coding) || courseDetails?.bought || curationVisibleDetailsCoding.free} style={{ cursor: ((modulesSelected === 1 && coding) || courseDetails?.bought || curationVisibleDetailsCoding.free) && 'not-allowed' }}
                                  />
                                }
                              </div>
                              <div className='selectmoduletype d-flex' style={{ justifyContent: "space-between", borderColor: document ? '#6941C6' : '', background: (courseDetails?.bought || curationVisibleDetailsDocument.free) && '#f2f2f2' }}>
                                <div><span className='selectedmodule_icon'>{CUSTOMIZE_DOCUMENT_ICN}</span>
                                  <label for="document" style={{ borderColor: document ? '#6941C6' : '' }}>{!curationVisibleDetailsDocument.visible ? 'No' : curationVisibleDetailsDocument?.originalContentCount} Document</label></div>
                                {
                                  (curationVisibleDetailsDocument?.visible) &&
                                  <input id="document" type="checkbox" name="document" checked={document}
                                    onChange={(e) => {
                                      if ('user' in localStorage === false) {
                                        setCustomizeCourse(true);
                                      }
                                      else {
                                        setDocument(e.target.checked);
                                        setSelectCurateAny(true);
                                      }
                                    }}
                                    disabled={(modulesSelected === 1 && document) || courseDetails?.bought || curationVisibleDetailsDocument.free} style={{ cursor: ((modulesSelected === 1 && document) || courseDetails?.bought || curationVisibleDetailsDocument.free) && 'not-allowed' }}
                                  />
                                }
                              </div>
                              <div className='selectmoduletype d-flex' style={{ justifyContent: "space-between", borderColor: videos ? '#6941C6' : '', background: (courseDetails?.bought || curationVisibleDetailsVideos.free) && '#f2f2f2' }}>
                                <div><span className='selectedmodule_icon'>{CUSTOMIZE_VIDEO_ICN}</span>
                                  <label for="videos" style={{ borderColor: videos ? '#6941C6' : '' }}>{!curationVisibleDetailsVideos.visible ? 'No' : curationVisibleDetailsVideos?.originalContentCount} Videos</label></div>
                                {
                                  (curationVisibleDetailsVideos?.visible) &&

                                  <input id="videos" type="checkbox" name="videos"
                                    checked={videos}
                                    onChange={(e) => {
                                      if ('user' in localStorage === false) {
                                        setCustomizeCourse(true);
                                      }
                                      else {
                                        setVideos(e.target.checked);
                                        setSelectCurateAny(true);
                                      }
                                    }}
                                    disabled={(modulesSelected === 1 && videos) || courseDetails?.bought || curationVisibleDetailsVideos.free} style={{ cursor: ((modulesSelected === 1 && videos) || courseDetails?.bought || curationVisibleDetailsVideos.free) && 'not-allowed' }}
                                  />
                                }
                              </div>

                              <div className='mt-4 '>


                                <div
                                  className='course-selected' style={{ alignItems: "center" }}>{modulesSelected} Artefact selected {courseDetails?.bought && <span><InfoIcon data-tooltip-id="BoughtCourse" /></span>}</div>


                                <div className='course-selected mt-1'>{courseDetails?.bought ? 'You bought this course for' : 'Your estimated course price'} : <span className='mx-2'>{currency === 'INR' ? '₹' : '$'}{loading ?
                                  <SkeletonLoader skeletonCount={1} />
                                  : currency === 'INR' ?
                                    Math.floor(courseDetails.price)
                                    :
                                    Math.floor(courseDetails.internationalCoursePrice)}</span> </div>
                              </div>
                              {courseDetails?.bought &&
                                <Tooltip id="BoughtCourse">
                                  <div className='course-card-content'>
                                    <ul>
                                      {
                                        liveSessions &&
                                        <li>Live Sessions</li>
                                      }
                                      {
                                        labs &&
                                        <li>Labs</li>
                                      }
                                      {
                                        mcq &&
                                        <li>MCQ Assessment</li>
                                      }
                                      {
                                        coding &&
                                        <li>Coding Assessment</li>
                                      }
                                      {
                                        document &&
                                        <li>Documents</li>
                                      }
                                      {
                                        videos &&
                                        <li>Videos</li>
                                      }

                                    </ul>
                                  </div>
                                </Tooltip>
                              }
                            </div>

                          </>

                      }
                    </>
                  }

                  {
                    loading ?
                      <SkeletonLoader skeletonCount={1} />
                      :
                      courseDetails?.bought ?
                        <button class="addtocart" onClick={proceedToTraining}>Proceed to Course</button>
                        :
                        // courseDetails?.bought && currentDate < courseDetails.trainingStartDate?.substring(0, 10) ?
                        //   <>
                        //     <button class="addtocart mt-4" disabled style={{ cursor: "not-allowed" }}>Proceed to Course</button>
                        //     <div className='course-card-content'>
                        //       <ul>
                        //         <li>
                        //           <span className='mx-2'>{CALENDAR_ICON}</span> Starts on {courseDetails.trainingStartDate?.substring(0, 10)}
                        //         </li>
                        //       </ul>
                        //     </div>
                        //   </>
                        //   :
                        courseDetails?.bought === false && currentDate > courseDetails.trainingStartDate?.substring(0, 10) ?
                          <button class="addtocart" onClick={() => {
                            if ('user' in localStorage === false) {
                              setNotification(true);

                            }
                            else {
                              //api call
                              notifyMe();
                            }
                          }}>{notify ? (
                            <>
                              <Spinner
                                as="span"
                                animation="border"
                                size="md"
                                role="status"
                                aria-hidden="true"
                                className="mr-2" // Add margin to separate spinner from text
                                style={{ border: '5px dotted #fff' }}
                              />
                              {' '}
                              Loading...
                            </>
                          ) : 'Nominations Closed | Notify'}</button>
                          :
                          courseDetails?.bought === false && currentDate <= courseDetails.trainingStartDate?.substring(0, 10) && cartResponse !== null && cartResponse.cartDetails?.filter(item => item.courseName.includes(courseDetails.courseName)).length > 0 ?
                            <div className=' mt-1'>

                              <button class="addtocart" onClick={() => { setShowCart(true); displayCart(); }}>Proceed To Payment</button>
                              <button className='btn-cancle mt-3' style={{ opacity: selectReset ? 0.6 : 1 }} onClick={() => resetCustomizeButton()} disabled={selectReset}>Reset</button>

                            </div>
                            :
                            courseDetails?.bought === false && currentDate <= courseDetails.trainingStartDate?.substring(0, 10) ?
                              <div className='mt-1'>

                                <button class="addtocart" onClick={() => handleAddToCart()}>Customize and Add to cart</button>
                                <button className='btn-cancle mt-3' style={{ opacity: selectReset ? 0.6 : 1 }} onClick={() => resetCustomizeButton()} disabled={selectReset}>Reset</button>



                              </div>
                              :
                              <div className='mt-1 '>

                                <button class="addtocart" onClick={() => handleAddToCart()}>Customize and Add to cart</button>
                                <button className='btn-cancle mt-3' style={{ opacity: selectReset ? 0.6 : 1 }} onClick={() => resetCustomizeButton()} disabled={selectReset}>Reset</button>



                              </div>

                  }

                  {/* customize course mobile end*/}
                  {/* <BsModal {...{ show: showCart, setShow: setShowCart, size: "md" }} /> */}

                </div>








              </div>
              {/* <button className='addtocart' >Add to cart</button> */}

            </div>
            <div>

            </div>



          </div>
        </div>

        {/* bottom navigation */}
        <nav class="mobile-bottom-navigation">
          {/* <a href="#" class="mobile__link">
 
    <span class="nav__text">Dashboard</span>
  </a>
  <a href="#" class="mobile__link ">
  
    <span class="nav__text">Profile</span>
  </a> */}

          <div className='container'>
            <div className=' mobile__link '>
              <div className="course-card-h mx-5  "> {currency === 'INR' ? '₹' : '$'}{loading ?
                <SkeletonLoader skeletonCount={1} />
                : currency === 'INR' ?
                  Math.floor(courseDetails.price)
                  :
                  Math.floor(courseDetails.internationalCoursePrice)} </div>
              {
                loading ?
                  <SkeletonLoader skeletonCount={1} />
                  :
                  courseDetails?.bought ?
                    <button class="addtocart my-3 " onClick={proceedToTraining}>Proceed to Course</button>
                    :
                    // courseDetails?.bought && currentDate < courseDetails.trainingStartDate?.substring(0, 10) ?
                    //   <button class="addtocart my-3 " style={{ cursor: "not-allowed" }}>Proceed to Course</button>
                    //   :
                    courseDetails?.bought === false && currentDate > courseDetails.trainingStartDate?.substring(0, 10) ?
                      <button class="addtocart my-3" onClick={() => {
                        if ('user' in localStorage === false) {
                          setNotification(true);

                        }
                        else {
                          //api call
                          notifyMe();
                        }
                      }}>{notify ? (
                        <>
                          <Spinner
                            as="span"
                            animation="border"
                            size="md"
                            role="status"
                            aria-hidden="true"
                            className="mr-2" // Add margin to separate spinner from text
                            style={{ border: '5px dotted #fff' }}
                          />
                          {' '}
                          Loading...
                        </>
                      ) : 'Nominations Closed | Notify'}</button>
                      :
                      courseDetails?.bought === false && currentDate <= courseDetails.trainingStartDate?.substring(0, 10) && cartResponse !== null && cartResponse.cartDetails?.filter(item => item.courseName.includes(courseDetails.courseName)).length > 0 ?
                        <button class="addtocart my-3" onClick={() => { setShowCart(true); displayCart(); }}>Proceed To Payment</button>
                        :
                        courseDetails?.bought === false && currentDate <= courseDetails.trainingStartDate?.substring(0, 10) ?
                          <button class="addtocart my-3" onClick={() => handleAddToCart()}>Buy now</button>
                          :
                          <button class="addtocart my-3" onClick={() => handleAddToCart()}>Buy now</button>
              }
            </div>

          </div>
          {/* <BsModal {...{ show: showCart, setShow: setShowCart, size: "md" }} /> */}


        </nav>

      </div >






      <div className='row container'>
        <div className='col-lg-8 col-md-8 col-sm-12 '>

          <div className='learningpt p-5'>
            <AnimationWrapper animationType="heading">  <div className='learningpt-header'>What you'll learn</div></AnimationWrapper>




            <div className='row'>
              {/* <div className='col-lg-6 col-md-6col-sm-12'> */}
              <div className='sectionpoint mt-3 mb-3'>
                {loading ?
                  <SkeletonLoader skeletonCount={10} />
                  : parse(courseDetails.whatWeWillLearn)}

              </div>



            </div>
          </div>

        </div>
      </div>



      {/* course include */}

      <div className='course-section'>
        <div className='row container'>
          <div className='col-xl-8 col-lg-8 col-md-8 col-sm-12'>
            <AnimationWrapper animationType="heading">
              <div className='d-flex' style={{ justifyContent: "space-between" }}>
                <div className='learningpt-header mb-5'>This course includes:</div>
                {loading ?
                  <SkeletonLoader skeletonCount={1} />
                  :
                  <div > <button className='btn-cancle' onClick={toggleAll}>{areAllOpen ? <>Collapse<span>{ICN_COLLAPSED}</span></> : <>Expand <span>{ICN_EXPAND_ACCORDIAN}</span></>}</button></div>
                }
              </div>

            </AnimationWrapper>

            {loading ?
              <SkeletonLoader skeletonCount={10} />
              :
              <div className='filter-sidebar-main' style={{ boxShadow: " 0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.10)", border: "1px solid #EAECF0", borderRadius: "8px" }}>
                {
                  contentDetails.map((content, index) => {
                    return (
                      // <DropdownItem title={content.sectionName} total={`${content.courseContentResposeTOList.length} Contents`} theme="dark">
                      <DropdownItem
                        key={index}
                        isOpen={openStates[index]}
                        toggleDropdown={() => toggleDropdown(index)}
                        title={content.sectionName}
                        total={`${content.courseContentResposeTOList.length} Contents`}
                        theme="dark"
                      >
                        {/* <button className="title-sm" onClick={() => { setType('SECTION'); setSectionObjective(train.sectionObjective) }}>Objective</button> */}

                        {content.courseContentResposeTOList.map((content, index) => {
                          return (
                            <div onClick={() => content.unlocked && showUnLockedContent(content.type, content.contentLink, content.contentName)} className='filter-sidebar-submenu justify-content-between align-items-center d-flex'
                              style={{
                                // borderBottom: "1px solid var(--Gray-200, #EAECF0)",
                                background: "#F9F5FF",
                                padding: "16px 32px",
                                cursor: !content.unlocked && "text"
                              }}>
                              {
                                content.unlocked ?
                                  <div className='d-flex justify-content-center'>
                                    <div className='acrdiancontent pr-3'>{content.slNo}.</div>
                                    <div className='acrdiancontent px-2' >
                                      {content.contentName}</div>
                                  </div>
                                  :
                                  <div className='d-flex justify-content-center'>
                                    <div className='acrdiancontent pr-3'>{content.slNo}.</div>
                                    <div className='acrdiancontent px-2'> {content.contentName} {IMG_PADLOCK}</div>
                                  </div>
                              }




                              <div data-tooltip-id="AssessmentDownload" data-tooltip-content={content.type === "ASSESSMENT" ? "MCQ Assessment" : content.type === "TRAINING_SESSION" ? "Meeting" :
                                content.type === 'DOCUMENTS' ? "Document" :
                                  content.type === "LAB" && content.labContent.evaluatedLab ? "Assessment Lab" :
                                    content.type === "LAB" && !content.labContent.evaluatedLab ?
                                      "Practice Lab" :
                                      (content.type === 'VIDEO' || content.type === 'EXTERNAL_LINK')
                                      && "Video"}>
                                {content.type === "ASSESSMENT" ? ICN_MCQ : content.type === "TRAINING_SESSION" ? ICN_STRAT_SESSION : content.type === 'DOCUMENTS' ? ICN_PDF : content.type === 'LAB' ? LAB : (content.type === 'VIDEO' || content.type === 'EXTERNAL_LINK')
                                  && ICN_VIDEO}
                                <Tooltip
                                  id="AssessmentDownload"
                                // data-tooltip-place="bottom"
                                // variant="info"
                                />

                              </div>
                            </div>
                          )
                        })

                        }
                      </DropdownItem>

                    )
                  })
                }

              </div>
            }
          </div>

        </div>

      </div>


      {/* Requirments */}

      <div className='course-section'>
        <div className='row container'>
          <div className='col-xl-8 col-lg-8 col-md-8 col-sm-12'>
            <AnimationWrapper animationType="heading">  <div className='learningpt-header'>Requirements</div></AnimationWrapper>

            <div className=' sectionpoint mt-3 mb-3'>

              {loading ?
                <SkeletonLoader skeletonCount={10} />
                : parse(courseDetails.requirements)}

            </div>
          </div>

        </div>

      </div>

      {/* Descriptions */}

      {/* EXPLORE MORE COURSE */}

      {top5Courses.length === 0 ? "" :
        <div className='container'>
          <AnimationWrapper animationType="heading">     <h3 className='feature-screen-header'>Learners also took</h3></AnimationWrapper>
          {/* <AnimationWrapper animationType="subHeading">    <div className='feature-screen-text'>
              one supporting text goes here, not more than one line.
            </div></AnimationWrapper>   */}

        </div>
      }

      {/* top 5 courses */}
      {
        top5Courses.length === 0 ?
          ""
          :
          <div className='container'>
            <div class="row mt-5 mb-5">

              {
                top5Courses.map((item) => {
                  return (
                    <div class="col-12 col-sm-12 col-md-6 col-lg-4 mb-3">
                      <div class="card py-1 px-5 h-100 ">

                        {/* {item.headerimg} */}
                        <AnimationWrapper animationType="card">
                          <div class="card-body d-flex flex-column">

                            <h4 class="card-title mt-4">{loadingTop5 ?
                              <SkeletonLoader skeletonCount={1} />
                              : item.courseName}</h4>
                            <div className='d-flex flex-wrap pt-3 pb-3 gap-2'>
                              {/* <button className='card-top-btn  flex-grow-1 mb-2 mr-1'>{loadingTop5 ?
                          <SkeletonLoader skeletonCount={1} />
                          : item.categoryName}</button> */}
                              {/* <button className='card-top-btn  flex-grow-1 mb-2 mr-1'>{loadingTop5 ?
                          <SkeletonLoader skeletonCount={1} />
                          : item.subCategoryName}</button> */}
                              {
                                loadingTop5 ?
                                  <SkeletonLoader skeletonCount={5} />
                                  :
                                  item.tags.map((tag) => {
                                    return (
                                      <button className='card-top-btn  flex-grow-1 mb-2 mr-1'>{tag}</button>
                                    )
                                  })
                              }

                            </div>
                            <div className='d-flex gap-5 mb-4 mt-4'>
                              <div className='total card-subtitle'><span className='mx-2'>{ICN_TOTAL_LECTURE}</span><span className='mt-5'>{loadingTop5 ?
                                <SkeletonLoader skeletonCount={1} />
                                : item.totalContent} Lectures</span></div>
                              <div className='total card-subtitle'> <span className='mx-2'>{ICN_TOTAL_TIME}</span> <span className='mt-5'>{loadingTop5 ?
                                <SkeletonLoader skeletonCount={1} />
                                : item.totalDuration} Total Hours</span></div>
                            </div>
                            <p class="card-text gap-2"><span >{ICN_LANGUAGE}</span>{loadingTop5 ?
                              <SkeletonLoader skeletonCount={1} />
                              : item.languages.toString()} </p>
                            <div className="course-card-h"> {currency === 'INR' ? '₹' : '$'}{loadingTop5 ?
                              <SkeletonLoader skeletonCount={1} />
                              :
                              currency === 'INR' ?
                                Math.floor(item.price)
                                :
                                Math.floor(item.internationalCoursePrice)
                            } </div>

                            <Link to={`/course/${item.courseSid}`}
                              onClick={() => { setTop5Route(true); setLoading(true); setLoadingTop5(true); }}

                              class="card-view-course mt-auto">{loadingTop5 ?
                                <SkeletonLoader skeletonCount={1} />
                                : "View Course"} {ICN_NEXT}</Link>

                          </div></AnimationWrapper>
                      </div>
                    </div>
                  )
                })
              }


            </div>
          </div>
      }

      {/* still have a question  */}
      <div className='layout'>
        <div className='container '>
          <div className='row hv-question-container'>
            <div className='col-lg-9 col-md-9 col-sm-12'>
              <h4 className='hv-question-title'>{loading ?
                <SkeletonLoader skeletonCount={1} />
                : "Still have questions?"}</h4>
              <p className='hv-question-subtitile'>{loading ?
                <SkeletonLoader skeletonCount={1} />
                : "Can’t find the answer you’re looking for? Please get in touch with our friendly team."}</p>
            </div>
            <div className='col-lg-3 col-md-3 col-sm-12'>
              {loadingTop5 ?
                <SkeletonLoader skeletonCount={1} />
                :
                // <button class="library-button" style={{ float: "inline-end" }}>Get In Touch</button>
                <Link to="/contactus" class=" btn_getintouch" style={{ float: "inline-end" }}>Get in Touch</Link>
              }
            </div>

          </div>

        </div>
      </div>
      {
        show &&
        <AllModal show={show} setShow={setShow} headerTitle={contentTitle}>
          <>
            {type === 'EXTERNAL_LINK' || type === 'VIDEOS' ? (
              <ReactPlayer
                url={contentLink}
                className='react-player'
                width="100%"
                height="500px"
                config={{
                  file: {
                    attributes: {
                      controlsList: 'nodownload'
                    }
                  }
                }}
                playing={true}
                muted={true}
                controls
                light
                style={{ borderTopLeftRadius: "12px", borderTopRightRadius: "12px" }}
              />
            ) : type === 'DOCUMENTS' ? (
              <iframe src={`${contentLink}#toolbar=0`} width="100%" height="600px" title="Document" />
            ) : (
              // Render any other content based on your needs
              ''
            )}
          </>
        </AllModal>


      }
      {
        showSignUp &&
        <AllModal show={showSignUp} setShow={setShowSignUp} headerTitle="" >
          <>
            <SignupForCart setShowSignUp={setShowSignUp} addToCart={addToCart} />
          </>
        </AllModal>

      }
      {
        customizeCourse &&
        <AllModal show={customizeCourse} setShow={setCustomizeCourse} headerTitle="" >
          <>
            <SignupForCart setCustomizeCourse={setCustomizeCourse} />
          </>
        </AllModal>
      }
      {
        notification &&
        <AllModal show={notification} setShow={setNotification} headerTitle="">
          <>
            <NotificationModal />
          </>
        </AllModal>
      }

    </>
    //   }
    // </>
  )
}

export default SpecificCourse