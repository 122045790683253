import React, { useEffect, useState, useRef } from 'react'
import { ICN_CHROME, ICN_HEADER_LOGO1, ICN_LOGO } from '../../Components/Common/Icon'
import "./Auth.css";
import { Link } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import RestService from '../../Services/api.service';
import ToastMessage from '../../Store/ToastHook';
import { ToastContainer } from 'react-toastify';
import CryptoJS from 'crypto-js';
import { Navigate } from 'react-router-dom';

const ForgotPassword = ({ isLoggedIn }) => {
  const secretKey = process.env.REACT_APP_SECRET;

  const [resetpassword, setResetpassword] = useState(true)
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const hasCheckedLink = useRef(false);
  const [valid, setValid] = useState(false);


  let { uuid, timestamp } = useParams();

  const handlePasswordChange = (e) => {
    setNewPassword(e.target.value);
  };

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Validation
    if (newPassword.length < 8) {
      setPasswordError('Password must be at least 8 characters long');
      setShowErrorMessage(true);
    } else if (newPassword !== confirmPassword) {
      setPasswordError('Passwords do not match');
      setShowErrorMessage(true);
    } else {
      // Passwords are valid, you can proceed with form submission or further actions
      // For now, just clear the error message and hide it
      // setPasswordError('');
      // setShowErrorMessage(false);
      changeForgotPassword();
      // Perform additional actions like form submission
    }
  };

  //change forgot password
  const changeForgotPassword = () => {
    try {
      // spinner.show();
      const confirmPasswordEncrypt = CryptoJS.AES.encrypt(confirmPassword, secretKey).toString();
      // const newPasswordEncrypt = CryptoJS.AES.encrypt(newPassword, secretKey).toString();

      const payload = {
        "confirmPassword": confirmPasswordEncrypt,
        "newPassword": confirmPasswordEncrypt,
        "uuid": uuid
      }
      RestService.changeForgotPassword(payload).then(
        response => {
          if (response.status === 200) {
            ToastMessage({ type: "success", message: 'Password Updated Successfully', time: 1500 });
            setTimeout(() => {
              setResetpassword(false);
            }, 2000);

          }
        },
        err => {
          console.error(err);
          ToastMessage({ type: "error", message: err.response.data.message, time: 2500 });
        }
      )
    } catch (err) {
      ToastMessage({ type: "error", message: 'Try Again' });
      console.error("error occur on changeForgotPassword()", err)
    }
  }

  const checkValidResetLink = () => {

    const decodedTimestamp = atob(timestamp);
    const receivedTimestamp = parseInt(decodedTimestamp, 10);
    const currentTimestamp = Date.now();
    const difference = 15 * 60 * 1000;
    const timeDifference = currentTimestamp - receivedTimestamp;

    if (parseInt(timeDifference) <= parseInt(difference)) {
      setValid(false);
    } else {
      setValid(true);
      ToastMessage({ type: "error", message: 'Reset Password Link Expired', time: 2000 });
      return;

    }

  }


  useEffect(() => {
    if (!hasCheckedLink.current) {
      checkValidResetLink();
      hasCheckedLink.current = true;
    }
  }, []);

  //if login and user tries to login again, redirect to my enrolled courses
  if (isLoggedIn) {
    return <Navigate to="/mycourses" replace />;
  }





  return (
    <>
      <ToastContainer />
      {
        resetpassword ?
          <div className="signup-form mt-5">
            <form onSubmit={handleSubmit}>
              <div className="text-center">
                {/* {ICN_LOGO} */}
              </div>
              <h2 className="pt-4">Create a New Password</h2>
              <p className="pt-3">Create a new password for your account. Make sure it's strong and secure.</p>

              <div className="form-group">
                <label>New Password</label>
                <div className="input-group">
                  <input
                    type="password"
                    className="form-control"
                    name="newPassword"
                    placeholder="New Password"
                    value={newPassword}
                    onChange={handlePasswordChange}
                    required
                    disabled={valid}
                  />
                </div>
              </div>

              <div className="form-group">
                <label>Confirm Password</label>
                <div className="input-group">
                  <input
                    type="password"
                    className="form-control"
                    name="confirmPassword"
                    placeholder="Confirm Password"
                    value={confirmPassword}
                    onChange={handleConfirmPasswordChange}
                    required
                    disabled={valid}
                  />
                </div>
              </div>

              {passwordError && <p className="text-danger">{passwordError}</p>}

              <div className="form-group">
                <button type="submit" className="btn btn-primary btn-lg" disabled={valid}>
                  Reset Password
                </button>
              </div>
            </form>
          </div>
          :
          <div class="signup-form">
            <form action="/examples/actions/confirmation.php" method="post">
              <div className='text-center'>
                {ICN_LOGO}
              </div>
              <h2 className='pt-4'>Password Successfully Reset</h2>
              <p className='pt-3'>Congratulations! Your password has been successfully reset. Now, you can confidently log in with your new credentials.</p>
              {/* <hr/> */}




              <div class="form-group">
                <Link to="/login" type="submit" class="btn btn-primary btn-lg">Continue Learning</Link>
              </div>







            </form>

            {/* <div class="text-center alredyhv">Don’t have an account? <Link to="/signup">Sign up</Link></div> */}



          </div>
      }

    </>
  )
}

export default ForgotPassword