import React, { useEffect, useState } from 'react'
import SkeletonLoader from '../../../Components/Common/SkeletonLoader'
import "../Auth.css"
import { CALENDAR_ICON, ICN_NEXT, ICN_SEARCH, POPUP_CARD_ICON } from '../../../Components/Common/Icon';
import { Link } from 'react-router-dom';
import Select from "react-select";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import CircularProgress, {
  circularProgressClasses,
} from "@mui/material/CircularProgress";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import RestService from '../../../Services/api.service';
import moment from 'moment';

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 8,
  borderRadius: 4,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 4,
    backgroundColor: theme.palette.mode === "light" ? "#7F56D9" : "#EAECF0",
  },
}));

const MyCourses = () => {

  const [loader, setLoader] = useState(true);
  const [isPopupOpen, setPopupOpen] = useState(false);
  const [enrolledCourses, setEnrolledCourses] = useState([]);
  const [filteredCourses, setFilteredCourses] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  // const [selectedSortBy, setSelectedSortBy] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedProgress, setSelectedProgress] = useState(null);
  const [categories, setCategories] = useState([]);
  const [currentDate, setCurrentDate] = useState('');

  let user = localStorage.getItem('user')
    ? JSON.parse(localStorage.getItem('user'))
    : '';

  const togglePopup = () => {
    setPopupOpen(!isPopupOpen);
  };


  //proceed to course
  const proceedToTraining = (courseSid) => {
    const consumerSid = user?.sid;
    // Construct the URL
    const url = `https://lxp.learnlytica.com/?njnjknkenenkjandkjeneakeajkekaned=${courseSid}&uhugttdjnjnjndaasksske3ninonoinioqdoiodiendeoin=${consumerSid}`;

    // Open a new tab with the specified URL
    window.location.href = url;
    // window.open(url, '_blank');

  }

  const filterCourses = () => {
    if (searchTerm.trim() === '') {
      setFilteredCourses(enrolledCourses); // Show all courses if no search term
    } else {
      const filtered = enrolledCourses.filter(course =>
        course.courseName.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setFilteredCourses(filtered);
    }
  };

  const handleInputChange = (e) => {
    setSearchTerm(e.target.value);
    filterCourses(); // Filter courses on every input change
  };

  //get user enrolled courses;
  const getUserEnrolledCourses = () => {
    try {
      // spinner.show();
      // const subCategorySid = subCategorySid;
      const consumerSid = user?.sid;
      RestService.getUserEnrolledCourses(consumerSid).then(
        response => {
          setEnrolledCourses(response.data);
          setFilteredCourses(response.data);
        },
        err => {
          console.error(err);
        }
      )
    } catch (err) {
      console.error("error occur on getUserEnrolledCourses()", err)
    }
  }

  const getUserEnrolledCoursesWithFilter = () => {
    try {
      // spinner.show();
      // const subCategorySid = subCategorySid;
      let payload = {};

      //sort
      // if (selectedSortBy !== null) {
      //   if (selectedSortBy.label === 'Price- High to low') {
      //     payload.priceFilter = 1;
      //   }
      //   else if (selectedSortBy.label === 'Price-Low to high') {
      //     payload.priceFilter = -1;
      //   }
      // }
      // else {
      //   payload.priceFilter = 0;
      // }

      //category
      if (selectedCategory !== null) {
        payload.categorySid = selectedCategory.value;
      }
      else {
        payload.categorySid = null;
      }

      //progress
      if (selectedProgress !== null) {
        payload.progressRange = selectedProgress.value;
      }
      else {
        payload.progressRange = null;
      }
      // const payload = {
      //   "categorySid": null,
      //   "priceFilter": 0,
      //   "progressRange": "COMPLETED",
      //   "ratingFilter": 0
      // }
      const consumerSid = user?.sid;
      RestService.getUserEnrolledCoursesWithFilter(consumerSid, payload).then(
        response => {
          setEnrolledCourses(response.data);
          setFilteredCourses(response.data);
        },
        err => {
          console.error(err);
        }
      )
    } catch (err) {
      console.error("error occur on getUserEnrolledCoursesWithFilter()", err)
    }
  }

  //get all categorises
  const getAllCategoriesAndSubCategories = () => {
    try {
      // spinner.show();
      // const subCategorySid = subCategorySid;
      RestService.getAllCategoriesAndSubCategories().then(
        response => {
          const newCategory = response.data.map(category => ({
            value: category.categorySid,
            label: category.categoryName
          }))
          setCategories(newCategory);
        },
        err => {
          console.error(err);
        }
      )
    } catch (err) {
      console.error("error occur on getAllCategoriesAndSubCategories()", err)
    }
  }

  const sortby = [
    { value: 'Newest', label: 'Newest' },
    { value: 'Price- Low to high', label: 'Price-Low to high' },
    { value: 'Price- High to low', label: 'Price- High to low' }

  ]
  const data = [
    { value: 'Cloudflare', label: 'Cloudflare', color: '#00B8D9', isFixed: true },
    { value: 'Google cloud', label: 'Google cloud', color: '#0052CC', isDisabled: true },
    { value: 'Beginning openstack', label: 'Beginning openstack', color: '#5243AA' },
    { value: 'Terraform', label: 'Terraform', color: '#FF5630', isFixed: true },
    { value: 'Introduction to cloud computing for managers', label: 'Introduction to cloud computing for managers', color: '#FF8B00' },
    { value: 'Vmware', label: 'Vmware', color: '#FFC400' },
    { value: 'Amazon web services(aws)', label: 'Amazon web services(aws)', color: '#36B37E' },
    { value: 'Azure', label: 'Azure', color: '#00875A' },
    { value: 'Cloud management_trending', label: 'Cloud management_trending', color: '#253858' },

  ];

  const progress = [
    { value: 'NOT_STARTED', label: 'Not Started', color: '#00B8D9' },
    { value: 'IN_PROGRESS', label: 'In Progress', color: '#00B8D9' },
    { value: 'COMPLETED', label: 'Completed', color: '#00B8D9' },
  ];

  useEffect(() => {
    // getUserEnrolledCourses();
    const formattedDate = moment().format('YYYY-MM-DD');

    // Set the formatted date in the state
    setCurrentDate(formattedDate);
    getAllCategoriesAndSubCategories();
    setTimeout(() => {
      setLoader(false);
    }, [1000]);
  }, []);

  useEffect(() => {
    // if (selectedSortBy !== null || selectedCategory !== null || selectedProgress !== null) {
    getUserEnrolledCoursesWithFilter();
    // }

  }, [selectedCategory, selectedProgress]);


  return (
    <>
      <div className='mycourse-header' >
        <div className='container'>
          <div className='row'>
            <div className='col-lg-7 col-md-7 col-sm-12 mt-5 mb-5'>

              {
                loader ?
                  <SkeletonLoader skeletonCount={3} />
                  :
                  <div className='mycourse-title ' >
                    My Enrolled Courses


                  </div>
              }
              {
                loader ?
                  <SkeletonLoader skeletonCount={2.5} />
                  :
                  <div className='mycourse-subtitle pt-5'>
                    Your Courses, Your Way. Dive into your enrolled courses and chart your learning journey effortlessly.
                  </div>
              }

            </div>



          </div>

        </div>
      </div>
      {/* filter */}
      <div className='container'>
        <div className='row mb-5'>

          {/* <div className='col-lg-3 col-md-3 col-sm-6 mt-5 '>
            <div class="form-group ">
              <label className='label-class pb-1'>Sort by</label>


              {
                loader ?
                  <SkeletonLoader skeletonCount={0.5} />
                  :
                  <Select
                    defaultValue="Select"
                    name="colors"
                    options={sortby}
                    value={selectedSortBy}
                    isClearable={true}
                    onChange={setSelectedSortBy}
                    className="basic-multi-select "
                    classNamePrefix="select"

                  />
              }


            </div>


          </div> */}


          <div className='col-lg-4 col-md-4 col-sm-6 mt-5'>
            <div class="form-group ">
              <label className='label-class pb-1'>Filter by Categories</label>
              {
                loader ?
                  <SkeletonLoader skeletonCount={0.5} />
                  :
                  <Select
                    defaultValue="Categories"
                    name="colors"
                    options={categories}
                    value={selectedCategory}
                    isClearable={true}
                    onChange={setSelectedCategory}
                    className="basic-multi-select "
                    classNamePrefix="Categories"

                  />
              }
            </div>


          </div>



          <div className='col-lg-4 col-md-4 col-sm-6 mt-5'>
            <div class="form-group ">
              <label className='label-class pb-1'>Filter by Progress</label>
              {
                loader ?
                  <SkeletonLoader skeletonCount={0.5} />
                  :
                  <Select
                    defaultValue="In progress"
                    name="colors"
                    options={progress}
                    value={selectedProgress}
                    isClearable={true}
                    onChange={setSelectedProgress}
                    className="basic-multi-select "
                    classNamePrefix="In progress"

                  />
              }
            </div>


          </div>


          <div className='col-lg-4 col-md-4 col-sm-6 mt-5  mb-5'>
            <div class="form-group" style={{ marginTop: "25px" }}>
              <div class="d-flex ">
                {
                  loader ?
                    <SkeletonLoader skeletonCount={0.5} />
                    :
                    <div class="input-group ">
                      <input
                        type="text"
                        className='form-control filter-input applycoupn'
                        placeholder="Search Courses"
                        style={{ height: "40px" }}
                        onChange={handleInputChange}

                      />
                      <button class=" applycoupn-btn" style={{ height: "40px" }} type="button" id="button-addon1" data-mdb-ripple-color="dark">
                        {ICN_SEARCH}
                      </button>
                    </div>
                }
              </div>
            </div>


          </div>




        </div>

      </div>


      {/* card */}
      {
        loader ?
          <SkeletonLoader skeletonCount={0.5} />
          :
          filteredCourses.length === 0 ?
            <div className='layout'>
              <div className='container'>
                <div className='section-heading  ' style={{ margin: "auto" }}>No Courses Found</div>
              </div>
            </div>
            :
            <div className='container'>

              <div class="row mt-5 mb-5">

                {
                  filteredCourses.map((item) => {
                    return (
                      <div class="col-12 col-sm-12 col-md-6 col-lg-4 mb-5">
                        <div class="card p-3 h-100 position-relative ">
                          {/* <div class="position-absolute top-5 end-0 px-5" onClick={togglePopup}>

      {POPUP_CARD_ICON}
      </div> */}

                          {/* {isPopupOpen && (
          <div className="position-absolute  end-0 px-5" style={{top:"20%"}}>
            <div className=''>
             
              <div className=' popup-div d-flex p-3' style={{flexDirection:"column"}}>
                <div className='popupcontent'>Rate this Course</div>
                <div>Help and Support</div>
              </div>
            </div>
          </div>
        )} */}

                          {/* {item.headerimg} */}
                          <div class="card-body d-flex flex-column">

                            {
                              loader ?
                                <SkeletonLoader skeletonCount={0.5} />
                                :
                                <h4 class="new-card-title mt-4">
                                  {/* <SkeletonLoader skeletonCount={1} /> */}
                                  {item.courseName}</h4>
                            }

                            {
                              loader ?
                                <SkeletonLoader skeletonCount={0.4} />
                                :
                                item.courseProgress !== null &&
                                <>

                                  <Box sx={{ flexGrow: 1, padding: 1, width: "80%" }}>
                                    <BorderLinearProgress variant="determinate" value={item.courseProgress} />
                                  </Box>

                                  <div className='progress-value px-3'>{item.courseProgress} % complete</div>
                                </>
                            }
                            {
                              loader ?
                                <SkeletonLoader skeletonCount={0.3} />
                                :
                                <div className='course-card-content mt-5'>

                                {
                                  item?.courseType === "SELF_PACED" ? (
                                    ""
                                  ) : (
                                    <>
                                      <span className='m-2'>{CALENDAR_ICON}</span>
                                      {currentDate >= item?.trainingStartDate?.substring(0, 10) ? 'Expert Session Started' : 'Expert Session Starts'} on {item?.trainingStartDate?.substring(0, 10)}
                                    </>
                                  )
                                }
                                
                                </div>
                            }


                            {loader ?
                              <SkeletonLoader skeletonCount={0.3} />
                              :
                              // <Link to={`/course/${item.courseSid}`} class="card-view-course mt-auto">
                              //   View course {ICN_NEXT}</Link>
                              <button class="card-view-course mt-4" onClick={() => proceedToTraining(item.courseSid)} style={{
                                border: "none", background: "transparent"
                              }}
                              >View Course {ICN_NEXT}</button>
                            }


                            {/* {loader ?
                              <SkeletonLoader skeletonCount={0.3} />
                              :
                              <button onClick={() => proceedToTraining(item.courseSid)} class="addtocart">
                                View course {ICN_NEXT}</button>
                            } */}

                          </div>
                        </div>
                      </div>
                    )
                  })
                }

              </div>
            </div>
      }


    </>
  )
}

export default MyCourses