import React from "react";
import HashLoader from "react-spinners/HashLoader";

const override = {
  display: "block",
  margin: "0 auto", // Center the loader horizontally
  borderColor: "red", // You can customize the border color if needed
};

const centerContainerStyle = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: "100vh",
};

function LoadingSpinner({ loading }) {
  return (
    <div style={centerContainerStyle}>
      <div className="sweet-loading">
        <HashLoader
          size={150} // Adjust the size as needed
          color="#36d7b7"
          loading={loading}
          css={override} // Use 'css' instead of 'cssOverride'
          aria-label="Loading Spinner"
          data-testid="loader"
        />
      </div>
    </div>
  );
}

export default LoadingSpinner;
