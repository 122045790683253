import React from 'react';
import PropTypes from 'prop-types';

const VideoComponent = ({ source }) => {
  return (
    <video
      src={source}
      type="video/mp4" // Set the video type to MP4
      autoPlay
      loop
      muted
      playsInline
      style={{
        maxWidth: '100%',
        height: 'auto'
      }}
    />
  );
};

VideoComponent.propTypes = {
  source: PropTypes.string.isRequired
};

export default VideoComponent;
