import React, { useContext, useEffect, useRef, useState } from 'react';
import Select from "react-select";
import { UPLOAD_ICON } from '../../../../Components/Common/Icon';
import Avtar from "../../../../Assets/Images/Avatar.png";
import RestService from '../../../../Services/api.service';
import { ToastContainer } from 'react-bootstrap';
import ToastMessage from '../../../../Store/ToastHook';
import CartContext from '../../../../Store/CartContext';
import SkeletonLoader from '../../../../Components/Common/SkeletonLoader';

const AccountSettings = () => {

  let user = localStorage.getItem('user')
    ? JSON.parse(localStorage.getItem('user'))
    : '';

  const [activeTab, setActiveTab] = useState('marzen'); // Set the default active tab
  const [imagePreview, setImagePreview] = useState(null);
  const [file, setFile] = useState();
  const [firstName, setFirstName] = useState('');
  const [editFirstName, setEditFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [editLastName, setEditLastName] = useState('');
  const [email, setEmail] = useState('');
  const [officialEmail, setOfficialEmail] = useState('');
  const [editOfficialEmail, setEditOfficialEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [role, setRole] = useState('');
  const [editRole, setEditRole] = useState('');
  const [profilePic, setProfilePic] = useState('');
  const [country, setCountry] = useState(null);
  const [editCountry, setEditCountry] = useState(null);
  const { setConsumerDetails } = useContext(CartContext);
  const [newData, setNewData] = useState([]);
  const [loader, setLoader] = useState(true);

  const [fileTypeError, setFileTypeError] = useState('');

  const [emailError, setEmailError] = useState('');

  const handleTabChange = (tabId) => {
    setActiveTab(tabId);
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setFile(file);
    if (file) {
      const reader = new FileReader();

      reader.onloadend = () => {
        setImagePreview(reader.result);
      };

      reader.readAsDataURL(file);
    }
  };

  const handleEmailChange = (e) => {
    const value = e.target.value;

    setOfficialEmail(value);
    setEditOfficialEmail(value);

    // Validate email
    const isValidEmail = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/.test(value);
    setEmailError(isValidEmail ? '' : 'Invalid email address');
  };

  const handleChangeCountry = (e) => {
    setCountry(e);
    setEditCountry(e);
  }

  const handleFileChange = (e) => {
    const file = e.target.files[0];

    // setFile(file);
    if (file) {
      const allowedExtensions = ['png', 'jpg', 'jpeg'];
      const fileExtension = file.name.split('.').pop().toLowerCase();

      if (allowedExtensions.includes(fileExtension)) {
        setFile(file);
        setFileTypeError('');
      } else {
        setFile(null);
        setFileTypeError('Invalid file type. Please upload a image file.');
      }
      const reader = new FileReader();

      reader.onloadend = () => {
        setImagePreview(reader.result);
      };

      reader.readAsDataURL(file);
    }
  };



  const data = [
    { label: 'United States', value: 'United States' },
    { label: 'United Kingdom', value: 'United Kingdom' },
    { label: 'Canada', value: 'Canada' },
    { label: 'Germany', value: 'Germany' },
    { label: 'France', value: 'France' },
    { label: 'China', value: 'China' },
    { label: 'Japan', value: 'Japan' },
    { label: 'India', value: 'India' },
    { label: 'Brazil', value: 'Brazil' },
    { label: 'Australia', value: 'Australia' },
    // Add more countries as needed
  ];

  const inputRef = useRef(null);

  //get profile details;
  const getUploadProfilePicture = () => {
    try {
      // spinner.show();
      // const subCategorySid = subCategorySid;
      const consumerSid = user?.sid;
      RestService.getUploadProfilePicture(consumerSid).then(
        response => {
          setConsumerDetails(response.data);
          setNewData(data);
          setFirstName(response.data.firstName);
          setLastName(response.data.lastName);
          setEmail(response.data.email);
          setOfficialEmail(response.data.officialEmail);
          setPhoneNumber(response.data.phoneNumber);
          setProfilePic(response.data.profilePic);
          setRole(response.data.role);
          setCountry({ label: response.data.country, value: response.data.country });
          setTimeout(() => {
            setLoader(false);
          }, 2000);

        },
        err => {
          console.error(err);
        }
      )
    } catch (err) {
      console.error("error occur on getUploadProfilePicture()", err)
    }
  }

  //upload profile photo;
  const uploadProfilePicture = () => {
    try {
      // spinner.show();
      // const subCategorySid = subCategorySid;
      setLoader(true);
      const formData = new FormData();
      formData.append("profile_pic", file);

      const consumerSid = user?.sid;
      RestService.uploadProfilePicture(consumerSid, formData).then(
        response => {
          if (response.status === 200) {
            let storedObject = user;

            // Update the consumerProfileDetails
            storedObject.consumerProfileDetails = response.data;

            // Convert the updated object back to a string
            let updatedData = JSON.stringify(storedObject);

            // Save the updated object back into local storage
            localStorage.setItem('user', updatedData);
            setConsumerDetails(response.data);
            setNewData(data);
            setFirstName(response.data.firstName);
            setLastName(response.data.lastName);
            setEmail(response.data.email);
            setOfficialEmail(response.data.officialEmail);
            setPhoneNumber(response.data.phoneNumber);
            setProfilePic(response.data.profilePic);
            setRole(response.data.role);
            setCountry({ label: response.data.country, value: response.data.country });
            setImagePreview(null);
            ToastMessage({ type: "success", message: 'Profile Pic Uploaded Successfully' });
            setTimeout(() => {
              setLoader(false);
            }, 2000);
          }

        },
        err => {
          ToastMessage({ type: "warning", message: 'Image size too large' });
          console.error(err);
        }
      )
    } catch (err) {
      console.error("error occur on uploadProfilePicture()", err)
    }
  }

  //update profile details;
  const editConsumerProfile = () => {
    try {
      // spinner.show();
      // const subCategorySid = subCategorySid;
      setLoader(true);
      const consumerSid = user?.sid;
      let payload = {
        consumerSid: consumerSid
      };

      if (firstName === editFirstName) {
        payload.firstName = editFirstName;
      }
      if (lastName === editLastName) {
        payload.lastName = editLastName;
      }
      if (officialEmail === editOfficialEmail) {
        payload.officialEmail = editOfficialEmail;
      }
      if (role === editRole) {
        payload.role = editRole;
      }
      if (editCountry !== null) {
        payload.country = editCountry?.value;
      }
      RestService.editConsumerProfile(payload).then(
        response => {
          let storedObject = user;

          // Update the consumerProfileDetails
          storedObject.consumerProfileDetails = response.data;

          // Convert the updated object back to a string
          let updatedData = JSON.stringify(storedObject);

          // Save the updated object back into local storage
          localStorage.setItem('user', updatedData);
          setConsumerDetails(response.data);
          setNewData(data);
          setEditFirstName('');
          setEditLastName('');
          setEditOfficialEmail('')
          setEditRole('');
          setEditCountry(null);
          setFirstName(response.data.firstName);
          setLastName(response.data.lastName);
          setEmail(response.data.email);
          setOfficialEmail(response.data.officialEmail);
          setPhoneNumber(response.data.phoneNumber);
          setProfilePic(response.data.profilePic);
          setRole(response.data.role);
          setCountry({ label: response.data.country, value: response.data.country });
          ToastMessage({ type: "success", message: 'Profile Updated Successfully' });
          setTimeout(() => {
            setLoader(false);
          }, 2000);
        },
        err => {
          console.error(err);
        }
      )
    } catch (err) {
      console.error("error occur on getUploadProfilePicture()", err)
    }
  }


  useEffect(() => {

    setTimeout(() => {
      const inputElement = inputRef.current;

      // if (!inputElement) {
      //   // If the input element is not available, exit early
      //   return;
      // }

      const dropZoneElement = inputElement.closest(".drop-zone");

      // if (!dropZoneElement) {
      //   // If the drop zone element is not found, exit early
      //   return;
      // }

      const updateThumbnail = (file) => {
        let thumbnailElement = dropZoneElement.querySelector(".drop-zone__thumb");

        if (dropZoneElement.querySelector(".drop-zone__prompt")) {
          dropZoneElement.querySelector(".drop-zone__prompt").remove();
        }

        if (!thumbnailElement) {
          thumbnailElement = document.createElement("div");
          thumbnailElement.classList.add("drop-zone__thumb");
          dropZoneElement.appendChild(thumbnailElement);
        }

        thumbnailElement.dataset.label = file.name;

        if (file.type.startsWith("image/")) {
          const reader = new FileReader();
          reader.readAsDataURL(file);
          // reader.onload = () => {
          //   thumbnailElement.style.backgroundImage = `url('${reader.result}')`;
          // };
        }
        //    else {
        //     thumbnailElement.style.backgroundImage = null;
        //   }
      };

      const handleClick = () => {
        inputElement.click();
      };

      const handleChange = (e) => {
        if (inputElement.files.length) {
          updateThumbnail(inputElement.files[0]);
        }
      };

      const handleDragOver = (e) => {
        e.preventDefault();
        dropZoneElement.classList.add("drop-zone--over");
      };

      const handleDragLeave = () => {
        dropZoneElement.classList.remove("drop-zone--over");
      };

      const handleDrop = (e) => {
        e.preventDefault();

        if (e.dataTransfer.files.length) {
          const file = e.dataTransfer.files[0];
          if (file) {
            const allowedExtensions = ['png', 'jpg', 'jpeg'];
            const fileExtension = file.name.split('.').pop().toLowerCase();

            if (allowedExtensions.includes(fileExtension)) {
              setFile(file);
              setFileTypeError('');
            } else {
              setFile(null);
              setFileTypeError('Invalid file type. Please upload a image file.');
            }
            const reader = new FileReader();

            reader.onloadend = () => {
              setImagePreview(reader.result);
            };

            reader.readAsDataURL(file);
          }
        }

        dropZoneElement.classList.remove("drop-zone--over");
      };


      // Event listeners
      dropZoneElement.addEventListener("click", handleClick);
      inputElement.addEventListener("change", handleChange);
      dropZoneElement.addEventListener("dragover", handleDragOver);
      ["dragleave", "dragend"].forEach((type) => {
        dropZoneElement.addEventListener(type, handleDragLeave);
      });
      dropZoneElement.addEventListener("drop", handleDrop);

      // Cleanup event listeners on component unmount
      return () => {
        dropZoneElement.removeEventListener("click", handleClick);
        inputElement.removeEventListener("change", handleChange);
        dropZoneElement.removeEventListener("dragover", handleDragOver);
        ["dragleave", "dragend"].forEach((type) => {
          dropZoneElement.removeEventListener(type, handleDragLeave);
        });
        dropZoneElement.removeEventListener("drop", handleDrop);
      };

    }, 3000)

  }, []); // Empty dependency array ensures the effect runs only once

  useEffect(() => {
    getUploadProfilePicture();
  }, []);

  return (
    <>
      <ToastContainer />
      <div className='container' >
        <div className='accountsettings mt-5'>Account Settings</div>


        <div class="tabset mt-5 mx-0">

          <input
            type="radio"
            name="tabset"
            id="tab1"
            aria-controls="marzen"
            checked={activeTab === 'marzen'}
            onChange={() => handleTabChange('marzen')}
          />
          <label htmlFor="tab1" style={{ marginBottom: "0px" }}>My details</label>

          {/* <input
type="radio"
name="tabset"
id="tab2"
aria-controls="rauchbier"
checked={activeTab === 'rauchbier'}
onChange={() => handleTabChange('rauchbier')}
/>
<label htmlFor="tab2" style={{ marginBottom: "0px" }}>Account Security</label> */}

          <div class="tab-panels">
            <section id="marzen" className={`tab-panel mt-5 ${activeTab === 'marzen' ? 'active' : ''}`}>

              <div className='container'>
                <div className='mydetails'>Personal info</div>
                <div className='mydetails-subtitle'>Update your photo and personal details here.</div>

                {/* Name */}
                {
                  loader ? <SkeletonLoader skeletonCount={1} />
                    :

                    <div className='row mt-5 b-top' style={{ justifyContent: "space-between", alignItems: "center" }}>
                      <div className='col-md-4 col-sm-4 col-lg-4'>
                        <div className='details-label'>Name</div>
                      </div>

                      <div className='col-md-8 col-sm-8 col-lg-8 '>
                        <div className='row'>
                          <div class="form-group col-md-4 ">
                            {/* <label>First Name</label> */}
                            <div class="input-group input-grp-style">

                              <input type="text" class="form-control" name="firstName" value={firstName} onChange={(e) => {
                                setFirstName(e.target.value);
                                setEditFirstName(e.target.value);
                              }} placeholder="First Name" style={{ padding: "10px 14px" }} />
                            </div>
                          </div>
                          <div class="form-group col-md-4 ">
                            {/* <label>First Name</label> */}
                            <div class="input-group input-grp-style">

                              <input type="text" class="form-control" name="lastName" value={lastName} onChange={(e) => {
                                setLastName(e.target.value);
                                setEditLastName(e.target.value);
                              }} placeholder="Last Name" style={{ padding: "10px 14px" }} />
                            </div>
                          </div>
                        </div>

                      </div>

                    </div>
                }


                {/* email */}
                {
                  loader ? <SkeletonLoader skeletonCount={1} />
                    :
                    <div className='row mt-2 b-top' style={{ justifyContent: "space-between", alignItems: "center" }}>
                      <div className='col-md-4 col-sm-4 col-lg-4'>
                        <div className='details-label'>Email</div>
                      </div>

                      <div className='col-md-8 col-sm-8 col-lg-8 '>
                        <div className='row'>
                          <div class="form-group col-md-8 ">
                            {/* <label>First Name</label> */}
                            <div class="input-group input-grp-style">

                              <input type="text" class="form-control" name="email" value={email} placeholder="info@learnlytica.com" disabled style={{ padding: "10px 14px" }} />
                            </div>
                          </div>

                        </div>

                      </div>

                    </div>
                }

                {/* Official Optional */}
                {
                  loader ? <SkeletonLoader skeletonCount={1} />
                    :
                    <div className='row mt-2 b-top' style={{ justifyContent: "space-between", alignItems: "center" }}>
                      <div className='col-md-4 col-sm-4 col-lg-4'>
                        <div className='details-label'>Official Email</div>
                      </div>

                      <div className='col-md-8 col-sm-8 col-lg-8 '>
                        <div className='row'>
                          <div class="form-group col-md-8 ">
                            {/* <label>First Name</label> */}
                            <div class="input-group input-grp-style">

                              <input type="text" class="form-control" name="officialEmail" value={officialEmail}
                                onChange={handleEmailChange}
                                placeholder="info@learnlytica.com" style={{ padding: "10px 14px" }} />
                            </div>
                            {emailError && <p className="error-message">{emailError}</p>}
                          </div>

                        </div>

                      </div>

                    </div>
                }


                {/* Phone */}
                {
                  loader ? <SkeletonLoader skeletonCount={1} />
                    :
                    <div className='row mt-2 b-top' style={{ justifyContent: "space-between", alignItems: "center" }}>
                      <div className='col-md-4 col-sm-4 col-lg-4'>
                        <div className='details-label'>Phone</div>
                      </div>

                      <div className='col-md-8 col-sm-8 col-lg-8 '>
                        <div className='row'>
                          <div class="form-group col-md-8 ">
                            {/* <label>First Name</label> */}
                            <div class="input-group input-grp-style">

                              <input type="text" class="form-control" name="phoneNumber" value={phoneNumber} placeholder="Phone number" disabled style={{ padding: "10px 14px" }} />
                            </div>
                          </div>

                        </div>

                      </div>

                    </div>
                }


                {/* your Photo */}


                {
                  loader ? <SkeletonLoader skeletonCount={3.5} />
                    :
                    <div className='row mt-2 b-top' style={{ justifyContent: "space-between", alignItems: "center" }}>
                      <div className='col-md-4 col-sm-4 col-lg-4'>
                        <div className='details-label'>Your Photo</div>
                        <div className='mydetails-subtitle'>This will be displayed on your profile.</div>
                      </div>

                      <div className='col-md-8 col-sm-8 col-lg-8 '>
                        <div className='row' >
                          <div className='col-md-2 col-lg-2 col-sm-12'>
                            <div class="avatar-upload">
                              {/*       
        <div class="avatar-preview">
            <div id="imagePreview" >
                <img src={Avtar}/>
            </div>
        </div> */}<img className="avatar-preview" src={imagePreview || profilePic || Avtar} alt="Preview" />
                            </div>
                            

                          </div>

                          <div className='col-md-6 col-sm-12 col-lg-6'>
                            <div className="drop-zone">
                              <div className='d-flex' style={{ flexDirection: "column", cursor: "pointer" }}>
                                <div className='text-center dropzone_icon'>{UPLOAD_ICON}</div>
                                <span className="drop-zone__prompt">
                                  <span className='click'>Click to upload</span> or Drop file here
                                </span>
                              </div>
                              <input type="file" name="myFile" className="drop-zone__input" accept="image/jpeg, image/png"  ref={inputRef} onChange={handleFileChange} />
                            </div>
                            {fileTypeError && <div className="error-message text-danger">{fileTypeError}</div>}
              
                        
                            {
                              imagePreview !== null &&

                              <div className='ml-auto mt-3'> {/* ml-auto sets margin-left to auto, pushing the buttons to the right */}
                                <button className='btn-save' style={{float:"inline-end", opacity: fileTypeError.length > 0 ? 0.5 : 1 }} onClick={() => uploadProfilePicture()} disabled={fileTypeError.length > 0}>Upload</button>

                              </div>
}
</div> 
                        </div>
                      </div>
                    </div>
                }


                {/* your profile */}

                {
                  loader ? <SkeletonLoader skeletonCount={1} />
                    :
                    <div className='row mt-2 b-top' style={{ justifyContent: "space-between", alignItems: "center" }}>
                      <div className='col-md-4 col-sm-4 col-lg-4'>
                        <div className='details-label'>Role</div>
                      </div>

                      <div className='col-md-8 col-sm-8 col-lg-8 '>
                        <div className='row'>
                          <div class="form-group col-md-8">
                            {/* <label>First Name</label> */}
                            <div class="input-group input-grp-style">

                              <input type="text" class="form-control" name="role" value={role} onChange={(e) => {
                                setRole(e.target.value);
                                setEditRole(e.target.value);
                              }} placeholder="Your Role" style={{ padding: "10px 14px" }} />
                            </div>
                          </div>

                        </div>

                      </div>

                    </div>
                }


                {/* country */}

                {
                  loader ? <SkeletonLoader skeletonCount={1} />
                    :
                    <div className='row mt-3 b-top mb-5' style={{ justifyContent: "space-between" }}>
                      <div className='col-md-4 col-sm-4 col-lg-4'>
                        <div className='details-label'>Country</div>
                      </div>


                      <div className='col-md-8 col-sm-8 col-lg-8 '>
                        <div className='row'>
                          <div class="form-group col-md-6 ">
                            <label>Select Country</label>


                            <Select
                              defaultValue={country}
                              options={newData}
                              className="basic-multi-select  "
                              classNamePrefix="In progress"
                              onChange={handleChangeCountry}
                              isClearable={true}

                            />



                          </div>

                        </div>

                      </div>


                    </div>
                }


                <div className='row mt-2 b-top'>
                  <div className='ml-auto'> {/* ml-auto sets margin-left to auto, pushing the buttons to the right */}
                    <button className='btn-save mx-2' style={{ float: "inline-end" }}
                      disabled={
                        editFirstName.length === 0 && editLastName.length === 0 &&
                        editOfficialEmail.length === 0 && editRole.length === 0 &&
                        editCountry === null
                      }
                      onClick={editConsumerProfile}>Save</button>
                    <button className='btn-cancle' style={{ float: "inline-end" }} onClick={() => getUploadProfilePicture()}>Cancel</button>

                  </div>
                </div>





              </div>

            </section>
            <section id="rauchbier" className={`tab-panel ${activeTab === 'rauchbier' ? 'active' : ''}`}>
              {/* <div>
              helloo
            </div> */}
            </section>

          </div>

        </div>
      </div>
    </>
  )
}

export default AccountSettings