import React from 'react';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

const AnimationWrapper = ({ children, animationType }) => {
  const controls = useAnimation();
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.1
  });

  const animationVariants = {
    fadeIn: {
      hidden: { opacity: 0 },
      visible: { opacity: 1 }
    },
    slideUp: {
      hidden: { y: 100, opacity: 0 },
      visible: { y: 0, opacity: 1 }
    },
    heading: {
      hidden: { scale: 0.8, opacity: 0 },
      visible: { scale: 1, opacity: 1 }
    },
    subHeading: {
      hidden: { x: -100, opacity: 0 },
      visible: { x: 0, opacity: 1 }
    },
    image: {
      hidden: { scale: 1.8, opacity: 0 },
      visible: { scale: 1, opacity: 1 }
    },
    button: {
      hidden: { rotate: -5, opacity: 0 },
      visible: { rotate: 0, opacity: 1 }
    },
  listItem: {
    hidden: { x: -30, opacity: 0 },
    visible: { x: 0, opacity: 1 }
  },
  card: {
    hidden: { y: 20, opacity: 0, scale: 0.95 },
    visible: { y: 0, opacity: 1, scale: 1, transition: { duration: 0.5 } }
  },
  counter: {
    hidden: { scale: 0.8, opacity: 0, color: "#888" },
    visible: { 
      scale: 1, 
      opacity: 1, 
      color: "#000",
      transition: { 
        scale: { 
          type: "spring", 
          damping: 5 
        },
        color: { 
          duration: 1 
        }
      }
    }
  },
    // Add more animations as needed
  };

  React.useEffect(() => {
    if (inView) {
      controls.start('visible');
    }
  }, [controls, inView]);

  return (
    <motion.div
      ref={ref}
      initial="hidden"
      animate={controls}
      variants={animationVariants[animationType]}
      transition={{ duration: 1 }}
    >
      {children}
    </motion.div>
  );
};

export default AnimationWrapper;
